import React, { useState } from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useDocumentData } from "react-firebase-hooks/firestore";
import * as firebaseApi from "../api/firebase";
import { useHGApp } from "../store";
import { useTransformer } from "../hooks/hooks";
import { RelationshipMap } from "../domain";
import { observer } from "mobx-react-lite";
import * as actions from "../actions";
import {
  Button,
  Dialog,
  DialogContentText,
  Switch,
  TextField,
} from "@mui/material";
import { TooltipSolid } from "./TooltipSolid";
import { ToolbarTextButton } from "./ToolbarButton";

function ConfirmArchiveDialog(props: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="font-bold text-gray-800 px-4 pt-4">
        Are you sure you want to archive this map?
      </div>
      <div className="px-4 p-4">
        <DialogContentText>
          Once you have archived this map, you will still be able to access it -
          it will just be moved to the archive section of the maps list.
        </DialogContentText>
        <div className="flex flex-row justify-end pt-4 space-x-2">
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="outlined" color="error" onClick={onConfirm}>
            Archive
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export const RelationshipMapDetailsEditor = (props: {
  initialName: string;
  initialDescription: string;
  initialIsTemplate: boolean;
  onClose: () => void;
  onFinish: ({
    nextName,
    nextDescription,
    nextIsTemplate,
  }: {
    nextName: string;
    nextDescription: string;
    nextIsTemplate: boolean;
  }) => void;
  onArchive: () => Promise<void>;
}) => {
  const {
    initialName,
    initialDescription,
    initialIsTemplate,
    onClose,
    onFinish,
    onArchive,
  } = props;

  const [nextName, setNextName] = useState<string>(initialName);
  const [nextDescription, setNextDescription] =
    useState<string>(initialDescription);
  const [nextIsTemplate, setNextIsTemplate] =
    useState<boolean>(initialIsTemplate);
  const [archiveConfirmDialogOpen, setArchiveConfirmDialogOpen] =
    useState<boolean>(false);

  const handleArchiveConfirmDialogClose = () => {
    setArchiveConfirmDialogOpen(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth={"xs"} open={true} onClose={onClose}>
      <ConfirmArchiveDialog
        onClose={handleArchiveConfirmDialogClose}
        onConfirm={onArchive}
        open={archiveConfirmDialogOpen}
      />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onFinish({
            nextName: nextName.trim(),
            nextDescription: nextDescription.trim(),
            nextIsTemplate,
          });
        }}
        className="p-4 pt-6 space-y-4"
      >
        <TextField
          autoFocus={true}
          autoComplete="off"
          value={nextName}
          onChange={(e) => {
            setNextName(e.target.value);
          }}
          label={`Relationship map name`}
          variant="outlined"
          fullWidth={true}
        />

        <TextField
          multiline={true}
          minRows={4}
          autoComplete="off"
          value={nextDescription}
          onChange={(e) => {
            setNextDescription(e.target.value);
          }}
          label={`Description`}
          variant="outlined"
          fullWidth={true}
        />

        <div className="bg-blue-50 rounded border-blue-200 border">
          <div className="flex flex-row items-center justify-between px-4 py-2">
            <label
              htmlFor="is-template"
              className="text-sm font-medium text-gray-800 block"
            >
              Use this Relationship Map as a template
            </label>
            <Switch
              id="is-template"
              checked={nextIsTemplate}
              onChange={(e) => {
                setNextIsTemplate(e.target.checked);
              }}
            />
          </div>
          <div className="text-sm text-gray-600 font-regular px-4 pb-4">
            Making this map a template will allow you to create new maps using
            the content of this one as a starting point.
          </div>
        </div>

        <div className="flex flex-row justify-between">
          <Button
            type="button"
            color="error"
            onClick={() => {
              setArchiveConfirmDialogOpen(true);
            }}
          >
            Archive
          </Button>

          <div className="flex flex-row space-x-2">
            <Button
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={nextName === ""}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export const RelationshipMapSwitcherToolbar = observer(() => {
  const hgApp = useHGApp();

  const mapId = hgApp.store.currentRelationshipMap?.id;
  const portalId = hgApp.store.portalId;

  if (!mapId) {
    throw new Error(
      "Should not render RelationshipMapSwitcherToolbar without current currentRelationshipMap.id",
    );
  }
  const mapRef = firebaseApi.mapRef(portalId, mapId);

  const [relationshipMap, loading, error] = useDocumentData(mapRef, {
    idField: "id",
    transform: useTransformer(RelationshipMap),
  });

  const detailsEditorVisible = hgApp.store.relationshipMapDetailsEditorVisible;

  const handleRelationshipMapEditorFinished = React.useCallback(
    (params: {
      nextName: string;
      nextDescription: string;
      nextIsTemplate: boolean;
    }) => {
      const { nextName, nextDescription, nextIsTemplate } = params;

      const fn = async () => {
        await actions.updateRelationshipMapDetails({
          portalId,
          mapId,
          nextName,
          nextDescription,
          nextIsTemplate,
          nextArchived: relationshipMap?.archived || false,
        });
      };

      actions.hideRelationshipMapDetailsEditor();

      fn().catch(console.error);
    },
    [relationshipMap?.archived],
  );

  const handleOnArchive = React.useCallback(async () => {
    try {
      await actions.archiveRelationshipMap({
        portalId,
        mapId,
      });
    } catch (e) {
      console.error(e);
    }
  }, [portalId, mapId]);

  const handleRelationshipMapEditorClosed = () => {
    actions.hideRelationshipMapDetailsEditor();
  };

  if (error) {
    throw error;
  }

  return (
    <div className="bg-white shadow-vlg rounded-md flex flex-row justify-items-stretch divide-x-2 pointer-events-auto">
      <div className="flex flex-col items-center justify-center">
        <button
          onClick={actions.startSwitchingRelationshipMap}
          className="flex flex-col items-center justify-center hover:text-purple-500 transition-all active:bg-purple-100 h-[40px] w-[40px] "
        >
          <MenuIcon></MenuIcon>
        </button>
      </div>

      {relationshipMap && (
        <TooltipSolid
          title="Change relationship map name"
          placement="bottom"
          arrow={true}
        >
          <div className="flex items-center">
            <ToolbarTextButton
              textSizeClass="text-base"
              onClick={() => {
                actions.showRelationshipMapDetailsEditor();
              }}
              label={relationshipMap?.name}
            ></ToolbarTextButton>
            {detailsEditorVisible && (
              <RelationshipMapDetailsEditor
                initialName={relationshipMap.name}
                initialDescription={relationshipMap.description || ""}
                initialIsTemplate={relationshipMap.isTemplate || false}
                onClose={handleRelationshipMapEditorClosed}
                onFinish={handleRelationshipMapEditorFinished}
                onArchive={handleOnArchive}
              />
            )}
          </div>
        </TooltipSolid>
      )}
    </div>
  );
});
