import React, {
  DependencyList,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as domain from "../domain";
import _ from "lodash";
import colors from "tailwindcss/colors";
import * as d3 from "d3";
import RBush from "rbush";
import type { BBox } from "rbush";
import { assert, lerpPointAlongLine } from "../utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useHGApp } from "../store";
import { observer } from "mobx-react-lite";
import * as actions from "../actions";
import { CardShape, TDDocument, TDShape } from "@orgcharthub/tldraw-tldraw";
import shortid from "shortid";

const PORT_MAGNET_PADDING = domain.GRID_SIZE * 4;

export interface NodeLayoutDefinition {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

function shapeByObjectRef(
  shapes: TDShape[],
  objectRef: domain.HGObjectRef,
): CardShape | undefined {
  const cardShapes = shapes.filter(domain.isCardShape);

  for (const shape of cardShapes) {
    const shapeObjectRef: domain.HGObjectRef = {
      objectType: shape.meta.objectType,
      objectId: shape.meta.objectId,
    };

    if (domain.objectRefsEqual(objectRef, shapeObjectRef)) {
      return shape;
    }
  }
}

const useD3 = (
  renderFn: (
    selection: d3.Selection<SVGSVGElement, null, null, undefined>,
  ) => void,
  dependencies: DependencyList,
) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    return renderFn(d3.select(ref.current as SVGSVGElement));
  }, dependencies);

  return ref;
};

const d3CableRenderer = d3
  .line<{ x: number; y: number }>()
  .x((d) => d.x)
  .y((d) => d.y)
  .curve(d3.curveBasis);

const Cable: React.FC<{
  aX: number;
  aY: number;
  bX: number;
  bY: number;
  labels: {
    colorConfig: domain.RelationshipLineColorConfig;
    text?: string | null;
  }[];
  type?: "draft" | "saved";
  onRemove?: () => void;
}> = (props) => {
  const { aX, aY, bX, bY, type, labels, onRemove } = props;

  const stripePatternId = useRef<string>(`stripes-${shortid()}`);

  const a = { x: aX, y: aY };
  const b = { x: bX, y: bY };

  const lineOpacity = 1;

  const numberOfCableSegments = 5;
  const stepX = (b.x - a.x) / numberOfCableSegments;
  const stepY = (b.y - a.y) / numberOfCableSegments;

  type CableNode = {
    fx?: number;
    fy?: number;
    x?: number;
    y?: number;
  };

  const nodes: CableNode[] = _.map(
    _.range(0, numberOfCableSegments + 1),
    (n) => {
      return { x: a.x + stepX * n, y: a.y + stepY * n };
    },
  );

  // force to a point 1000px away from the midpoint between the start and end y position
  const forceY = _.chain([a, b])
    .map((point) => point.y)
    .sum()
    .divide(2)
    .add(1000)
    .value();

  const cableRef = useRef<d3.Selection<
    SVGPathElement,
    null,
    null,
    undefined
  > | null>(null);

  const [cableMidpoint, setCableMidpoint] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const ref = useD3((selection) => {
    const cable = selection
      .append("path")
      .attr("stroke", `url(#${stripePatternId.current})`)
      .attr("stroke-width", type === "draft" ? 4 : 6)
      .attr("stroke-linecap", "round")
      .attr("fill", "none")
      .attr("class", "drop-shadow-md");

    if (type === "draft") {
      cable.attr("stroke-dasharray", "8");
    }

    cableRef.current = cable;

    const links = d3.pairs(nodes).map(([source, target]) => {
      return { source, target };
    });

    nodes[0].fx = a.x;
    nodes[0].fy = a.y;

    nodes[nodes.length - 1].fx = b.x;
    nodes[nodes.length - 1].fy = b.y;

    const sim = d3
      .forceSimulation(nodes)
      .force("gravity", d3.forceY(forceY).strength(0.005))
      .force("collide", d3.forceCollide(20))
      .force("links", d3.forceLink(links).strength(0.8))
      .on("tick", () => {
        cable.attr("d", (_d) => {
          const d = _d as unknown as {
            nodes: { x: number; y: number }[];
            sim: d3.Simulation<CableNode, undefined>;
          };
          return d3CableRenderer(d.nodes);
        });

        const cableNode = cable.node();
        if (cableNode) {
          const cableMidpoint = cableNode.getPointAtLength(
            cableNode.getTotalLength() / 2,
          );
          setCableMidpoint(cableMidpoint);
        }
      });

    cable.datum({ nodes, sim });

    return () => {
      cable.remove();
    };
  }, []);

  useEffect(() => {
    const cable = cableRef.current;
    if (cable) {
      const { nodes, sim } = cable.datum() as unknown as {
        nodes?: CableNode[];
        sim?: d3.Simulation<CableNode, undefined>;
      };

      if (nodes && nodes.length > 1 && sim) {
        const first = nodes[0];
        const last = nodes[nodes?.length - 1];

        first.fx = a.x;
        first.fy = a.y;

        last.fx = b.x;
        last.fy = b.y;

        sim.alpha(1);
        sim.restart();
      }
    }
  }, [a.x, a.y, b.x, b.y]);

  return (
    <g>
      <svg className="overflow-visible" ref={ref} />

      <svg className="overflow-hidden relative">
        <defs>
          <linearGradient
            id={stripePatternId.current}
            spreadMethod="repeat"
            x1={0}
            x2={0.33}
            y1={0}
            y2={0}
          >
            <>
              {labels.map((label, i) => {
                const stepSize = 1 / labels.length;
                return (
                  <React.Fragment key={label.text || "unlabelled"}>
                    <stop
                      stopColor={label.colorConfig.labelBackgroundColor}
                      offset={i * stepSize}
                    />
                    <stop
                      stopColor={label.colorConfig.labelBackgroundColor}
                      offset={(i + 1) * stepSize}
                    />
                  </React.Fragment>
                );
              })}
            </>
          </linearGradient>
        </defs>
      </svg>

      {type !== "draft"
        ? labels.map((label, i) => {
            const { text, colorConfig } = label;

            if (!cableMidpoint || _.isEmpty(text) || typeof text !== "string") {
              return null;
            }

            const yOffset = 38 * i - (labels.length * 18) / 2;

            return (
              <AssociationLabel
                key={i}
                position={{ x: cableMidpoint.x, y: cableMidpoint.y + yOffset }}
                onRemove={onRemove}
                label={text}
                fillColor={colorConfig.labelBackgroundColor}
                textColor={colorConfig.labelTextColor}
              />
            );
          })
        : null}

      <circle
        r={8}
        opacity={lineOpacity}
        fill={labels[0] ? labels[0].colorConfig.lineColor : "#ff0000"}
        cx={a.x}
        cy={a.y}
      />
      <circle
        r={8}
        opacity={lineOpacity}
        fill={labels[0] ? labels[0].colorConfig.lineColor : "#ff0000"}
        cx={b.x}
        cy={b.y}
      />

      {/* <rect
        fill="red"
        fillOpacity={0.3}
        x={b.x - 50}
        y={b.y - 50}
        width={100}
        height={100}
      /> */}
    </g>
  );
};

const CreateAssociationLabelLabelDialog: React.FC<{
  onCancel: () => void;
  onCreate: (label: string) => void;
}> = (props) => {
  const { onCancel, onCreate } = props;

  const [label, setLabel] = useState<string>("");

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>Create new Association Label</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Describe how the records relate to one another (ex: decision maker,
          partner, etc.)
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="label"
          label="Association label"
          placeholder={`e.g. "Decision Maker"`}
          fullWidth
          variant="standard"
          onChange={(e) => {
            setLabel(e.currentTarget.value);
          }}
          value={label}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={_.isEmpty(label)}
          onClick={(e) => {
            onCreate(label);
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CanvasDraftEdgeLabelSelector: React.FC<{
  targetPortX: number;
  targetPortY: number;
  fromObjectType: string;
  toObjectType: string;
}> = observer((props) => {
  const targetPort = { x: props.targetPortX, y: props.targetPortY };

  const hgApp = useHGApp();

  const { fromObjectType, toObjectType } = props;
  const x = targetPort.x + 18;
  const y = targetPort.y - 12;

  const creatingAssociationLabel =
    !!hgApp.store.draftAssociationLabel?.fromObjectType;

  const allAssociationLabels = Object.values(hgApp.store.hgAssociationLabels);

  const allowableAssociationLabels = allAssociationLabels.filter((labelDef) => {
    const mentionedObjectTypes =
      domain.hgAssociationLabelMentionedObjectTypes(labelDef);
    return domain.objectTypesListsEqual(
      [fromObjectType, toObjectType],
      mentionedObjectTypes,
    );
  });

  const primaryAssociationLables = allowableAssociationLabels.filter(
    (associationLabel) => {
      return domain.isPrimaryAssociation(associationLabel);
    },
  );

  const secondaryAssociationLabels = allowableAssociationLabels.filter(
    (associationLabel) => {
      return !domain.isPrimaryAssociation(associationLabel);
    },
  );

  const sortedPrimaryAssociationLabels = _.sortBy(
    primaryAssociationLables,
    (associationLabel) => {
      if (domain.isStrictPrimaryAssociation(associationLabel)) {
        return 0;
      } else if (domain.isUnlabelledAssociationLabel(associationLabel)) {
        return 1;
      } else {
        return 2;
      }
    },
  );

  const sortedSecondaryAssociationLabels = _.sortBy(
    secondaryAssociationLabels,
    (associationLabel) => {
      assert(associationLabel.label);
      return associationLabel.label.toLowerCase();
    },
  );

  const width = 240;
  const height = 600;

  const onCancelCreatingAssociationLabel = () => {
    actions.cancelCreateAssociation();
  };

  const onFinishCreatingAssociationLabel = (label: string) => {
    actions.finishCreatingAssociationWithNewLabel({ label });
  };

  return (
    <g>
      <foreignObject className="" x={x} y={y} width={width} height={height}>
        <div
          className={`rounded-xl shadow-v2xl pointer-events-auto bg-gray-900 w-full p-2`}
        >
          {creatingAssociationLabel && (
            <CreateAssociationLabelLabelDialog
              onCancel={onCancelCreatingAssociationLabel}
              onCreate={onFinishCreatingAssociationLabel}
            />
          )}

          <div className="flex flex-col">
            {[
              sortedPrimaryAssociationLabels,
              sortedSecondaryAssociationLabels,
            ].map((associationLabels, i) => {
              return (
                <div
                  key={i === 0 ? "primary" : "secondary"}
                  className={`space-y-2`}
                >
                  {_.map(associationLabels, (labelDef) => {
                    const colorConfig =
                      domain.RELATIONSHIP_LINE_COLOR_CONFIGS[
                        labelDef.color as domain.RelationshipLineColorConfigColor
                      ];

                    return (
                      <div
                        key={labelDef.canonicalId}
                        onClick={(e) => {
                          actions.finishCreatingAssociation({
                            associationLabelCanonicalId: labelDef.canonicalId,
                          });
                        }}
                        className={`${colorConfig.labelBackgroundColorClass} cursor-pointer flex items-center px-4 py-2 rounded-lg cursor-pointer opacity-90 hover:opacity-100 transition-all`}
                      >
                        <span
                          className={`text-lg ${colorConfig.labelTextColorClass} font-semibold`}
                        >
                          {labelDef.label || "Association"}
                        </span>
                      </div>
                    );
                  })}

                  {i === 0 && !_.isEmpty(sortedSecondaryAssociationLabels) && (
                    <div className="py-1 pb-3">
                      <div className="border-b-4 border-dashed border-white border-opacity-10"></div>
                    </div>
                  )}
                </div>
              );
            })}
            {/* <Button
              onClick={(e) => {
                actions.startCreatingAssociationWithNewLabel();
              }}
            >
              Create new Label
            </Button> */}
            <div
              className={
                !_.isEmpty(sortedPrimaryAssociationLabels) ||
                !_.isEmpty(sortedSecondaryAssociationLabels)
                  ? "pt-4"
                  : ""
              }
            >
              <Button
                fullWidth
                onClick={(e) => {
                  actions.cancelCreateAssociation();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </foreignObject>
    </g>
  );
});

export const CanvasDraftEdgeLayer: React.FC<{
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
}> = observer((props) => {
  const { width, height, offsetX, offsetY } = props;

  const hgApp = useHGApp();

  const draftState = hgApp.store.draftConnection;

  const shapes = Object.values(
    (hgApp.tlApp.document as TDDocument).pages.page_1.shapes,
  );

  const nodeLayoutDefinitions = shapes.reduce((acc, shape) => {
    if (!draftState) {
      return acc;
    }

    if (shape.type !== "card") {
      return acc;
    }

    const objectType = shape.meta.objectType;

    // only allow same object to same object connections if company->company
    if (
      draftState.from.objectType === "company" ||
      draftState.from.objectType !== objectType
    ) {
      acc.push({
        x: shape.point[0],
        y: shape.point[1],
      });
    }

    return acc;
  }, [] as { x: number; y: number }[]);

  function x(x: number): number {
    return x + offsetX;
  }

  function y(y: number): number {
    return y + offsetY;
  }

  function getNodePosition(
    nodeId: string,
  ): { x: number; y: number; width: number; height: number } | undefined {
    const node = _.chain(shapes)
      .filter(
        (shape) => shape.type === "card" && shape.meta.objectId === nodeId,
      )
      .first()
      .value();
    if (!node) {
      return;
    }

    if (node.type !== "card") {
      return;
    }

    const size = hgApp.store.cardSizeCache[node.id] || [300, 300];

    return {
      x: node.point[0],
      y: node.point[1],
      width: size[0],
      height: size[1],
    };
  }

  const { index: connectionPortRectIndex, bboxes } = useMemo(() => {
    const index = new RBush<BBox>();
    const portMagnetPaddingHalved = PORT_MAGNET_PADDING / 2;
    // const portMagnetPaddingHalved = 0;
    const bboxes: BBox[] = _.map(
      _.values(nodeLayoutDefinitions),
      (layoutDef) => {
        assert(layoutDef);
        const connectorPoint = connectorPointForShapePoint({
          x: x(layoutDef.x),
          y: y(layoutDef.y),
        });
        const bbox: BBox = {
          minX: connectorPoint.x - portMagnetPaddingHalved,
          minY: connectorPoint.y - portMagnetPaddingHalved,
          maxX: connectorPoint.x + portMagnetPaddingHalved,
          maxY: connectorPoint.y + portMagnetPaddingHalved,
        };
        return bbox;
      },
    );
    index.load(bboxes);
    return { index, bboxes };
  }, [nodeLayoutDefinitions]);

  if (!draftState) {
    return null;
  }

  const currentPoint = hgApp.store.currentPoint;
  const mouseXY = {
    x: currentPoint[0],
    y: currentPoint[1],
  };

  const canvasTransform: { x: number; y: number; scale: number } = {
    x: 0,
    y: 0,
    scale: 1,
  };

  const canvasXY = {
    x: (mouseXY.x - canvasTransform.x) * (1 / canvasTransform.scale),
    y: (mouseXY.y - canvasTransform.y) * (1 / canvasTransform.scale),
  };

  const draftStateFrom = draftState.from;
  const draftStateTo = draftState.to;

  const fromNodeId = draftState.from.nodeId;
  const fromNodePosition = getNodePosition(fromNodeId);
  let toNodePosition;
  if (draftStateTo) {
    toNodePosition = getNodePosition(draftStateTo.nodeId);
  }

  if (!fromNodePosition) {
    return null;
  }

  const debugPosition = {
    x: x(fromNodePosition.x + 22),
    y: y(fromNodePosition.y + 22) + 100,
  };

  const a = connectorPointForShapePoint({
    x: x(fromNodePosition.x),
    y: y(fromNodePosition.y),
  });

  let b: { x: number; y: number };
  if (toNodePosition) {
    b = connectorPointForShapePoint({
      x: x(toNodePosition.x),
      y: y(toNodePosition.y),
    });
  } else {
    b = canvasXY;
    const canvasXYBBox: BBox = {
      minX: canvasXY.x - 10,
      maxX: canvasXY.x + 10,
      minY: canvasXY.y - 10,
      maxY: canvasXY.y + 10,
    };
    const magnetPort = _.first(connectionPortRectIndex.search(canvasXYBBox));
    if (magnetPort) {
      b = connectorPointForMagnetPoint({
        x: magnetPort.minX,
        y: magnetPort.minY,
      });
    }
  }

  return (
    <svg
      onPointerDown={(e) => e.stopPropagation()}
      onMouseDown={(e) => console.log("click!")}
      data-layer={"CanvasDraftEdgeLayer"}
      className="absolute overflow-visible pointer-events-none border-0 border-red-500"
      viewBox={`0 0 ${width} ${height}`}
      style={{
        top: 0,
        left: 0,
        width: width,
        height: height,
      }}
    >
      {/* <line
        x1={x(fromNodePosition.x + 22)}
        y1={y(fromNodePosition.y + 22)}
        x2={canvasXY.x}
        y2={canvasXY.y}
        fill="none"
        stroke="red"
        strokeWidth={2}
      /> */}

      <Cable
        type="draft"
        aX={a.x}
        aY={a.y}
        bX={b.x}
        bY={b.y}
        labels={[
          {
            colorConfig: domain.RELATIONSHIP_LINE_COLOR_CONFIGS["draft"],
            text: "draft",
          },
        ]}
      />

      {!!draftStateTo && (
        <CanvasDraftEdgeLabelSelector
          targetPortX={b.x}
          targetPortY={b.y}
          fromObjectType={draftStateFrom.objectType}
          toObjectType={draftStateTo.objectType}
        />
      )}

      {/* <rect
        x={canvasXYBBox.minX}
        y={canvasXYBBox.minY}
        width={canvasXYBBox.maxX - canvasXYBBox.minX}
        height={canvasXYBBox.maxY - canvasXYBBox.minY}
      /> */}

      {/* <>
        {_.map(bboxes, (bbox, i) => {
          return (
            <rect
              key={i}
              x={bbox.minX}
              y={bbox.minY}
              // width={10}
              // height={10}
              width={bbox.maxX - bbox.minX}
              height={bbox.maxY - bbox.minY}
              fill="red"
              fillOpacity={0.4}
              stroke="none"
            ></rect>
          );
        })}
      </> */}

      {/* <svg x={debugPosition.x} y={debugPosition.y} width="300" height="100">
        <rect width="100%" height="100%" fill="grey"></rect>
        <text x={0} y={0}>
          mouseXY: {mouseXY.x},{mouseXY.y}
        </text>
        <text x={0} y={40}>
          canvasXY: {canvasXY.x},{canvasXY.y}
        </text>
        <text x={0} y={80}>
          offsetXY: {offsetX},{offsetY}
        </text>
      </svg> */}
    </svg>
  );
});

function connectorPointForShapePoint(a: { x: number; y: number }): {
  x: number;
  y: number;
} {
  const paddingVertical = 26;
  const paddingHorizontal = 22;
  return {
    x: a.x + paddingHorizontal,
    y: a.y + paddingVertical,
  };
}

function connectorPointForMagnetPoint(a: { x: number; y: number }): {
  x: number;
  y: number;
} {
  return {
    x: a.x + PORT_MAGNET_PADDING / 2,
    y: a.y + PORT_MAGNET_PADDING / 2,
  };
}

const CanvasFlexibleEdge: React.FC<{
  offsetX: number;
  offsetY: number;
  hgAssociations: domain.HGAssociation[];
  sourceShape: CardShape;
  targetShape: CardShape;
}> = observer((props) => {
  const { hgAssociations, sourceShape, targetShape, offsetX, offsetY } = props;

  const hgApp = useHGApp();

  if (_.isEmpty(hgAssociations)) {
    return null;
  }

  const hgAssociation = hgAssociations[0];

  const removeAssociation = useCallback(() => {
    actions.removeAssociation({ canonicalId: hgAssociation.canonicalId });
  }, [hgAssociation.canonicalId]);

  const source = connectorPointForShapePoint({
    x: sourceShape.point[0] + offsetX,
    y: sourceShape.point[1] + offsetY,
  });

  const target = connectorPointForShapePoint({
    x: targetShape.point[0] + offsetX,
    y: targetShape.point[1] + offsetY,
  });

  return (
    <Cable
      onRemove={removeAssociation}
      aX={source.x}
      aY={source.y}
      bX={target.x}
      bY={target.y}
      labels={hgAssociations.map((hgAssociation) => {
        const hgAssociationLabel = hgApp.store.hgAssociationLabels[
          hgAssociation.associationLabelCanonicalId
        ] as domain.HGAssociationLabel | undefined;

        return {
          text: hgAssociationLabel?.label || "unknown",
          colorConfig: hgAssociationLabel
            ? domain.RELATIONSHIP_LINE_COLOR_CONFIGS[
                hgAssociationLabel.color as domain.RelationshipLineColorConfigColor
              ]
            : domain.RELATIONSHIP_LINE_COLOR_CONFIGS["red"],
        };
      })}
    />
  );
});

function makeSVGPathPrimaryAssociation(
  a: { x: number; y: number },
  b: { x: number; y: number },
): string {
  return `M${a.x},${a.y} L${b.x},${b.y}`;
}

const AssociationLabel = React.memo(
  (props: {
    position: { x: number; y: number };
    label: string;
    fillColor: string;
    textColor: string;
    onRemove?: () => void;
  }) => {
    const { position, label, fillColor, textColor, onRemove } = props;

    const refText = useRef<SVGTextElement | null>(null);
    const [textMetrics, setTextMetrics] = useState<{
      width: number;
      height: number;
    } | null>(null);

    useLayoutEffect(() => {
      const textEl = refText.current;
      if (textEl) {
        const bbox = textEl.getBBox();
        setTextMetrics({
          width: bbox.width,
          height: bbox.height,
        });
      }
    }, [label]);

    const labelPaddingLeft = 10;
    const labelPaddingRight = onRemove ? 2 : 10;
    const labelPaddingY = 5;
    const removeButtonWidth = onRemove ? 28 : 0;

    const labelRect = {
      x: position.x - (textMetrics ? textMetrics.width / 2 : 0),
      y: position.y - (textMetrics ? textMetrics.height / 2 : 0),
      width: textMetrics ? textMetrics.width : 0,
      height: textMetrics ? textMetrics.height : 0,
    };

    const rectRect = {
      x: labelRect.x - labelPaddingLeft,
      y: labelRect.y - labelPaddingY,
      width:
        labelRect.width +
        labelPaddingLeft +
        labelPaddingRight +
        removeButtonWidth,
      height: labelRect.height + labelPaddingY * 2,
    };

    return (
      <g>
        <rect
          fill={fillColor}
          x={rectRect.x}
          y={rectRect.y}
          width={rectRect.width}
          height={rectRect.height}
          rx={3}
          className="drop-shadow-md"
        ></rect>
        <text
          ref={refText}
          x={labelRect.x}
          y={labelRect.y}
          dy={17}
          textAnchor="start"
          fill={textColor}
          fontWeight="500"
          fontSize={18}
          style={{
            willChange: "transform",
            transform: "translateZ(0)",
            backfaceVisibility: "hidden",
          }}
        >
          {label}
        </text>
        {onRemove && (
          <foreignObject
            x={rectRect.x}
            y={rectRect.y}
            width={rectRect.width}
            height={rectRect.height}
            className="relative rounded-md"
          >
            <div className="absolute inset-0">
              <button
                onPointerDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  if (onRemove) {
                    onRemove();
                  }
                }}
                className="pointer-events-auto absolute right-0 bg-transparent hover:bg-white hover:bg-opacity-20 active:bg-opacity-30 flex items-center justify-center"
                style={{
                  color: textColor,
                  width: removeButtonWidth,
                  height: rectRect.height,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    fill="currentColor"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  />
                </svg>
              </button>
            </div>
          </foreignObject>
        )}
      </g>
    );
  },
  _.isEqual,
);

interface PrimaryAssociationPathProps {
  source: { x: number; y: number };
  target: { x: number; y: number };
  isParentChildAssociation?: boolean;
  isUnlabelledAssociation?: boolean;
}
const PrimaryAssociationPath = React.memo(
  (props: PrimaryAssociationPathProps) => {
    const {
      source,
      target,
      isParentChildAssociation,
      isUnlabelledAssociation,
    } = props;

    if (!isParentChildAssociation) {
      const path = makeSVGPathPrimaryAssociation(source, target);

      return (
        <path
          stroke={
            isUnlabelledAssociation ? colors.slate["300"] : colors.slate["400"]
          }
          strokeWidth={3}
          d={path}
        />
      );
    } else {
      const firstArrowPoint = lerpPointAlongLine(source, target, 0.3);
      const secondArrowPoint = lerpPointAlongLine(source, target, 0.6);

      const path1 = makeSVGPathPrimaryAssociation(source, firstArrowPoint);
      const path2 = makeSVGPathPrimaryAssociation(
        firstArrowPoint,
        secondArrowPoint,
      );
      const path3 = makeSVGPathPrimaryAssociation(secondArrowPoint, target);

      return (
        <svg className="overflow-visible">
          <defs>
            <marker
              id="arrow"
              markerWidth="10"
              markerHeight="10"
              refX="0"
              refY="3"
              orient="auto"
              markerUnits="strokeWidth"
            >
              <path d="M0,0 L0,6 L6,3 z" fill={colors.slate["400"]} />
            </marker>
          </defs>
          <path stroke={colors.slate["400"]} strokeWidth={3} d={path1} />
          <path
            markerStart="url(#arrow)"
            markerEnd="url(#arrow)"
            stroke={colors.slate["400"]}
            strokeWidth={3}
            d={path2}
          />
          <path stroke={colors.slate["400"]} strokeWidth={3} d={path3} />
        </svg>
      );
    }
  },
  _.isEqual,
);

const CanvasPrimaryEdge: React.FC<{
  offsetX: number;
  offsetY: number;
  hgAssociation: domain.HGAssociation;
  sourceShape: CardShape;
  targetShape: CardShape;
}> = observer((props) => {
  const hgApp = useHGApp();

  const { hgAssociation, sourceShape, targetShape, offsetX, offsetY } = props;

  const removeAssociation = useCallback(() => {
    actions.removeAssociation({ canonicalId: hgAssociation.canonicalId });
  }, [hgAssociation.canonicalId]);

  const hgAssociationLabel = hgApp.store.hgAssociationLabels[
    hgAssociation.associationLabelCanonicalId
  ] as domain.HGAssociationLabel | undefined;
  const isActuallyPrimary =
    hgAssociationLabel && domain.isStrictPrimaryAssociation(hgAssociationLabel);

  const isParentChildAssociation =
    hgAssociationLabel && domain.isParentChildAssociation(hgAssociationLabel);

  const isUnlabelledAssociation =
    hgAssociationLabel &&
    domain.isUnlabelledAssociationLabel(hgAssociationLabel);

  const sourceCanonicalId = domain.canonicalIdForHGObjectRef({
    objectId: sourceShape.meta.objectId,
    objectType: sourceShape.meta.objectType,
  });

  const targetCanonicalId = domain.canonicalIdForHGObjectRef({
    objectId: targetShape.meta.objectId,
    objectType: targetShape.meta.objectType,
  });

  const sourceSize = hgApp.store.cardSizeCache[sourceCanonicalId] || [300, 300];
  const targetSize = hgApp.store.cardSizeCache[targetCanonicalId] || [300, 300];

  const source = {
    x: sourceShape.point[0] + sourceSize[0] / 2 + offsetX,
    y: sourceShape.point[1] + sourceSize[1] / 2 + offsetY,
  };
  const target = {
    x: targetShape.point[0] + targetSize[0] / 2 + offsetX,
    y: targetShape.point[1] + targetSize[1] / 2 + offsetY,
  };

  const path = makeSVGPathPrimaryAssociation(source, target);

  const labelPosition = lerpPointAlongLine(source, target, 0.5);

  return (
    <svg className="overflow-visible">
      <PrimaryAssociationPath
        isParentChildAssociation={isParentChildAssociation}
        isUnlabelledAssociation={isUnlabelledAssociation}
        source={source}
        target={target}
      />
      {hgAssociationLabel && (
        <AssociationLabel
          position={labelPosition}
          label={hgAssociationLabel.label || "Association"}
          fillColor={
            isUnlabelledAssociation ? colors.slate["300"] : colors.slate["400"]
          }
          textColor={
            isUnlabelledAssociation ? colors.slate["600"] : colors.slate["800"]
          }
          onRemove={() => {
            removeAssociation();
          }}
        />
      )}
    </svg>
  );
});

export const CanvasPrimaryEdgeLayer: React.FC<{
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
}> = observer((props) => {
  const { width, height, offsetX, offsetY } = props;

  const hgApp = useHGApp();

  // force a read of the document notify update state so we can move links based on document updates (e.g. shape moved)
  hgApp.store.documentNotifyUpdate;

  const hgAssociations = Object.values(hgApp.store.hgAssociations).filter(
    (hgAssociation) => {
      const hgAssociationLabel = hgApp.store.hgAssociationLabels[
        hgAssociation.associationLabelCanonicalId
      ] as domain.HGAssociationLabel | undefined;

      if (!hgAssociationLabel) {
        return false;
      }

      // return domain.isPrimaryAssociation(hgAssociationLabel);
      return hgAssociation;
    },
  );

  // console.log(
  //   "with duplicates",
  //   _.map(hgAssociations, (hgAssociation) =>
  //     JSON.parse(
  //       JSON.stringify(_.pick(hgAssociation, ["fromObjectRef", "toObjectRef"])),
  //     ),
  //   ),
  // );

  // if we have more than one association, don't show unlabelled associations
  const filteredAssociations = _.chain(hgAssociations)
    .groupBy((hgAssociation) => {
      return domain.objectRefPairId([
        hgAssociation.fromObjectRef,
        hgAssociation.toObjectRef,
      ]);
    })
    .flatMap((hgAssociations, k) => {
      const primaryAssociations = hgAssociations.filter((hgAssociation) => {
        const hgAssociationLabel = hgApp.store.hgAssociationLabels[
          hgAssociation.associationLabelCanonicalId
        ] as domain.HGAssociationLabel | undefined;

        if (!hgAssociationLabel) {
          return false;
        }

        return domain.isPrimaryAssociation(hgAssociationLabel);
      });

      const nonPrimaryAssociations = hgAssociations.filter((hgAssociation) => {
        const hgAssociationLabel = hgApp.store.hgAssociationLabels[
          hgAssociation.associationLabelCanonicalId
        ] as domain.HGAssociationLabel | undefined;

        if (!hgAssociationLabel) {
          return false;
        }

        return !domain.isPrimaryAssociation(hgAssociationLabel);
      });

      if (
        primaryAssociations.length === 1 &&
        nonPrimaryAssociations.length === 0
      ) {
        // if we only have one primary association show that
        return hgAssociations;
      } else {
        // don't show unlabelled if we have more than one association
        return hgAssociations.filter((hgAssociation) => {
          const hgAssociationLabel = hgApp.store.hgAssociationLabels[
            hgAssociation.associationLabelCanonicalId
          ] as domain.HGAssociationLabel;

          return (
            !domain.isUnlabelledAssociationLabel(hgAssociationLabel) &&
            domain.isPrimaryAssociation(hgAssociationLabel)
          );
        });
      }
    })
    .value();

  // console.log(
  //   "withoutDuplicates",
  //   _.map(filteredAssociations, (hgAssociation) =>
  //     JSON.parse(
  //       JSON.stringify(_.pick(hgAssociation, ["fromObjectRef", "toObjectRef"])),
  //     ),
  //   ),
  // );

  const shapes = Object.values(
    (hgApp.tlApp.document as TDDocument).pages.page_1.shapes,
  );

  return (
    <svg
      data-layer={"CanvasPrimaryEdgeLayer"}
      className="absolute pointer-events-none overflow-visible"
      viewBox={`0 0 ${width} ${height}`}
      style={{
        top: 0,
        left: 0,
        width: width,
        height: height,
      }}
    >
      {filteredAssociations.map((hgAssociation, i) => {
        const sourceShape = shapeByObjectRef(
          shapes,
          hgAssociation.fromObjectRef,
        );
        const targetShape = shapeByObjectRef(shapes, hgAssociation.toObjectRef);

        if (!sourceShape || !targetShape) {
          return;
        }

        return (
          <CanvasPrimaryEdge
            key={hgAssociation.canonicalId}
            hgAssociation={hgAssociation}
            offsetX={offsetX}
            offsetY={offsetY}
            sourceShape={sourceShape}
            targetShape={targetShape}
          />
        );
      })}
    </svg>
  );
});

export const CanvasFlexibleEdgeLayer: React.FC<{
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
}> = observer((props) => {
  const { width, height, offsetX, offsetY } = props;

  const hgApp = useHGApp();

  const hgAssociations = _.chain(hgApp.store.hgAssociations)
    .values()
    .filter((hgAssociation) => {
      const hgAssociationLabel = hgApp.store.hgAssociationLabels[
        hgAssociation.associationLabelCanonicalId
      ] as domain.HGAssociationLabel | undefined;

      if (!hgAssociationLabel) {
        return false;
      }

      return !domain.isPrimaryAssociation(hgAssociationLabel);
    })
    .groupBy((hgAssociation) =>
      domain.objectRefPairId([
        hgAssociation.fromObjectRef,
        hgAssociation.toObjectRef,
      ]),
    )
    .values()
    .value();

  // console.log("documentNotifyUpdate", hgApp.store.documentNotifyUpdate);

  // force a read of the document notify update state so we can move links based on document updates (e.g. shape moved)
  hgApp.store.documentNotifyUpdate;

  const shapes = Object.values(
    (hgApp.tlApp.document as TDDocument).pages.page_1.shapes,
  );

  return (
    <svg
      data-layer={"CanvasFlexibleEdgeLayer"}
      className="absolute pointer-events-none overflow-visible"
      viewBox={`0 0 ${width} ${height}`}
      style={{
        top: 0,
        left: 0,
        width: width,
        height: height,
      }}
    >
      {hgAssociations.map((hgAssociations, i) => {
        const fromObjectRef = hgAssociations[0].fromObjectRef;
        const toObjectRef = hgAssociations[0].toObjectRef;

        const sourceShape = shapeByObjectRef(shapes, fromObjectRef);
        const targetShape = shapeByObjectRef(shapes, toObjectRef);

        if (!sourceShape || !targetShape) {
          return;
        }

        return (
          <CanvasFlexibleEdge
            key={hgAssociations[0].canonicalId}
            offsetX={offsetX}
            offsetY={offsetY}
            hgAssociations={hgAssociations}
            sourceShape={sourceShape}
            targetShape={targetShape}
          />
        );
      })}
    </svg>
  );
});

const CanvasLoaderEdge: React.FC<{
  offsetX: number;
  offsetY: number;
  edge: domain.HGCanvasEdge;
  sourceLayoutDefinition: NodeLayoutDefinition;
  targetLayoutDefinition: NodeLayoutDefinition;
}> = (props) => {
  const {
    edge,
    sourceLayoutDefinition,
    targetLayoutDefinition,
    offsetX,
    offsetY,
  } = props;

  const source = {
    x: sourceLayoutDefinition.x + sourceLayoutDefinition.width / 2 + offsetX,
    y: sourceLayoutDefinition.y + sourceLayoutDefinition.height / 2 + offsetY,
  };
  const target = {
    x: targetLayoutDefinition.x + targetLayoutDefinition.width / 2 + offsetX,
    y: targetLayoutDefinition.y + targetLayoutDefinition.height / 2 + offsetY,
  };

  const path = makeSVGPathPrimaryAssociation(source, target);

  return (
    <>
      <path stroke="purple" strokeDasharray={10} strokeWidth={3} d={path} />
    </>
  );
};

export const CanvasLoaderEdgeLayer: React.FC<{
  width: number;
  height: number;
  nodeLayoutDefinitions: Record<string, NodeLayoutDefinition | undefined>;
  edges: domain.HGCanvasEdge[];
  offsetX: number;
  offsetY: number;
}> = (props) => {
  const { width, height, edges, nodeLayoutDefinitions, offsetX, offsetY } =
    props;

  let _edges = _.filter(edges, (edge) => edge.isLoader);

  return (
    <svg
      data-layer={"CanvasLoaderEdgeLayer"}
      className="absolute pointer-events-none overflow-visible"
      viewBox={`0 0 ${width} ${height}`}
      style={{
        top: 0,
        left: 0,
        width: width,
        height: height,
      }}
    >
      {_edges.map((edge, i) => {
        const sourceLayoutDefinition = nodeLayoutDefinitions[edge.source];
        const targetLayoutDefinition = nodeLayoutDefinitions[edge.target];

        if (!sourceLayoutDefinition || !targetLayoutDefinition) {
          return;
        }

        const _sourceLayoutDefinition = sourceLayoutDefinition;
        const _targetLayoutDefinition = targetLayoutDefinition;

        return (
          <CanvasLoaderEdge
            key={edge.id}
            edge={edge}
            offsetX={offsetX}
            offsetY={offsetY}
            sourceLayoutDefinition={_sourceLayoutDefinition}
            targetLayoutDefinition={_targetLayoutDefinition}
          />
        );
      })}
    </svg>
  );
};
