import { LiveMap, LiveObject } from "@liveblocks/client";
import type {
  TDAsset,
  TDBinding,
  TDShape,
  TDUser,
  TldrawApp,
} from "@orgcharthub/tldraw-tldraw";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  Storage,
  useRedo,
  useRoom,
  useUndo,
  useCanUndo,
  useCanRedo,
  useUpdateMyPresence,
} from "../utils/liveblocks";
import { HGApp } from "../store";
import * as firebaseApi from "../api/firebase";
import * as actions from "../actions";
import { TLUser } from "@orgcharthub/tldraw-core";

declare const window: Window & { app: TldrawApp };

export function useMultiplayerState(
  hgApp: HGApp,
  portalId: string,
  roomId: string,
  user: Omit<TLUser<{ email?: string; displayName?: string }>, "id">,
) {
  const app = hgApp.tlApp;
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  const [rerenderCounter, setReRenderCounter] = useState<number>(0);

  const room = useRoom();
  const onUndo = useUndo();
  const onRedo = useRedo();
  const updateMyPresence = useUpdateMyPresence();

  const rIsPaused = useRef(false);

  const rLiveShapes = useRef<Storage["shapes"]>();
  const rLiveBindings = useRef<Storage["bindings"]>();
  const rLiveAssets = useRef<Storage["assets"]>();
  const rLiveMetadata = useRef<Storage["metadata"]>();

  // Callbacks --------------

  // Put the state into the window, for debugging.
  const onMount = useCallback(
    (app: TldrawApp) => {
      console.log("load room", { roomId });
      app.loadRoom(roomId, user);
      app.pause(); // Turn off the app's own undo / redo stack
      window.app = app;
    },
    [roomId],
  );

  // assuming the app is mounted here...
  useEffect(() => {
    console.log("load room onMountHGApp", { roomId });
    app.loadRoom(roomId, user);
    app.pause(); // Turn off the app's own undo / redo stack
    window.app = app;
  }, [roomId]);

  // Update the live shapes when the app's shapes change.
  const onChangePage = useCallback(
    (
      app: TldrawApp,
      shapes: Record<string, TDShape | undefined>,
      bindings: Record<string, TDBinding | undefined>,
      assets: Record<string, TDAsset | undefined>,
    ) => {
      console.log("onChangePage", { app, shapes, bindings, assets });
      room.batch(() => {
        const lShapes = rLiveShapes.current;
        const lBindings = rLiveBindings.current;
        const lAssets = rLiveAssets.current;
        const lMetadata = rLiveMetadata.current;

        if (!(lShapes && lBindings && lAssets && lMetadata)) return;

        Object.entries(shapes).forEach(([id, shape]) => {
          if (!shape) {
            lShapes.delete(id);
          } else {
            lShapes.set(shape.id, shape);
          }
        });

        Object.entries(bindings).forEach(([id, binding]) => {
          if (!binding) {
            lBindings.delete(id);
          } else {
            lBindings.set(binding.id, binding);
          }
        });

        Object.entries(assets).forEach(([id, asset]) => {
          if (!asset) {
            lAssets.delete(id);
          } else {
            lAssets.set(asset.id, asset);
          }
        });

        if (!rIsPaused.current) {
          lMetadata.set("mapVersion", (lMetadata.get("mapVersion") || 0) + 1);
        }
      });
    },
    [room],
  );

  // Handle presence updates when the user's pointer / selection changes
  const onChangePresence = useCallback(
    (app: TldrawApp, user: TDUser) => {
      // console.log("onChangePresence", { app, user });
      updateMyPresence({ id: app.room?.userId, user });
    },
    [updateMyPresence],
  );

  // Document Changes --------

  React.useEffect(() => {
    const unsubs: (() => void)[] = [];
    console.log("mount useMultiplayerState", { app, room });
    if (!(app && room)) return;

    setLoading(true);

    // Handle errors
    unsubs.push(room.subscribe("error", (error) => setError(error)));

    // Handle changes to other users' presence
    unsubs.push(
      room.subscribe("others", (others, event) => {
        console.log("on others", { others, event });
        if (event.type === "leave") {
          if (event.user.presence && event.user.presence.id) {
            app?.removeUser(event.user.presence.id);
          }
        } else {
          app.updateUsers(
            others
              .toArray()
              .filter((other) => other.presence)
              .map((other) => other.presence!.user)
              .filter(Boolean),
          );
        }
      }),
    );

    let stillAlive = true;

    // Setup the document's storage and subscriptions
    async function setupDocument() {
      console.log("setupDocument");
      const storage = await room.getStorage();

      console.log("got storage");

      // Migrate previous versions
      const version = storage.root.get("version");

      // Initialize (get or create) maps for shapes/bindings/assets

      let lShapes = storage.root.get("shapes");
      if (!lShapes || !("_serialize" in lShapes)) {
        storage.root.set("shapes", new LiveMap());
        lShapes = storage.root.get("shapes");
      }
      rLiveShapes.current = lShapes;

      let lBindings = storage.root.get("bindings");
      if (!lBindings || !("_serialize" in lBindings)) {
        storage.root.set("bindings", new LiveMap());
        lBindings = storage.root.get("bindings");
      }
      rLiveBindings.current = lBindings;

      let lAssets = storage.root.get("assets");
      if (!lAssets || !("_serialize" in lAssets)) {
        storage.root.set("assets", new LiveMap());
        lAssets = storage.root.get("assets");
      }
      rLiveAssets.current = lAssets;

      let lMetadata = storage.root.get("metadata");
      console.log(
        "existing lMetadata",
        lMetadata ? lMetadata.toObject() : lMetadata,
      );
      if (!lMetadata || !("_serialize" in lMetadata)) {
        console.log("setting lMetadata");
        storage.root.set("metadata", new LiveObject({ mapVersion: 0 }));
        lMetadata = storage.root.get("metadata");
      }
      rLiveMetadata.current = lMetadata;

      if (!version) {
        // The doc object will only be present if the document was created
        // prior to the current multiplayer implementation. At this time, the
        // document was a single LiveObject named 'doc'. If we find a doc,
        // then we need to move the shapes and bindings over to the new structures
        // and then mark the doc as migrated.
        const doc = storage.root.get("doc");

        // No doc? No problem. This was likely a newer document
        if (doc) {
          const {
            document: {
              pages: {
                page: { shapes, bindings },
              },
              assets,
            },
          } = doc.toObject();

          Object.values(shapes).forEach((shape) =>
            lShapes.set(shape.id, shape),
          );
          Object.values(bindings).forEach((binding) =>
            lBindings.set(binding.id, binding),
          );
          Object.values(assets).forEach((asset) =>
            lAssets.set(asset.id, asset),
          );
        }
      }

      // Save the version number for future migrations
      storage.root.set("version", 2.1);

      // Subscribe to changes
      const handleChanges = () => {
        const shapes = Object.fromEntries(lShapes.entries());
        const bindings = Object.fromEntries(lBindings.entries());
        const assets = Object.fromEntries(lAssets.entries());
        app?.replacePageContent(shapes, bindings, assets);
        actions.debouncedCheckForFetches();
      };

      const handleMetadataChanges = () => {
        const shapes = Object.fromEntries(lShapes.entries());
        const bindings = Object.fromEntries(lBindings.entries());
        const assets = Object.fromEntries(lAssets.entries());

        firebaseApi.debouncedSyncRelationshipMapDocument({
          portalId: portalId,
          mapId: roomId,
          document: {
            version: 2.1,
            shapes,
            bindings,
            assets,
          },
          metadata: {
            mapVersion: lMetadata.get("mapVersion") || 0,
          },
        });
      };

      if (stillAlive) {
        console.log("subscribe to room");
        unsubs.push(room.subscribe(lShapes, handleChanges));

        unsubs.push(room.subscribe(lMetadata, handleMetadataChanges));

        // Update the document with initial content
        handleChanges();

        // Zoom to fit the content
        app.zoomToFit();
        if (app.zoom > 1) {
          app.resetZoom();
        }

        setLoading(false);
      }
    }

    setupDocument();

    return () => {
      console.log("unmount useMultiplayerState");
      stillAlive = false;
      unsubs.forEach((unsub) => unsub());
      app?.patchState({ room: undefined });
    };
  }, [portalId, room, app]);

  const onSessionStart = React.useCallback(() => {
    console.log("onSessionsStart");
    if (!room) return;
    room.history.pause();
    rIsPaused.current = true;
  }, [room]);

  const onSessionEnd = React.useCallback(() => {
    console.log("onSessionsEnd");
    if (!room) return;
    room.history.resume();
    rIsPaused.current = false;

    const rMetadata = rLiveMetadata.current;
    if (rMetadata) {
      rMetadata.set("mapVersion", (rMetadata.get("mapVersion") || 0) + 1);
    }
  }, [room]);

  useHotkeys(
    "ctrl+shift+l;,⌘+shift+l",
    () => {
      if (window.confirm("Reset the document?")) {
        room.batch(() => {
          const lShapes = rLiveShapes.current;
          const lBindings = rLiveBindings.current;
          const lAssets = rLiveAssets.current;
          const lMetadata = rLiveMetadata.current;

          if (!(lShapes && lBindings && lAssets && lMetadata)) return;

          lShapes.forEach((shape) => {
            lShapes.delete(shape.id);
          });

          lBindings.forEach((shape) => {
            lBindings.delete(shape.id);
          });

          lAssets.forEach((shape) => {
            lAssets.delete(shape.id);
          });

          lMetadata.set("mapVersion", 0);
        });
      }
    },
    [],
  );

  const canUndo = useCanUndo();
  const canRedo = useCanRedo();

  useEffect(() => {
    actions.setCanUndo({ canUndo });
  }, [canUndo]);

  useEffect(() => {
    actions.setCanRedo({ canRedo });
  }, [canRedo]);

  useEffect(() => {
    actions.setLoadingMultiplayer({ loadingMultiplayer: loading });
  }, [loading]);

  return {
    onUndo,
    onRedo,
    onMount,
    onSessionStart,
    onSessionEnd,
    onChangePage,
    onChangePresence,
    error,
    loading,
    rerenderCounter,
  };
}
