import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import {
  canonicalIdForHSProperty,
  HGObject,
  HSProperty,
  HSPropertyEnumeration,
} from "../domain";
import { useHGApp as useHGAppContext } from "../store";
import * as computeds from "../computeds";
import { observer } from "mobx-react-lite";

function optionValueToLabel(params: {
  hsProperty: HSPropertyEnumeration;
  value: string;
}): string | null {
  const { hsProperty, value } = params;

  const option = _.find(hsProperty.options, (option) => {
    return option.value === value;
  });

  return option ? option.label : null;
}

const PropertyRowDefault: React.FC<{
  hsProperty: HSProperty;
  value: string | null;
}> = (props) => {
  const { hsProperty, value } = props;

  return (
    <div className="flex flex-row space-x-2">
      <div className="shrink-0 font-bold">{hsProperty.label}</div>
      <div className="grow truncate hg-privacy blur">{value}</div>
    </div>
  );
};

const PropertyRowEnumeration: React.FC<{
  hsProperty: HSPropertyEnumeration;
  value: string;
}> = (props) => {
  const { hsProperty, value } = props;

  const values = value.split(";");
  const optionsForRender = _.map(values, (optionValue) => {
    const valueLabel = optionValueToLabel({
      hsProperty,
      value: optionValue,
    });
    return { label: valueLabel || value, value: optionValue };
  });

  const Tag = (props: { label: string }) => {
    return (
      <div className="px-2 py-1 bg-gray-200 rounded-md inline-flex items-center justify-center hg-privacy blur">
        {props.label}
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-1">
      <div className="shrink-0 font-bold">{hsProperty.label}</div>
      <div className="grow flex-row flex-wrap space-x-2">
        {_.map(optionsForRender, (option) => {
          return <Tag key={option.value} label={option.label} />;
        })}
      </div>
    </div>
  );
};

const PropertyRowDateOrDateTime: React.FC<{
  hsProperty: HSProperty;
  value: string;
}> = (props) => {
  const { hsProperty, value } = props;

  const dt = DateTime.fromISO(value);

  const formattedValue = dt.toLocaleString(
    hsProperty.type === "date" || hsProperty.fieldType === "date"
      ? DateTime.DATE_SHORT
      : DateTime.DATETIME_SHORT,
  );

  return (
    <div className="flex flex-row space-x-2">
      <div className="shrink-0 font-bold">{hsProperty.label}</div>
      <div className="grow truncate hg-privacy blur">{formattedValue}</div>
    </div>
  );
};

export const PropertyRow: React.FC<{
  objectType: string;
  propertyName: string;
  propertyValue: string | null;
}> = (props) => {
  const { objectType, propertyName, propertyValue } = props;

  const app = useHGAppContext();

  const hsProperty = app.store.hgProperties[
    canonicalIdForHSProperty({
      objectType,
      name: propertyName,
    })
  ] as HSProperty | undefined;

  if (typeof hsProperty === "undefined") {
    return <div>can't find property: {propertyName}</div>;
  }

  let reactNode: React.ReactNode;

  if (propertyValue === null) {
    reactNode = (
      <PropertyRowDefault hsProperty={hsProperty} value={propertyValue} />
    );
  } else {
    switch (hsProperty.type) {
      case "datetime": {
        reactNode = (
          <PropertyRowDateOrDateTime
            hsProperty={hsProperty}
            value={propertyValue}
          />
        );
        break;
      }

      case "date": {
        reactNode = (
          <PropertyRowDateOrDateTime
            hsProperty={hsProperty}
            value={propertyValue}
          />
        );
        break;
      }

      case "enumeration": {
        reactNode = (
          <PropertyRowEnumeration
            hsProperty={hsProperty}
            value={propertyValue}
          />
        );
        break;
      }

      default: {
        reactNode = (
          <PropertyRowDefault hsProperty={hsProperty} value={propertyValue} />
        );
      }
    }
  }

  return <div className="px-4 py-2">{reactNode}</div>;
};

export const PropertyRows: React.FC<{
  hgObject: HGObject;
}> = observer((props) => {
  const { hgObject } = props;

  const displayProperties = computeds.portalDisplayPropertiesOnCard(
    hgObject.objectType,
  );

  return (
    <div className="border-y border-gray-200 divide-y divide-gray-200">
      {_.map(displayProperties, (displayProperty) => {
        const value = hgObject.properties?.[displayProperty.name] || null;

        if (value === null) {
          return null;
        }

        return (
          <PropertyRow
            key={displayProperty.name}
            objectType={hgObject.objectType}
            propertyName={displayProperty.name}
            propertyValue={value}
          />
        );
      })}
    </div>
  );
});
