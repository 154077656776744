import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const animationVariants = {
  hidden: {
    position: "absolute",
    top: "1rem",
    bottom: "1rem",
    left: 0,
    x: "-21rem",
    width: "26rem",
  },
  visible: {
    position: "absolute",
    top: "1rem",
    bottom: "1rem",
    left: 0,
    x: "1.5rem",
    width: "26rem",
  },
} as const;

export const AppSidebar = (props: {
  children: React.ReactNode;
  open: boolean;
  onPanelClosed: () => void;
}) => {
  const { open, onPanelClosed, children } = props;

  console.log("render app sidebar", { open });

  return (
    <motion.div
      // initial={false}
      onAnimationComplete={(definition) => {
        console.log("onAnimationComplete", definition);
        if (definition === "hidden") {
          onPanelClosed();
        }
      }}
      id={`open-${open}`}
      className="z-10 pointer-events-none"
      transition={{
        type: "spring",
        bounce: 0.1,
        duration: 0.4,
      }}
      variants={animationVariants}
      // animate={panelOpen ? "animate" : "initial"}
      animate={open ? "visible" : "hidden"}
    >
      {children}
    </motion.div>
  );
};
