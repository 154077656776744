import React, { useCallback, useState } from "react";
import { ToolbarIconButton, ToolbarTextButton } from "./ToolbarButton";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import { useHGApp } from "../store";
import { TDSnapshot } from "@orgcharthub/tldraw-tldraw";
import { Menu, MenuItem } from "@mui/material";
import { isOutsideHubSpotURL, outsideHubSpotURL } from "../domain";

const zoomSelector = (s: TDSnapshot) =>
  s.document.pageStates[s.appState.currentPageId].camera.zoom;

export const ZoomToolbar = () => {
  const hgApp = useHGApp();

  const app = hgApp.tlApp;

  const zoom = app.useStore(zoomSelector);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openInFullscreen = useCallback(() => {
    const currentLocation = window.location;
    const outsideURL = outsideHubSpotURL(currentLocation.href);
    window.open(outsideURL, "_blank");
  }, []);

  const isOutsideHubSpot = isOutsideHubSpotURL(window.location.href);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex flex-row items-center bg-white shadow-vlg rounded-md divide-x-2 divide-gray-200">
      {!isOutsideHubSpot && (
        <ToolbarTextButton
          tooltipTitle="Open in Fullscreen"
          onClick={openInFullscreen}
          label={"Fullscreen"}
        >
          <OpenInNewIcon fontSize={"small"} />
        </ToolbarTextButton>
      )}

      <ToolbarIconButton onClick={app.zoomOut}>
        <RemoveIcon style={{ fontSize: 18 }} />
      </ToolbarIconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            app.resetZoom();
            setAnchorEl(null);
          }}
        >
          Zoom to 100%
        </MenuItem>
        <MenuItem
          onClick={() => {
            app.zoomToFit();
            setAnchorEl(null);
          }}
        >
          Zoom to Fit
        </MenuItem>
        <MenuItem
          onClick={() => {
            app.zoomToSelection();
            setAnchorEl(null);
          }}
        >
          Zoom to Selection
        </MenuItem>
      </Menu>

      <button
        className="flex items-center justify-center px-2 text-sm tabular-nums hover:text-purple-500 active:bg-purple-100 font-regular"
        style={{ height: 40, width: 60 }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {Math.round(zoom * (1 / 0.5) * 100)}%
      </button>
      <ToolbarIconButton onClick={app.zoomIn}>
        <AddIcon style={{ fontSize: 18 }} />
      </ToolbarIconButton>
    </div>
  );
};
