import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "../hooks/hooks";
import { useStylesheet } from "../hooks/useStylesheet";
import _ from "lodash";
import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import * as TLDraw from "@orgcharthub/tldraw-core";
import * as HGObjectCanvasNodes from "./HGObjectCanvasNodes";
import {
  canonicalIdForHGObjectRef,
  HGCanvasObjectNode,
  isOutsideHubSpotURL,
  outsideHubSpotURL,
} from "../domain";
import useResizeObserver from "@react-hook/resize-observer";
import { observer } from "mobx-react-lite";
import {
  Button,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
} from "@mui/material";
import { HGAppContext, useHGApp, app as hgApp } from "../store";
import * as actions from "../actions";
import * as UIEdges from "./ui-edges";
import { runInAction } from "mobx";
import { AddObjectToChartPopoverContent } from "./AddToChartModal";
import { HGObjectSidebarImpl } from "./HGObjectSidebar";
import {
  Add as AddIcon,
  Fullscreen as FullscreenIcon,
} from "@mui/icons-material";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AddAssociatedToChartModal } from "./add-associated-to-chart-modal";

import { RoomProvider } from "../utils/liveblocks";
import { useMultiplayerState } from "../hooks/useMultiplayerState";
import { assert } from "../utils";
import { Toolbar } from "./Toolbar";
import { AppSidebar } from "./AppSidebar";
import { ZoomToolbar } from "./ZoomToolbar";
import { RelationshipMapSelector } from "./RelationshipMapSelector";
import { RelationshipMapSwitcherToolbar } from "./RelationshipMapSwitcherToolbar";
import { useKeyboardShortcuts } from "../hooks/useKeyboardShortcuts";
import { SENTRY_DSN, IS_PROD } from "../config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { UserCursor } from "./UserCursor";

if (IS_PROD) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
  });
}

type CardWrapperProps = TLDraw.TLComponentProps<
  TDDraw.CardShape,
  HTMLDivElement,
  TDDraw.TDMeta
> & {
  onSizeChange: (size: [width: number, height: number]) => void;
};

const CardWrapper = React.forwardRef<HTMLDivElement, CardWrapperProps>(
  (props, ref) => {
    const internalSizingRef = useRef<HTMLDivElement | null>(null);

    const onSizeChange = useCallback(
      (size: [width: number, height: number]) => {
        props.onSizeChange(size);
      },
      [props.onSizeChange],
    );

    useResizeObserver(internalSizingRef, (entry) => {
      const nextSize: [w: number, h: number] = [
        entry.contentRect.width,
        entry.contentRect.height,
      ];
      onSizeChange(nextSize);
      actions.updateCardSizeCache({
        objectCanonicalId: canonicalIdForHGObjectRef({
          objectId: node.id,
          objectType: node.objectType,
        }),
        size: nextSize,
      });
    });

    useLayoutEffect(() => {
      const rect = internalSizingRef.current?.getBoundingClientRect();
      const _size: [w: number, h: number] = [
        rect?.width || 0,
        rect?.height || 0,
      ];
      onSizeChange(_size);
      actions.updateCardSizeCache({
        objectCanonicalId: canonicalIdForHGObjectRef({
          objectId: node.id,
          objectType: node.objectType,
        }),
        size: _size,
      });
    }, []);

    const node: HGCanvasObjectNode = {
      type: "object",
      id: props.shape.meta.objectId,
      objectType: props.shape.meta.objectType,
    };

    return (
      <div ref={internalSizingRef}>
        <HGObjectCanvasNodes.HGObjectCanvasNode node={node} />
      </div>
    );
  },
);

const Component = <T extends TLDraw.TLShape, E extends Element = any, M = any>(
  component: (
    props: TLDraw.TLComponentProps<T, E, M> & {
      onSizeChange: (size: [width: number, height: number]) => void;
    },
    ref: TLDraw.TLForwardedRef<E>,
  ) => React.ReactElement,
) => {
  return React.forwardRef(component);
};

TDDraw.Card.InjectedComponent = Component((props, ref) => {
  return <CardWrapper {...props} ref={ref} />;
});

interface NodeSize {
  width: number;
  height: number;
}

const BottomEdgeLayer: React.FC<{}> = observer((props) => {
  const contentWidth = 8000;
  const contentHeight = 2000;
  const offsetX = 0;
  const offsetY = 0;

  return (
    <>
      <UIEdges.CanvasPrimaryEdgeLayer
        width={contentWidth}
        height={contentHeight}
        offsetX={offsetX}
        offsetY={offsetY}
      />
    </>
  );
});

const TopEdgeLayer: React.FC<{}> = observer((props) => {
  const contentWidth = 8000;
  const contentHeight = 2000;
  const offsetX = 0;
  const offsetY = 0;

  return (
    <>
      <UIEdges.CanvasFlexibleEdgeLayer
        width={contentWidth}
        height={contentHeight}
        offsetX={offsetX}
        offsetY={offsetY}
      />

      <UIEdges.CanvasDraftEdgeLayer
        width={contentWidth}
        height={contentHeight}
        offsetX={offsetX}
        offsetY={offsetY}
      />
    </>
  );
});

const Document: React.FC<{}> = observer((props) => {
  const app = useHGApp();
  const liveblocksRoomId = app.store.currentRelationshipMap?.id;
  const portalId = app.store.portalId;

  const user = app.store.user;

  assert(
    liveblocksRoomId,
    "should not render <Document /> without currentRelationshipMap.id in store",
  );

  assert(user, "should not render <Document /> without user in store");

  const { error, rerenderCounter, ...events } = useMultiplayerState(
    app,
    portalId,
    liveblocksRoomId,
    user,
  );

  // console.log(
  //   "render Document",
  //   JSON.parse(JSON.stringify(app.tlApp.document)),
  // );

  // console.log(
  //   "app documentForceUpdate with observer",
  //   app.store.documentForceUpdate,
  //   app.store.document,
  // );

  const handleChange = useCallback(
    (state: TDDraw.TldrawApp, reason?: string) => {
      // console.log("handle change", {
      //   document: _.cloneDeep(state.document),
      //   reason,
      // });
      // app.store.document = state.document;
      runInAction(() => {
        app.store.documentNotifyUpdate += 1;
      });
    },
    [],
  );

  if (error) return <div>Error: {error.message}</div>;

  // console.log("app id", _.cloneDeep(app.id));
  // console.log("store id", _.cloneDeep(app.id));
  // console.log(
  //   "render document",
  //   _.cloneDeep(app.store.document.pages.page_1.shapes),
  // );

  // setTimeout(() => {
  //   console.log("re-render Document", app.tlApp.document);
  // }, 1);

  // read the forceUpdate to ensure we re-render on document changes initialized outside of tldraw code
  app.store.documentForceUpdate;

  const handleKeyboardEvents =
    !app.store.relationshipMapDetailsEditorVisible &&
    !app.store.switchingRelationshipMap;

  return (
    <div className="relative flex-auto">
      {/* <div className="absolute top-[80px] left-8 z-10">
        Force update counter: {app.store.documentForceUpdate}
      </div> */}
      <TDDraw.Tldraw
        // id="test"
        app={app.tlApp}
        // document={app.tlApp.document}
        darkMode={false}
        showUI={false}
        showMenu={false}
        showMultiplayerMenu={false}
        showPages={false}
        showSponsorLink={false}
        showHelp={false}
        onChange={handleChange}
        renderOnTopLinks={() => {
          return <TopEdgeLayer />;
        }}
        renderUnderneathLinks={() => {
          return <BottomEdgeLayer />;
        }}
        onPersist={(...args: any[]) => {
          // console.log("onPersist args", args);
        }}
        components={{
          Cursor: UserCursor,
        }}
        disableAssets={true}
        handleKeyboardEvents={handleKeyboardEvents}
        {...events}
      />
    </div>
  );
});

const Canvas: React.FC<{}> = observer((props) => {
  return <Document />;
});

const HGToolbar: React.FC<{}> = observer((props) => {
  const draftState = !!hgApp.store.draftAddingObjectToChart;

  const handleClose = () => {
    actions.cancelAddingObjectToChart();
  };

  const isOutsideHubSpot = isOutsideHubSpotURL(window.location.href);

  return (
    <>
      <div
        className="flex flex-row bg-white rounded-md"
        style={{ boxShadow: "var(--shadows-panel)" }}
      >
        <div className="flex flex-row divide-x-2 divide-purple-200 items-center">
          {!isOutsideHubSpot && (
            <div>
              <Button
                startIcon={<FullscreenIcon />}
                color="secondary"
                variant="text"
                style={{ height: 40 }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  const currentLocation = window.location;
                  const outsideURL = outsideHubSpotURL(currentLocation.href);
                  window.open(outsideURL, "_blank");
                }}
              >
                Open Fullscreen
              </Button>
            </div>
          )}
          <div>
            <Button
              startIcon={<AddIcon />}
              color="secondary"
              variant="text"
              style={{ height: 40 }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                actions.startAddingObjectToChart();
              }}
            >
              Add to Map
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={!!draftState}
        onClose={handleClose}
      >
        <AddObjectToChartPopoverContent />
      </Dialog>
    </>
  );
});

const HGDevToolbar: React.FC<{}> = observer((props) => {
  const hgApp = useHGApp();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = !!anchorEl;

  return (
    <div className="border-2 border-red-500 rounded-md px-2 py-2 relative bg-white">
      <Button
        variant="contained"
        color="secondary"
        style={{ textTransform: "none" }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        dev menu
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={async () => {
            actions.addShape();
            setAnchorEl(null);
          }}
        >
          Add shape
        </MenuItem>

        <MenuItem
          onClick={async () => {
            // actions.addShape();
            setAnchorEl(null);

            actions.checkForFetches({ force: true });
          }}
        >
          Refresh associations
        </MenuItem>

        <MenuItem
          onClick={async () => {
            actions.closeRelationshipMap();
            setAnchorEl(null);
          }}
        >
          Close relationship map
        </MenuItem>
        <MenuItem
          onClick={async () => {
            actions.togglePrivacyMode();
            setAnchorEl(null);
          }}
        >
          Toggle privacy mode
        </MenuItem>
      </Menu>
    </div>
  );
});

const AddToChartModal = observer(() => {
  const app = useHGApp();

  const draftState = !!app.store.draftAddingObjectToChart;

  const handleClose = () => {
    actions.cancelAddingObjectToChart();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={!!draftState}
      onClose={handleClose}
    >
      <AddObjectToChartPopoverContent />
    </Dialog>
  );
});

const RelationshipMapEditor = observer(() => {
  const hgApp = useHGApp();

  const roomId = hgApp.store.currentRelationshipMap?.id;

  const [panelOpen, setPanelOpen] = useState<boolean>(false);

  // show panel if we have a focused object
  useEffect(() => {
    if (hgApp.store.focusedObject) {
      setPanelOpen(true);
    }
  }, [!!hgApp.store.focusedObject]);

  const handleClosePanel = useCallback(() => {
    setPanelOpen(false);
  }, []);

  const handlePanelClosed = useCallback(() => {
    actions.unfocusObject();
  }, []);

  assert(
    roomId,
    "should not render RelationshipMapEditor without currentRelationshipMap.id in store",
  );

  const loadingMultiplayerState = hgApp.store.loadingMultiplayer;

  return (
    <RoomProvider id={roomId}>
      <div className="flex flex-col items-start w-screen h-screen overflow-y-scroll relative">
        <div className="flex flex-col flex-auto w-full">
          <Canvas />
        </div>

        {/* <div
          className={`absolute bottom-4 z-[300] ${
            isOutsideHubSpot
              ? "left-[calc(50%-315px)]"
              : "left-[calc(50%-490px)]"
          }`}
        >
          <HGToolbar />
        </div> */}

        {/* <div
          className="absolute z-[300]"
          style={{
            top: "50%",
            left: "1rem",
            transform: "translateY(-50%)",
          }}
        >
          <Toolbar />
        </div> */}

        <AppSidebar open={panelOpen} onPanelClosed={handlePanelClosed}>
          <div className="flex flex-row h-full">
            <HGObjectSidebarImpl onClosePanel={handleClosePanel} />

            <div className="relative w-[80px] z-10">
              <Toolbar />
            </div>

            <div className="absolute z-10 whitespace-nowrap left-[calc(100%-60px)]">
              <RelationshipMapSwitcherToolbar />
            </div>
          </div>
        </AppSidebar>

        <div className="absolute bottom-4 right-4 z-10">
          <ZoomToolbar />
        </div>

        {hgApp.store.devMenuVisible && (
          <div className="absolute top-4 right-4 z-10">
            <HGDevToolbar />
          </div>
        )}

        {/* <HGObjectSidebar /> */}

        <AddToChartModal />

        <AddAssociatedToChartModal />

        {hgApp.store.auth.impersonation && (
          <div className="absolute bottom-2 left-2 bg-red-500 px-4 py-2 font-medium text-red-50 z-10">
            impersonation session
          </div>
        )}

        {loadingMultiplayerState && (
          <div className="absolute inset-0 bg-white opacity-80 z-10 flex items-center justify-center">
            <CircularProgress color="secondary" />
          </div>
        )}
      </div>
    </RoomProvider>
  );
});

const HGApp: React.FC<{}> = observer(() => {
  const hgApp = useHGApp();
  const mapId = hgApp.store.currentRelationshipMap?.id;

  useKeyboardShortcuts();

  useStylesheet();

  if (
    !hgApp.store.initialized ||
    (mapId && !hgApp.store.initializedEnoughForMap)
  ) {
    return (
      <div className="h-screen w-screen flex items-center justify-center bg-gray-100">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <>
      {mapId && <RelationshipMapEditor />}
      <RelationshipMapSelector />
    </>
  );
});

const queryClient = new QueryClient();

const AppWrapper = () => {
  useEffectOnce(() => {
    const fn = async () => {
      console.log("initializing app...");
      await actions.initialize();
    };
    fn().catch(console.error);
  });

  return (
    <QueryClientProvider client={queryClient}>
      <HGAppContext.Provider value={hgApp}>
        <HGApp />
      </HGAppContext.Provider>
    </QueryClientProvider>
  );
};

export default AppWrapper;
