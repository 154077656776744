import * as ts from "io-ts";
import * as domain from "../domain";
import { API_BASE_URL } from "../config";
import { parseOrThrow } from "../utils";

function baseHeaders(): { [k: string]: string } {
  return {
    "x-och-app": "och",
    "content-type": "application/json",
    accept: "application/json",
  };
}

function headersWithAuth(authState: domain.AuthState): { [k: string]: string } {
  return {
    authorization: `Bearer ${authState.accessToken}`,
    ...baseHeaders(),
  };
}

export async function createMap(
  authState: {
    accessToken: string;
  },
  params: {
    id: string;
    fromTemplateId: string;
  },
): Promise<{ id: string }> {
  const { id, fromTemplateId } = params;

  const RequestBody = ts.type({
    id: ts.string,
    fromTemplateId: ts.string,
  });

  const ResponseBody = ts.type({
    id: ts.string,
  });

  const res = await fetch(`${API_BASE_URL}/api/rm/v1/maps`, {
    method: "POST",
    headers: {
      ...headersWithAuth(authState),
    },
    body: JSON.stringify(
      RequestBody.encode({
        id,
        fromTemplateId,
      }),
    ),
  });

  const body = await res.json();

  const decoded = parseOrThrow(ResponseBody, body);

  return { id: decoded.id };
}
