import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHGApp } from "../store";
import * as firebaseApi from "../api/firebase";
import * as actions from "../actions";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useTransformer } from "../hooks/hooks";
import { RelationshipMap, toShortDateTime } from "../domain";
import { Button, CircularProgress, Dialog, TextField } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import _ from "lodash";
import { assertNever } from "../utils";
import {
  SearchableList,
  SearchableListRow,
  SearchableListButtonRow,
} from "./primitives/SearchableList";

type RelationshipMapRow = SearchableListRow<RelationshipMap>;

function RelationshipMapSelectorFooter({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="flex p-4 bg-gray-100">{children}</div>;
}

function RelationshipMapSelectorContainer({
  disabled,
  children,
}: {
  disabled: boolean;
  children: React.ReactNode;
}) {
  return (
    <fieldset
      disabled={disabled}
      className="flex flex-col flex-1 min-h-0 min-w-0 disabled:opacity-30 disabled:pointer-events-none relative"
    >
      {children}
      {disabled && (
        <div className="absolute inset-0 flex items-center justify-center">
          <CircularProgress color="secondary" />
        </div>
      )}
    </fieldset>
  );
}

const RelationshipMapSelectorRow = (props: {
  disabled?: boolean;
  relationshipMap: RelationshipMap;
  buttonLabel: string;
  onSelectRelationshipMap: (relationshipMap: RelationshipMap) => void;
}) => {
  const { disabled, relationshipMap, onSelectRelationshipMap, buttonLabel } =
    props;

  const handleOpen = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onSelectRelationshipMap(relationshipMap);
    },
    [],
  );

  return (
    <button
      disabled={!!disabled}
      onClick={handleOpen}
      className="flex flex-row justify-between items-center hover:bg-gray-200 active:bg-blue-100 cursor-pointer py-6 px-4 hover:bg-blue-50 cursor-pointer text-left space-y-2 select-none transition-all"
    >
      <div className="flex flex-col pr-4 space-y-2">
        <h2 className="text-lg text-blue-900 font-semibold leading-tight">
          {relationshipMap.name}
        </h2>

        {relationshipMap.description && (
          <div className="font-regular text-gray-800 text-sm leading-tight">
            {relationshipMap.description}
          </div>
        )}

        <div className="flex flex-row">
          <span className="text-xs font-regular text-gray-800 leading-none">
            Last updated {toShortDateTime(relationshipMap.updatedAt)}
          </span>
        </div>
      </div>

      <div className="shrink-0 font-medium text-sm bg-black bg-opacity-10 rounded-md p-2 hover:bg-blue-200 hover:text-blue-600 active:bg-blue-300 active:text-blue-600 transition-all">
        {buttonLabel}
      </div>
    </button>
  );
};

function CreateFromBlankRelationshipMapRow({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <button
      disabled={!!disabled}
      onClick={onClick}
      className="flex-1 flex flex-row justify-between items-center hover:bg-gray-200 active:bg-blue-100 cursor-pointer py-8 px-4 hover:bg-blue-50 cursor-pointer text-left space-y-2 select-none bg-white transition-all"
    >
      <div className="flex flex-col pr-4 space-y-2">
        <h2 className="text-lg text-blue-900 font-semibold leading-tight">
          Create from empty
        </h2>

        <div className="font-regular text-gray-800 text-base leading-tight">
          Start with an empty relationship map
        </div>
      </div>

      <div className="shrink-0 text-sm font-medium text-blue-500 bg-blue-100 rounded-md p-2 hover:bg-blue-200 hover:text-blue-600 transition-all active:bg-blue-300 active:text-blue-600">
        Create from blank
      </div>
    </button>
  );
}

function SelectorScreenSelectExisting(props: {
  disabled: boolean;
  relationshipMaps: RelationshipMap[];
  onSelectRelationshipMap: (relationshipMap: RelationshipMap) => void;
  onSelectViewTemplates: () => void;
  onSelectViewArchivedMaps: () => void;
  onSelectViewArchivedTemplates: () => void;
  onCreateNewRelationshipMap: () => void;
}) {
  const {
    disabled,
    relationshipMaps,
    onSelectRelationshipMap,
    onSelectViewTemplates,
    onSelectViewArchivedMaps,
    onSelectViewArchivedTemplates,
    onCreateNewRelationshipMap,
  } = props;

  interface ViewActionRowItem {
    actionType:
      | "view-templates"
      | "view-archived-maps"
      | "view-archived-templates";
  }

  function isViewActionRowItem(
    item: RelationshipMap | ViewActionRowItem,
  ): item is ViewActionRowItem {
    return item.hasOwnProperty("actionType");
  }

  type Row = SearchableListRow<RelationshipMap | ViewActionRowItem>;

  const liveMaps = relationshipMaps.filter(
    (map) => !map.archived && !map.isTemplate,
  );
  const hasTemplates = relationshipMaps.some(
    (map) => !map.archived && map.isTemplate,
  );
  const hasArchivedTemplates = relationshipMaps.some(
    (map) => map.isTemplate && map.archived,
  );
  const hasArchivedMaps = relationshipMaps.some(
    (map) => !map.isTemplate && map.archived,
  );

  const existingMapRows: RelationshipMapRow[] = _.chain(liveMaps)
    .map((relationshipMap) => {
      const row: RelationshipMapRow = {
        groupOrder: 0,
        id: relationshipMap.id,
        item: relationshipMap,
      };
      return row;
    })
    .sortBy((map) => map.item.updatedAt)
    .reverse()
    .value();

  const rows: Row[] = [...existingMapRows];

  if (hasTemplates) {
    rows.push({
      groupName: "Templates",
      groupOrder: 1,
      id: "view-templates",
      item: { actionType: "view-templates" },
    });
  }

  if (hasArchivedMaps) {
    rows.push({
      groupName: "Archived",
      groupOrder: 2,
      id: "view-archived-maps",
      item: { actionType: "view-archived-maps" },
    });
  }

  if (hasArchivedTemplates) {
    rows.push({
      groupName: "Archived",
      groupOrder: 2,
      id: "view-archived-templates",
      item: { actionType: "view-archived-templates" },
    });
  }

  return (
    <RelationshipMapSelectorContainer disabled={disabled}>
      <SearchableList
        disabled={disabled}
        rows={rows}
        filterRow={(item, query) => {
          if (isViewActionRowItem(item)) {
            return false;
          }
          return item.name.toLowerCase().includes(query.toLowerCase());
        }}
        renderRow={(item) => {
          if (isViewActionRowItem(item)) {
            let label: string;
            let action: () => void;

            switch (item.actionType) {
              case "view-templates": {
                label = "View templates";
                action = onSelectViewTemplates;
                break;
              }
              case "view-archived-templates": {
                label = "View archived templates";
                action = onSelectViewArchivedTemplates;
                break;
              }
              case "view-archived-maps": {
                label = "View archived relationship maps";
                action = onSelectViewArchivedMaps;
                break;
              }

              default: {
                assertNever(item.actionType);
              }
            }

            return (
              <SearchableListButtonRow
                disabled={disabled}
                label={label}
                buttonLabel="View"
                onClick={action}
              />
            );
          } else {
            return (
              <RelationshipMapSelectorRow
                disabled={disabled}
                relationshipMap={item}
                buttonLabel="Open Map"
                onSelectRelationshipMap={onSelectRelationshipMap}
              />
            );
          }
        }}
        renderNotFoundMesssage={({ query }) => {
          if (query === "") {
            return (
              <div className="flex flex-col space-y-2">
                <span className="text-gray-800">
                  Press{" "}
                  <strong className="font-semibold">
                    "Create new relationship map"
                  </strong>{" "}
                  to get started mapping out the relationships in your CRM.
                </span>
              </div>
            );
          } else {
            return (
              <span className="text-gray-800 whitespace">
                No relationship maps found for{" "}
                <span className="text-gray-900 font-medium whitespace-pre-wrap break-all">
                  &ldquo;{query.trim()}&rdquo;
                </span>
              </span>
            );
          }
        }}
        searchPlaceholderText="Search relationship maps"
      />
      <RelationshipMapSelectorFooter>
        <div className="flex-1 flex justify-end">
          <Button
            disabled={disabled}
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            onClick={onCreateNewRelationshipMap}
          >
            Create new relationship map
          </Button>
        </div>
      </RelationshipMapSelectorFooter>
    </RelationshipMapSelectorContainer>
  );
}

function SelectorScreenCreateFromTemplate(props: {
  disabled: boolean;
  relationshipMaps: RelationshipMap[];
  onSelectRelationshipMap: (relationshipMap: RelationshipMap) => void;
  onCreateFromBlank: () => void;
  onBack: () => void;
}) {
  const {
    disabled,
    relationshipMaps,
    onSelectRelationshipMap,
    onCreateFromBlank,
    onBack,
  } = props;

  const templates = relationshipMaps.filter(
    (map) => !map.archived && map.isTemplate,
  );

  interface CreateFromBlankRowItem {
    rowType: "CreateFromBlankRowItem";
  }

  function isCreateFromBlankRowItem(
    item: RelationshipMap | CreateFromBlankRowItem,
  ): item is CreateFromBlankRowItem {
    return item.hasOwnProperty("rowType");
  }

  type Row = SearchableListRow<RelationshipMap | CreateFromBlankRowItem>;

  const mapRows: RelationshipMapRow[] = _.chain(templates)
    .map((relationshipMap) => {
      const row: RelationshipMapRow = {
        groupOrder: 0,
        id: relationshipMap.id,
        item: relationshipMap,
      };
      return row;
    })
    .sortBy((map) => map.item.updatedAt)
    .reverse()
    .value();

  const rows: Row[] = [
    {
      groupOrder: 0,
      id: "create-from-blank",
      item: { rowType: "CreateFromBlankRowItem" },
    },
    ...mapRows,
  ];

  return (
    <RelationshipMapSelectorContainer disabled={disabled}>
      <SearchableList
        disabled={disabled}
        rows={rows}
        filterRow={(item, query) => {
          if (isCreateFromBlankRowItem(item)) {
            return false;
          }
          return item.name.toLowerCase().includes(query.toLowerCase());
        }}
        renderRow={(item) => {
          if (isCreateFromBlankRowItem(item)) {
            return (
              <CreateFromBlankRelationshipMapRow
                disabled={disabled}
                onClick={onCreateFromBlank}
              />
            );
          } else {
            return (
              <RelationshipMapSelectorRow
                disabled={disabled}
                relationshipMap={item}
                buttonLabel="Use this template"
                onSelectRelationshipMap={onSelectRelationshipMap}
              />
            );
          }
        }}
        renderNotFoundMesssage={({ query }) => {
          return (
            <span className="text-gray-800 whitespace">
              No templates found for{" "}
              <span className="text-gray-900 font-medium whitespace-pre-wrap break-all">
                &ldquo;{query.trim()}&rdquo;
              </span>
            </span>
          );
        }}
        searchPlaceholderText="Search templates"
      />
      <RelationshipMapSelectorFooter>
        <Button
          disabled={disabled}
          startIcon={<ChevronLeft />}
          variant="text"
          sx={{
            textTransform: "none",
          }}
          onClick={onBack}
        >
          Open an existing map instead
        </Button>
      </RelationshipMapSelectorFooter>
    </RelationshipMapSelectorContainer>
  );
}

function SelectorScreenSelectTemplate(props: {
  disabled: boolean;
  relationshipMaps: RelationshipMap[];
  onSelectRelationshipMap: (relationshipMap: RelationshipMap) => void;
  onBack: () => void;
}) {
  const { disabled, relationshipMaps, onSelectRelationshipMap, onBack } = props;

  const templates = relationshipMaps.filter(
    (map) => !map.archived && map.isTemplate,
  );

  const rows: RelationshipMapRow[] = _.chain(templates)
    .map((relationshipMap) => {
      const row: RelationshipMapRow = {
        groupName: "Templates",
        groupOrder: 0,
        id: relationshipMap.id,
        item: relationshipMap,
      };
      return row;
    })
    .sortBy((map) => map.item.updatedAt)
    .reverse()
    .value();

  return (
    <RelationshipMapSelectorContainer disabled={disabled}>
      <SearchableList
        rows={rows}
        disabled={disabled}
        filterRow={(relationshipMap, query) => {
          return relationshipMap.name
            .toLowerCase()
            .includes(query.toLowerCase());
        }}
        renderRow={(relationshipMap) => {
          return (
            <RelationshipMapSelectorRow
              disabled={disabled}
              relationshipMap={relationshipMap}
              buttonLabel="Edit template"
              onSelectRelationshipMap={onSelectRelationshipMap}
            />
          );
        }}
        renderNotFoundMesssage={({ query }) => {
          return (
            <span className="text-gray-800 whitespace">
              No templates found for{" "}
              <span className="text-gray-900 font-medium whitespace-pre-wrap break-all">
                &ldquo;{query.trim()}&rdquo;
              </span>
            </span>
          );
        }}
        searchPlaceholderText="Search templates"
      />
      <RelationshipMapSelectorFooter>
        <Button
          disabled={disabled}
          startIcon={<ChevronLeft />}
          variant="text"
          sx={{
            textTransform: "none",
          }}
          onClick={onBack}
        >
          Open an existing map instead
        </Button>
      </RelationshipMapSelectorFooter>
    </RelationshipMapSelectorContainer>
  );
}

function SelectorScreenSelectArchivedMap(props: {
  disabled: boolean;
  relationshipMaps: RelationshipMap[];
  onSelectRelationshipMap: (relationshipMap: RelationshipMap) => void;
  onBack: () => void;
}) {
  const { disabled, relationshipMaps, onSelectRelationshipMap, onBack } = props;

  const archivedMaps = relationshipMaps.filter(
    (map) => map.archived && !map.isTemplate,
  );

  const rows: RelationshipMapRow[] = _.chain(archivedMaps)
    .map((relationshipMap) => {
      const row: RelationshipMapRow = {
        groupName: "Archived relationship maps",
        groupOrder: 0,
        id: relationshipMap.id,
        item: relationshipMap,
      };
      return row;
    })
    .sortBy((map) => map.item.updatedAt)
    .reverse()
    .value();

  return (
    <RelationshipMapSelectorContainer disabled={disabled}>
      <SearchableList
        rows={rows}
        disabled={disabled}
        filterRow={(relationshipMap, query) => {
          return relationshipMap.name
            .toLowerCase()
            .includes(query.toLowerCase());
        }}
        renderRow={(relationshipMap) => {
          return (
            <RelationshipMapSelectorRow
              disabled={disabled}
              relationshipMap={relationshipMap}
              buttonLabel="Reopen map"
              onSelectRelationshipMap={onSelectRelationshipMap}
            />
          );
        }}
        renderNotFoundMesssage={({ query }) => {
          return (
            <span className="text-gray-800 whitespace">
              No relationship maps found for{" "}
              <span className="text-gray-900 font-medium whitespace-pre-wrap break-all">
                &ldquo;{query.trim()}&rdquo;
              </span>
            </span>
          );
        }}
        searchPlaceholderText="Search archived maps"
      />
      <RelationshipMapSelectorFooter>
        <Button
          disabled={disabled}
          startIcon={<ChevronLeft />}
          variant="text"
          sx={{
            textTransform: "none",
          }}
          onClick={onBack}
        >
          Open an existing map instead
        </Button>
      </RelationshipMapSelectorFooter>
    </RelationshipMapSelectorContainer>
  );
}

function SelectorScreenSelectArchivedTemplate(props: {
  disabled: boolean;
  relationshipMaps: RelationshipMap[];
  onSelectRelationshipMap: (relationshipMap: RelationshipMap) => void;
  onBack: () => void;
}) {
  const { disabled, relationshipMaps, onSelectRelationshipMap, onBack } = props;

  const archivedTemplates = relationshipMaps.filter(
    (map) => map.archived && map.isTemplate,
  );

  const rows: RelationshipMapRow[] = _.chain(archivedTemplates)
    .map((relationshipMap) => {
      const row: RelationshipMapRow = {
        groupName: "Archived templates",
        groupOrder: 0,
        id: relationshipMap.id,
        item: relationshipMap,
      };
      return row;
    })
    .sortBy((map) => map.item.updatedAt)
    .reverse()
    .value();

  return (
    <RelationshipMapSelectorContainer disabled={disabled}>
      <SearchableList
        disabled={disabled}
        rows={rows}
        filterRow={(relationshipMap, query) => {
          return relationshipMap.name
            .toLowerCase()
            .includes(query.toLowerCase());
        }}
        renderRow={(relationshipMap) => {
          return (
            <RelationshipMapSelectorRow
              disabled={disabled}
              relationshipMap={relationshipMap}
              buttonLabel="Reopen template"
              onSelectRelationshipMap={onSelectRelationshipMap}
            />
          );
        }}
        renderNotFoundMesssage={({ query }) => {
          return (
            <span className="text-gray-800 whitespace">
              No templates found for{" "}
              <span className="text-gray-900 font-medium whitespace-pre-wrap break-all">
                &ldquo;{query.trim()}&rdquo;
              </span>
            </span>
          );
        }}
        searchPlaceholderText="Search archived templates"
      />
      <RelationshipMapSelectorFooter>
        <Button
          disabled={disabled}
          variant="text"
          startIcon={<ChevronLeft />}
          sx={{
            textTransform: "none",
          }}
          onClick={onBack}
        >
          Open an existing map instead
        </Button>
      </RelationshipMapSelectorFooter>
    </RelationshipMapSelectorContainer>
  );
}

const RelationshipMapSelectorInner = (props: {
  portalId: string;
  currentMapId: string | undefined;
  waitingOnAPI: boolean;
  setWaitingOnAPI: (waitingOnAPI: boolean) => void;
}) => {
  const { portalId, currentMapId, waitingOnAPI, setWaitingOnAPI } = props;

  const [selectorScreen, setSelectorScreen] = useState<
    | "selecting-map"
    | "creating-from-template"
    | "viewing-templates"
    | "viewing-archived-maps"
    | "viewing-archived-templates"
  >("selecting-map");

  const mapsRef = firebaseApi.mapsCollectionRef(portalId);

  const [relationshipMaps, loading, error] = useCollectionData(mapsRef, {
    idField: "id",
    transform: useTransformer(RelationshipMap),
  });

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const templateMaps = (relationshipMaps || []).filter(
    (relationshipMap) => !!relationshipMap.isTemplate,
  );

  const handleOnCreateNewRelationshipMap = () => {
    if (templateMaps.length > 0) {
      setSelectorScreen("creating-from-template");
    } else {
      setWaitingOnAPI(true);
      actions.createNewRelationshipMap();
    }
  };

  const handleOnCreateFromBlank = () => {
    setWaitingOnAPI(true);
    actions.createNewRelationshipMap();
  };

  const handleOnSelectRelationshipMap = (relationshipMap: RelationshipMap) => {
    setWaitingOnAPI(true);

    switch (selectorScreen) {
      case "selecting-map":
      case "viewing-templates": {
        return actions.openRelationshipMap({
          relationshipMapId: relationshipMap.id,
        });
      }

      case "creating-from-template": {
        return actions.createNewRelationshipMapFromTemplate({
          templateMapId: relationshipMap.id,
        });
      }

      case "viewing-archived-maps":
      case "viewing-archived-templates": {
        return actions.reopenRelationshipMap({
          relationshipMapId: relationshipMap.id,
        });
      }

      default: {
        assertNever(selectorScreen);
      }
    }
  };

  const handleOnSelectViewTemplates = () => {
    setSelectorScreen("viewing-templates");
  };

  const handleOnSelectViewArchivedMaps = () => {
    setSelectorScreen("viewing-archived-maps");
  };

  const handleOnSelectViewArchivedTemplates = () => {
    setSelectorScreen("viewing-archived-templates");
  };

  const handleOnBack = () => {
    setSelectorScreen("selecting-map");
  };

  switch (selectorScreen) {
    case "selecting-map": {
      return (
        <SelectorScreenSelectExisting
          disabled={waitingOnAPI}
          relationshipMaps={relationshipMaps || []}
          onSelectRelationshipMap={handleOnSelectRelationshipMap}
          onSelectViewTemplates={handleOnSelectViewTemplates}
          onSelectViewArchivedMaps={handleOnSelectViewArchivedMaps}
          onSelectViewArchivedTemplates={handleOnSelectViewArchivedTemplates}
          onCreateNewRelationshipMap={handleOnCreateNewRelationshipMap}
        />
      );
    }

    case "viewing-templates": {
      return (
        <SelectorScreenSelectTemplate
          disabled={waitingOnAPI}
          relationshipMaps={relationshipMaps || []}
          onSelectRelationshipMap={handleOnSelectRelationshipMap}
          onBack={handleOnBack}
        />
      );
    }

    case "creating-from-template": {
      return (
        <SelectorScreenCreateFromTemplate
          disabled={waitingOnAPI}
          relationshipMaps={relationshipMaps || []}
          onSelectRelationshipMap={handleOnSelectRelationshipMap}
          onCreateFromBlank={handleOnCreateFromBlank}
          onBack={handleOnBack}
        />
      );
    }

    case "viewing-archived-maps": {
      return (
        <SelectorScreenSelectArchivedMap
          disabled={waitingOnAPI}
          relationshipMaps={relationshipMaps || []}
          onSelectRelationshipMap={handleOnSelectRelationshipMap}
          onBack={handleOnBack}
        />
      );
    }

    case "viewing-archived-templates": {
      return (
        <SelectorScreenSelectArchivedTemplate
          disabled={waitingOnAPI}
          relationshipMaps={relationshipMaps || []}
          onSelectRelationshipMap={handleOnSelectRelationshipMap}
          onBack={handleOnBack}
        />
      );
    }

    default: {
      assertNever(selectorScreen);
    }
  }
};

export const RelationshipMapSelector: React.FC<{}> = observer(() => {
  const hgApp = useHGApp();
  const portalId = hgApp.store.portalId;

  const isVisible =
    !hgApp.store.currentRelationshipMap || hgApp.store.switchingRelationshipMap;

  const [waitingOnAPI, setWaitingOnAPI] = useState<boolean>(false);

  useEffect(() => {
    if (!isVisible) {
      setWaitingOnAPI(false);
    }
  }, [isVisible]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={isVisible}
      onClose={() => {
        if (!waitingOnAPI && hgApp.store.currentRelationshipMap) {
          actions.stopSwitchingRelationshipMap();
        }
      }}
    >
      <div className="flex flex-col h-screen max-h-[483px] overflow-hidden">
        {isVisible && (
          <RelationshipMapSelectorInner
            currentMapId={hgApp.store.currentRelationshipMap?.id}
            portalId={portalId}
            waitingOnAPI={waitingOnAPI}
            setWaitingOnAPI={setWaitingOnAPI}
          />
        )}
      </div>
    </Dialog>
  );
});
