import React, { useState } from "react";
import _ from "lodash";
import { TextField } from "@mui/material";

export interface SearchableListRow<T> {
  groupName?: string;
  groupOrder: number;
  id: string;
  item: T;
}

export function SearchableListHeader({ label }: { label: string }) {
  return (
    <div className="text-sm text-gray-700 font-semibold bg-gray-200 py-2 px-4 sticky top-0 border-t-2 border-b-2 border-b-gray-300 border-t-gray-200 leading-tight select-none">
      {label}
    </div>
  );
}

export function SearchableListButtonRow({
  disabled,
  onClick,
  label,
  buttonLabel,
}: {
  disabled?: boolean;
  onClick: () => void;
  label: string;
  buttonLabel: string;
}) {
  return (
    <button
      disabled={!!disabled}
      onClick={onClick}
      className="flex flex-row justify-between items-center hover:bg-gray-200 active:bg-blue-100 cursor-pointer p-4 hover:bg-blue-50 cursor-pointer select-none transition-all group"
    >
      <div className="flex flex-col pr-4 text-base font-regular text-gray-800 group-active:text-blue-600 transition-all">
        {label}
      </div>

      <div className="shrink-0 font-medium text-sm bg-black bg-opacity-10 rounded-md p-2 hover:bg-blue-200 hover:text-blue-600 active:bg-blue-300 active:text-blue-600 transition-all">
        {buttonLabel}
      </div>
    </button>
  );
}

export function SearchableList<T>(props: {
  rows: SearchableListRow<T>[];
  searchPlaceholderText: string;
  disabled?: boolean;

  filterRow: (item: T, query: string) => boolean;
  renderRow: (item: T) => JSX.Element;
  renderNotFoundMesssage: ({ query }: { query: string }) => JSX.Element;
}) {
  const {
    rows,
    searchPlaceholderText,
    disabled,
    renderNotFoundMesssage,
    filterRow,
    renderRow,
  } = props;

  const [query, setQuery] = useState<string>("");

  const UNLABELLED_GROUP_NAME = "<unlabelled>";

  const groupOrders = rows.reduce((acc, row) => {
    acc[row.groupName || UNLABELLED_GROUP_NAME] = row.groupOrder;
    return acc;
  }, {} as { [groupName: string]: number });

  const filteredRows = rows.filter((row) => {
    if (query === "") {
      return true;
    }
    return filterRow(row.item, query);
  });

  const grouped = _.groupBy(
    filteredRows,
    (row) => row.groupName || UNLABELLED_GROUP_NAME,
  );

  let resultsList: JSX.Element;
  if (filteredRows.length === 0) {
    resultsList = (
      <div className="px-12 py-12 flex-1 flex flex-col items-center justify-center">
        {renderNotFoundMesssage({ query })}
      </div>
    );
  } else {
    resultsList = (
      <>
        {_.map(groupOrders, (groupOrder, groupName) => {
          const mapRows = grouped[groupName] || [];

          if (mapRows.length === 0) {
            return null;
          }

          return (
            <React.Fragment key={groupName}>
              {groupName !== UNLABELLED_GROUP_NAME && (
                <SearchableListHeader label={groupName} />
              )}

              {mapRows.map((row) => {
                return (
                  <div
                    key={row.id}
                    className="flex flex-col border-b-2 border-gray-200"
                  >
                    {renderRow(row.item)}
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  return (
    <div className="flex flex-col flex-1 min-h-0">
      <div className="px-3 pt-4 pb-3 bg-gray-100">
        <TextField
          disabled={!!disabled}
          autoFocus={true}
          autoComplete="off"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          label={searchPlaceholderText}
          variant="outlined"
          fullWidth={true}
        />
      </div>

      <div className="flex flex-col overflow-y-scroll flex-1 min-h-0">
        {resultsList}
      </div>
    </div>
  );
}
