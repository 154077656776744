import React from "react";
import { HGObject } from "../domain";
import * as domain from "../domain";
import * as icons from "@mui/icons-material";
import { Button } from "@mui/material";
import * as computeds from "../computeds";
import { observer } from "mobx-react-lite";
import { useHGApp } from "../store";
import _ from "lodash";

const bgColorClassesForObjectType: {
  [objectType: string]:
    | {
        dark: string;
        darker: string;
        darkest: string;
        light: string;
        darkBorder: string;
      }
    | undefined;
} = {
  contact: {
    darkBorder: "border-purple-400",
    darker: "bg-purple-300",
    darkest: "bg-purple-500",
    dark: "bg-purple-200",
    light: "bg-purple-100",
  },
  company: {
    darkBorder: "border-blue-400",
    darker: "bg-blue-300",
    darkest: "bg-blue-500",
    dark: "bg-blue-200",
    light: "bg-blue-100",
  },
  deal: {
    darkBorder: "border-emerald-400",
    darker: "bg-emerald-300",
    darkest: "bg-emerald-500",
    dark: "bg-emerald-200",
    light: "bg-emerald-100",
  },
};

const fgColorClassForObjectType: {
  [objectType: string]: string | undefined;
} = {
  contact: "text-purple-900",
  company: "text-blue-900",
  deal: "text-emerald-900",
};

function fgColorClass(objectType: string): string {
  return fgColorClassForObjectType[objectType] || "text-gray-900";
}

function bgColorClasses(objectType: string): {
  darkBorder: string;
  darker: string;
  darkest: string;
  dark: string;
  light: string;
} {
  return (
    bgColorClassesForObjectType[objectType] || {
      darkBorder: "border-gray-400",
      dark: "bg-gray-200",
      darker: "bg-gray-300",
      darkest: "bg-gray-400",
      light: "bg-gray-50",
    }
  );
}

export const AddToMapSuggestionRow: React.FC<{
  hgObject: HGObject;
  hgAssociations?: domain.HGAssociation[];
  onAddToMap: (hgObject: HGObject) => void;
}> = observer((props) => {
  const { hgObject, hgAssociations, onAddToMap } = props;

  const app = useHGApp();

  let iconNode: React.ReactNode | undefined;
  switch (hgObject.objectType) {
    case "contact": {
      iconNode = (
        <icons.Person
          color="inherit"
          className="relative"
          style={{ fontSize: 16, top: -1 }}
        />
      );
      break;
    }
    case "company": {
      iconNode = <icons.Business color="inherit" style={{ fontSize: 16 }} />;
      break;
    }
    case "deal": {
      iconNode = <icons.Handshake color="inherit" style={{ fontSize: 16 }} />;
      break;
    }
  }

  const Tag = (props: { label: string }) => {
    return (
      <div
        className={`shrink-0 pl-1 pr-3 py-1 rounded-md flex items-center justify-center ${
          bgColorClasses(hgObject.objectType).dark
        } ${fgColorClass(hgObject.objectType)} text-xs font-bold`}
      >
        <div className="pl-1 mr-1">{iconNode}</div>
        <div className="relative">{props.label}</div>
      </div>
    );
  };

  const associationLabelsJoinedString = _.chain(hgAssociations)
    .map((hgAssociation) => {
      return app.store.hgAssociationLabels[
        hgAssociation.associationLabelCanonicalId
      ];
    })
    .compact()
    .map((hgAssociationLabel) => hgAssociationLabel.label)
    .compact()
    .join(", ")
    .value();

  return (
    <div className="px-4 py-2 flex flex-row items-center">
      <div className="pl-1 space-y-0.5 mr-2 min-w-0 grow">
        <h4 className="text-sm font-medium text-gray-900 hg-privacy blur">
          {domain.objectDisplayName(hgObject)}
        </h4>

        <div className="flex flex-row space-x-2 items-center whitespace-nowrap">
          <Tag
            label={computeds.displayLabelForObjectTypeSingular(
              hgObject.objectType,
            )}
          />

          {!_.isEmpty(associationLabelsJoinedString) && (
            <div className="text-sm font-regular text-gray-900 text-ellipsis overflow-hidden">
              {associationLabelsJoinedString}
            </div>
          )}
        </div>
      </div>

      <div className="shrink-0">
        <Button
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={(e) => {
            onAddToMap(hgObject);
          }}
        >
          Add to map
        </Button>
      </div>
    </div>
  );
});
