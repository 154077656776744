import { action, runInAction } from "mobx";
import { app } from "../store";
import * as authApi from "../api/auth";
import * as hsApi from "../api/hubspot-api";
import * as useCases from "../use-cases/use-cases";
import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import {
  canonicalIdForAssociation,
  canonicalIdForAssociationLabel,
  canonicalIdForHGObjectRef,
  HGAssociation,
  HGAssociationLabel,
  HGDisplayProperty,
  HGObject,
  HGObjectRef,
  HGPortal,
  objectRefsEqual,
  userDisplayName,
  calculatePropertiesToFetchForObjectRefs,
  calculatePropertiesToFetchForAllObjectTypes,
  hubspotURLForObjectRef,
  associationIdsToRemoveBeforeReassert,
  updateURLParam,
} from "../domain";
import _ from "lodash";
import shortid from "shortid";
import { assert, indexBy, parseOrThrow } from "../utils";
import { CardShape, TDDocument } from "@orgcharthub/tldraw-tldraw";
import * as firebaseApi from "../api/firebase";
import * as hubgraphApi from "../api/hubgraph";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { makeNewInitialDocument } from "../domain/document";

const store = app.store;

function wrapTLDrawUpdate(fn: (tlApp: typeof app.tlApp) => void): void {
  fn(app.tlApp);
  app.store.documentForceUpdate += 1;
}

function makeAction<F extends (...args: any[]) => any>(
  actionName: string,
  fn: F,
  options: {
    quiet: boolean;
  } = { quiet: false },
): F {
  return action((...args: any[]) => {
    if (!options.quiet) {
      console.group(`action:${actionName}`, ...args);
    }
    const beforeState = _.cloneDeep(store);
    const result = fn(...args);
    const afterState = _.cloneDeep(store);
    if (!options.quiet) {
      console.log("processed action", { beforeState, afterState });
      console.groupEnd();
    }
    return result;
  }) as F;
}

function makeActionAsync<F extends (...args: any[]) => Promise<any>>(
  actionName: string,
  fn: F,
): F {
  return action(async (...args: any[]) => {
    console.group(`action:${actionName}`, ...args);
    const beforeState = _.cloneDeep(store);
    const result = await fn(...args);
    const afterState = _.cloneDeep(store);
    console.log("processed action", { beforeState, afterState });
    console.groupEnd();
    return result;
  }) as F;
}

function upsertMany<T extends { canonicalId: string }>(params: {
  entities: T[];
  state: { [id: string]: T };
}): void {
  const { state, entities } = params;
  for (const entity of entities) {
    const id = entity.canonicalId;
    state[id] = entity;
  }
  return;
}

function handleAuthenticateSuccess(nextAuthState: {
  accessToken: string;
  refreshToken?: string;
}): void {
  const { accessToken, refreshToken } = nextAuthState;
  // keep auth tokens away from URL as much as we can
  window.location.hash = "";

  runInAction(() => {
    store.auth.accessToken = accessToken;
    if (refreshToken) {
      store.auth.refreshToken = refreshToken;
    }
  });
}

export const receivePortalUpdate = makeAction(
  "receivePortalUpdate",
  (params: { portalData: HGPortal }): void => {
    const { portalData } = params;
    store.portal = portalData;
  },
);

function setupFirestoreLongLivedSubscriptions(params: { portalId: string }) {
  const { portalId } = params;
  const portalRef = firebaseApi.portalDocRef(portalId);
  portalRef.onSnapshot(
    (snapshot) => {
      const portalData = parseOrThrow(HGPortal, snapshot.data());
      receivePortalUpdate({
        portalData,
      });
    },
    (error) => {
      console.error(error);
    },
  );
}

export const startBackgroundServices = makeAction(
  "startBackgroundServices",
  () => {
    setInterval(() => {
      authenticationMaybeRefresh();
    }, 10 * 1000);
  },
);

export const initialize = makeActionAsync("initialize", async () => {
  const portalId = store.portalId;
  let accessToken: string | undefined;
  let refreshToken: string | undefined;

  firebaseApi.startFirebase();

  if (store.auth.queryToken) {
    console.log("found queryToken in state, using as accessToken");
    // handle dev-time passing of token in the URL
    handleAuthenticateSuccess({ accessToken: store.auth.queryToken });
    accessToken = store.auth.queryToken;
  } else if (!_.isEmpty(store.auth.authCode)) {
    console.log("found authCode in state, performing login");
    // login using an authCode (for example, set on the CRM Card iframe URL)
    const loginRes = await authApi.login(store.auth.authCode);
    handleAuthenticateSuccess(loginRes);
    accessToken = loginRes.accessToken;
    refreshToken = loginRes.refreshToken;
  } else {
    console.log(
      "no queryToken, no authToken, attempting cookie-session based auth",
    );
    // no other auth method given, so assume that we should have access
    // to an auth cookie. to handle this, attempt to refresh using any
    // session cookie we have right now, and if this doesn't work
    // then redirect to login to HubSpot to get a session cookie
    try {
      const nextAuthState = await authApi.refreshSession({ portalId });
      handleAuthenticateSuccess(nextAuthState);
      accessToken = nextAuthState.accessToken;
      refreshToken = nextAuthState.refreshToken;
    } catch (e) {
      // remove any auth tokens etc from the document hash (don't want to
      // leave it in the `returnTo` param)
      window.location.hash = "";
      const marketingSiteUrl = window.location.host.replace("map.", "");
      const url = `${marketingSiteUrl}/auth/hubspot/login`;
      const returnTo = window.location.href;
      const redirectUrl = `${
        window.location.protocol
      }//${url}?appRedirectUrl=${encodeURIComponent(
        returnTo,
      )}&portalId=${portalId}`;
      window.location.href = redirectUrl;
    }
  }

  if (!accessToken) {
    throw new Error("Failed to get an accessToken during initialize");
  }

  // login to firebase
  authApi.firebaseSignOut();
  await authApi.firebaseSignIn({ token: accessToken });

  // monitor auth state changes
  const waitForFirebaseUser = new Promise<boolean>((resolve, reject) => {
    authApi.onAuthStateChanged((user) => {
      console.log("user changed", user);
      if (user) {
        resolve(true);
      }
    });
  });

  // start background services (e.g. refreshing auth)
  startBackgroundServices();

  // initial subscriptions
  setupFirestoreLongLivedSubscriptions({
    portalId,
  });

  // fetch user information
  const userId = store.auth.userId;
  let user: {
    id: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
  };

  // can't fetch actual user in impersonation mode - won't be in the HS account
  if (store.auth.impersonation) {
    user = {
      id: userId,
      email: "",
      firstName: "",
      lastName: "",
    };
  } else {
    user = await hsApi.fetchOwner({
      authState: {
        accessToken,
      },
      userId,
    });
  }

  let color: string;
  if (user.email === "austin@orgcharthub.com") {
    color = "#1d4ed8";
  } else if (user.email === "dan@orgcharthub.com") {
    color = "#22c55e";
  } else {
    color = "#a16207";
  }

  const displayName = userDisplayName(user);

  console.log("waiting for firebase user...");
  await waitForFirebaseUser;

  runInAction(() => {
    store.initialized = true;
    store.user = {
      color,
      metadata: {
        email: user.email,
        displayName,
      },
      point: [0, 0],
      selectedIds: [],
    };
  });

  const minimumHSMapDependenciesMet = new Promise<boolean>(
    async (resolve, reject) => {
      assert(accessToken);

      let haveFetchedAssocitionLabels: boolean = false;
      let haveProperties: boolean = false;
      let havePropertyGroups: boolean = false;
      let haveCheckedDisplayProperties: boolean = false;
      let resolved: boolean = false;
      function maybeResove() {
        console.log("maybeResolve", {
          haveFetchedAssocitionLabels,
          haveProperties,
          havePropertyGroups,
          haveCheckedDisplayProperties,
        });
        if (
          haveFetchedAssocitionLabels &&
          haveProperties &&
          havePropertyGroups &&
          haveCheckedDisplayProperties &&
          !resolved
        ) {
          console.log("resolving minimum hs dependencies");
          resolve(true);
        }
      }

      await useCases.maybeAddDefaultDisplayProperties({
        portalId,
      });
      haveCheckedDisplayProperties = true;
      maybeResove();

      await useCases.ensureHubSpotDependencies({
        portalId,
        accessToken,
        onDependency: (event) => {
          console.log("received hubspot dependency event", event);
          runInAction(() => {
            switch (event.type) {
              case "DependencyEventSchemasDiscovered": {
                if (event.customObjectsSupported) {
                  store.customObjectsSupported = true;
                  upsertMany({
                    entities: event.schemas,
                    state: store.hgObjectSchemas,
                  });
                } else {
                  store.customObjectsSupported = false;
                }
                return;
              }

              case "DependencyEventAssociationLabelsFetched": {
                upsertMany({
                  entities: event.associationLabels,
                  state: store.hgAssociationLabels,
                });
                haveFetchedAssocitionLabels = true;
                maybeResove();
                return;
              }

              case "DependencyEventPropertyGroupsFetched": {
                upsertMany({
                  entities: event.propertyGroups,
                  state: store.hgPropertyGroups,
                });
                havePropertyGroups = true;
                maybeResove();
                return;
              }

              case "DependencyEventPropertiesFetched": {
                upsertMany({
                  entities: event.properties,
                  state: store.hgProperties,
                });
                haveProperties = true;
                maybeResove();
                return;
              }
            }
          });
        },
      });
    },
  );

  console.log("waiting for minimum hs dependencies for map...");
  await minimumHSMapDependenciesMet;
  console.log("have minimum hs dependencies for map");

  runInAction(() => {
    store.initializedEnoughForMap = true;
  });
});

export const updateCardSizeCache = makeAction(
  "updateCardSizeCache",
  (params: {
    objectCanonicalId: string;
    size: [width: number, height: number];
  }) => {
    app.store.cardSizeCache[params.objectCanonicalId] = [...params.size];
  },
);

export const addShape = makeAction("addShape", () => {
  const id = shortid();
  wrapTLDrawUpdate((tlApp) => {
    tlApp.createShapes({
      id,
      type: TDDraw.TDShapeType.Rectangle,
      name: "Rect",
      childIndex: 1,
      point: [_.random(1, 10) * 100, _.random(1, 10) * 100],
      size: [_.random(1, 3) * 100, _.random(1, 3) * 100],
      style: {
        dash: TDDraw.DashStyle.Draw,
        size: TDDraw.SizeStyle.Small,
        color: _.shuffle([
          TDDraw.ColorStyle.Red,
          TDDraw.ColorStyle.Green,
          TDDraw.ColorStyle.Orange,
          TDDraw.ColorStyle.Violet,
          TDDraw.ColorStyle.Cyan,
        ])[0],
      },
    });
  });
});

export const randomColor = makeAction(
  "randomColor",
  (tlApp: TDDraw.TldrawApp): void => {
    wrapTLDrawUpdate((tlApp) => {
      const currentDoc = tlApp.document;
      const rect_1 = currentDoc.pages.page_1.shapes.rect_1;
      const next = {
        ...currentDoc,
        pages: {
          ...currentDoc.pages,
          page_1: {
            ...currentDoc.pages.page_1,
            shapes: {
              ...currentDoc.pages.page_1.shapes,
              rect_1: {
                ...rect_1,
                style: {
                  ...rect_1.style,
                  color: _.shuffle([
                    TDDraw.ColorStyle.Red,
                    TDDraw.ColorStyle.Blue,
                    TDDraw.ColorStyle.Black,
                    TDDraw.ColorStyle.Green,
                    TDDraw.ColorStyle.Orange,
                  ])[0],
                },
              },
            },
          },
        },
      };
      tlApp.updateDocument(next);
    });
  },
);

// export const clickNodeConnector = action(
//   (params: { nodeId: string; hgObjectRef: HGObjectRef }): void => {
//     console.log("params", params);
//     console.log("state before", _.cloneDeep(store));

//     const tlApp = app.tlApp;

//     function getNextChildIndex() {
//       const shapes = Object.values(store.document.pages.page_1.shapes);
//       return shapes.length === 0
//         ? 1
//         : shapes.sort((a, b) => b.childIndex - a.childIndex)[0].childIndex + 1;
//     }

//     if (store.draftConnection) {
//       console.log("complete arrow session");
//       tlApp.completeSession();

//       store.draftConnection = undefined;
//     } else {
//       // const childIndex = this.getNextChildIndex()
//       const childIndex = getNextChildIndex();

//       const id = shortid();

//       const newShape = TDDraw.Arrow.create({
//         id,
//         parentId: "page_1",
//         childIndex,
//         point: tlApp.currentPoint,
//         style: {
//           color: TDDraw.ColorStyle.Red,
//           dash: TDDraw.DashStyle.Draw,
//           size: TDDraw.SizeStyle.Small,
//         },
//       });

//       tlApp.patchCreate([newShape]);

//       console.log("create new arrow session");
//       tlApp.startSession(TDDraw.SessionType.Arrow, newShape.id, "end", true);

//       store.draftConnection = {
//         from: { ...params.hgObjectRef, nodeId: params.nodeId },
//       };
//     }

//     console.log("state after", _.cloneDeep(store));

//     return;
//   },
// );

export const clickNodeConnector = makeAction(
  "clickNodeConnector",
  (params: { nodeId: string; hgObjectRef: HGObjectRef }): void => {
    console.log("params", params);

    if (store.draftConnection) {
      // don't allow same object to same object connections, except for company->company
      if (
        store.draftConnection.from.objectType === "company" ||
        store.draftConnection.from.objectType !== params.hgObjectRef.objectType
      ) {
        store.draftConnection.to = {
          nodeId: params.nodeId,
          objectId: params.hgObjectRef.objectId,
          objectType: params.hgObjectRef.objectType,
        };
      }
    } else {
      store.draftConnection = {
        from: {
          nodeId: params.nodeId,
          objectId: params.hgObjectRef.objectId,
          objectType: params.hgObjectRef.objectType,
        },
      };
    }
  },
);

export const cancelCreateAssociation = makeAction(
  "cancelCreateAssociation",
  () => {
    store.draftConnection = undefined;
    store.draftAssociationLabel = undefined;
  },
);

export const finishCreatingAssociation = makeActionAsync(
  "finishCreatingAssociation",
  async (params: { associationLabelCanonicalId: string }) => {
    const { associationLabelCanonicalId } = params;

    const accessToken = store.auth.accessToken;

    if (!accessToken) {
      throw new Error(
        "Cannot perform finishCreatingAssociation without access token",
      );
    }

    const from = store.draftConnection?.from;
    const to = store.draftConnection?.to;

    const associationLabel = _.find(store.hgAssociationLabels, (label) => {
      assert(label);
      return label.canonicalId === associationLabelCanonicalId;
    });

    console.log("in finishCreatingAssociation action", {
      params,
      from,
      to,
      associationLabel,
    });

    if (
      typeof from === "undefined" ||
      typeof to === "undefined" ||
      typeof associationLabel === "undefined"
    ) {
      console.warn("could not find association to update on HubSpot", {
        from,
        to,
        associationLabel,
      });
      return;
    }

    const objectRefA: HGObjectRef = {
      objectType: from.objectType,
      objectId: from.objectId,
    };

    const objectRefB: HGObjectRef = {
      objectType: to.objectType,
      objectId: to.objectId,
    };

    const canonicalId = canonicalIdForAssociation({
      objectRefA,
      objectRefB,
      associationLabelCanonicalId: associationLabel.canonicalId,
    });
    const hgAssociation: HGAssociation = {
      associationLabelCanonicalId: associationLabel.canonicalId,
      canonicalId,
      fromObjectRef: objectRefA,
      toObjectRef: objectRefB,
    };

    store.hgAssociations[canonicalId] = hgAssociation;
    store.draftConnection = undefined;

    // update HubSpot and undo local changes on failure
    // TODO: rollback on failure
    let typeId: number;
    if (
      associationLabel.category === "HUBSPOT_DEFINED" &&
      associationLabel.label === "Parent to Child" &&
      objectRefA.objectType === "company" &&
      objectRefB.objectType === "company"
    ) {
      // parent child associations need to use a specific directional typeId (13 = parent->child)
      typeId = 13;
    } else {
      typeId =
        objectRefA.objectType ===
        associationLabel.associationLabelDefinitions[0].fromObjectType
          ? associationLabel.associationLabelDefinitions[0].typeId
          : associationLabel.associationLabelDefinitions[1].typeId;
    }

    const { associations: nextAssociations } = await useCases.createAssociation(
      {
        authState: { accessToken },
        associationCategory: associationLabel.category,
        associationTypeId: typeId,
        fromObjectId: objectRefA.objectId,
        fromObjectType: objectRefA.objectType,
        toObjectType: objectRefB.objectType,
        toObjectId: objectRefB.objectId,
      },
    );

    // TODO: refresh associations so that we get any that HubSpot make as well (e.g. create primary and it makes unlabelled)
    // we might be able to return the data directly from the createAssociation call
    runInAction(() => {
      console.log("upserting next associations", nextAssociations);

      // remove existing associations between mentioned objects and other objects of the same type
      // that might conflict - we essentially have entirely new and up-to-date information,
      // so we don't want to keep anything
      const associationCanonicalIdsToRemove =
        associationIdsToRemoveBeforeReassert({
          affectedObjectRefs: [objectRefA, objectRefB],
          existingHGAssociations: Object.values(store.hgAssociations),
        });

      for (const canonicalId of associationCanonicalIdsToRemove) {
        delete store.hgAssociations[canonicalId];
      }

      // add in all the new association state
      upsertMany({
        entities: nextAssociations,
        state: store.hgAssociations,
      });
    });
  },
);

export const removeAssociation = makeActionAsync(
  "removeAssociation",
  async (params: { canonicalId: string }) => {
    const accessToken = store.auth.accessToken;

    if (!accessToken) {
      throw new Error(
        "Cannot perform finishCreatingAssociation without access token",
      );
    }

    const hgAssociation = store.hgAssociations[params.canonicalId] as
      | HGAssociation
      | undefined;

    if (!hgAssociation) {
      throw new Error("Cannot remove association that doesn not exist");
    }

    const hgAssociationLabel = store.hgAssociationLabels[
      hgAssociation.associationLabelCanonicalId
    ] as HGAssociationLabel | undefined;

    if (!hgAssociationLabel) {
      throw new Error(
        "Cannot remove association without information about the association label ",
      );
    }

    const fromObjectRef = hgAssociation.fromObjectRef;
    const toObjectRef = hgAssociation.toObjectRef;
    const associationCategory = hgAssociationLabel.category;
    const fromDef =
      hgAssociationLabel.associationLabelDefinitions[0].fromObjectType ===
      fromObjectRef.objectType
        ? hgAssociationLabel.associationLabelDefinitions[0]
        : hgAssociationLabel.associationLabelDefinitions[1];

    // parent->child associations need to use a specific direction (from is always parent), so
    // we need to make sure the associationTypeId matches (13 = parent->child)
    let associationTypeId: number;

    if (
      associationCategory === "HUBSPOT_DEFINED" &&
      hgAssociationLabel.label === "Parent to Child"
    ) {
      associationTypeId = 13;
    } else {
      associationTypeId = fromDef.typeId;
    }

    // optimistically delete
    delete store.hgAssociations[params.canonicalId];

    // actually delete, and rollback on failure
    // TODO: rollback on failure
    const { associations: nextAssociations } = await useCases.removeAssociation(
      {
        authState: { accessToken },
        fromObjectType: fromObjectRef.objectType,
        fromObjectId: fromObjectRef.objectId,
        toObjectType: toObjectRef.objectType,
        toObjectId: toObjectRef.objectId,
        associationCategory,
        associationTypeId,
      },
    );

    // TODO: refresh associations so that we get any that HubSpot make as well (e.g. create primary and it makes unlabelled)
    // we might be able to return the data directly from the removeAssociation call
    runInAction(() => {
      console.log(
        "removeAssociation upsert next associations",
        nextAssociations,
      );
      upsertMany({
        entities: nextAssociations,
        state: store.hgAssociations,
      });
    });
  },
);

export const startCreatingAssociationWithNewLabel = makeAction(
  "startCreatingAssociationWithNewLabel",
  () => {
    const fromObjectType = store.draftConnection?.from.objectType;
    const toObjectType = store.draftConnection?.to?.objectType;

    if (!fromObjectType || !toObjectType) {
      return;
    }

    store.draftAssociationLabel = {
      fromObjectType,
      toObjectType,
    };
  },
);

export const finishCreatingAssociationWithNewLabel = makeAction(
  "finishCreatingAssociationWithNewLabel",
  (params: { label: string }) => {
    const { label } = params;
    const from = store.draftConnection?.from;
    const to = store.draftConnection?.to;

    if (!from || !to) {
      return;
    }

    const category: HGAssociationLabel["category"] = "USER_DEFINED";

    const associationLabelDefinitions: HGAssociationLabel["associationLabelDefinitions"] =
      _.map(
        [
          [from.objectType, to.objectType],
          [to.objectType, from.objectType],
        ],
        ([fromObjectType, toObjectType]) => {
          const def: HGAssociationLabel["associationLabelDefinitions"][0] = {
            fromObjectType,
            toObjectType,
            typeId: _.random(1000, 2000),
          };
          return def;
        },
      );

    const associationLabelCanonicalId = canonicalIdForAssociationLabel({
      objectTypeA: from.objectType,
      objectTypeB: to.objectType,
      category,
      label,
    });

    const hgAssociationLabel: HGAssociationLabel = {
      label,
      canonicalId: associationLabelCanonicalId,
      category,
      color: "red",
      associationLabelDefinitions,
    };

    const objectRefA: HGObjectRef = from;
    const objectRefB: HGObjectRef = to;

    const associationCanonicalId = canonicalIdForAssociation({
      associationLabelCanonicalId,
      objectRefA,
      objectRefB,
    });

    const hgAssociation: HGAssociation = {
      associationLabelCanonicalId,
      canonicalId: associationCanonicalId,
      fromObjectRef: objectRefA,
      toObjectRef: objectRefB,
    };

    store.hgAssociationLabels[associationLabelCanonicalId] = hgAssociationLabel;
    store.hgAssociations[associationCanonicalId] = hgAssociation;

    store.draftConnection = undefined;
    store.draftAssociationLabel = undefined;
  },
);

export const startAddingObjectToChart = makeAction(
  "startAddingObjectToChart",
  () => {
    store.draftAddingObjectToChart = {
      objectType: "company",
    };
  },
);

export const cancelAddingObjectToChart = makeAction(
  "cancelAddingObjectToChart",
  () => {
    store.draftAddingObjectToChart = undefined;
  },
);

export const updateAddingObjectToChartObjectType = makeAction(
  "updateAddingObjectToChartObjectType",
  (params: { objectType: string }) => {
    if (!store.draftAddingObjectToChart) {
      return;
    }
    store.draftAddingObjectToChart.objectType = params.objectType;
  },
);

export const addObjectToChart = makeActionAsync(
  "addObjectToChart",
  async (params: { hgObject: HGObject; fromAssociation?: boolean }) => {
    const { hgObject, fromAssociation } = params;

    const accessToken = store.auth.accessToken;
    if (!accessToken) {
      throw new Error("Cannot addObjectToChart without accessToken");
    }

    const addingObjectRef: HGObjectRef = {
      objectId: hgObject.objectId,
      objectType: hgObject.objectType,
    };

    const currentShapes = (app.tlApp.document as TDDocument).pages.page_1
      .shapes;

    // if they are already on the map, don't add
    for (const shape of Object.values(currentShapes)) {
      if (shape.type !== "card") {
        continue;
      }

      const objectRef: HGObjectRef = {
        objectType: shape.meta.objectType,
        objectId: shape.meta.objectId,
      };

      if (objectRefsEqual(objectRef, addingObjectRef)) {
        store.draftAddingObjectToChart = undefined;
        return;
      }
    }

    // add the object to our database
    store.hgObjects[hgObject.canonicalId] = _.cloneDeep(hgObject);

    // get current canvas position and new index for shape
    const point = fromAssociation
      ? app.tlApp.currentPoint
      : app.tlApp.centerPoint;
    const childIndex = Object.values(currentShapes).length + 1;

    // add the shape for it
    const shapeId = shortid();
    const shape: CardShape = {
      id: shapeId,
      type: TDDraw.TDShapeType.Card,
      parentId: "page_1",
      name: "Card",
      childIndex,
      point,
      style: {
        dash: TDDraw.DashStyle.Draw,
        size: TDDraw.SizeStyle.Large,
        color: TDDraw.ColorStyle.Blue,
      },
      meta: {
        objectType: hgObject.objectType,
        objectId: hgObject.objectId,
      },
    };

    wrapTLDrawUpdate((tlApp) => {
      tlApp.createShapes(shape);
    });

    store.draftAddingObjectToChart = undefined;

    // fetch the associations for this object
    const propertiesToFetchByObjectType =
      calculatePropertiesToFetchForAllObjectTypes({
        displayProperties: app.store.portal?.["hg-display-properties"] || [],
        hgObjectSchemas: Object.values(app.store.hgObjectSchemas),
      });
    store.hgObjectsFetchingAssociations[hgObject.canonicalId] = true;
    const existingAssociationLabels = Object.values(store.hgAssociationLabels);
    const existingObjects = Object.values(store.hgObjects);
    const { associations, objects } = await useCases.fetchObjectAssociations({
      authState: { accessToken },
      existingAssociationLabels,
      existingObjects,
      objectRef: {
        objectId: hgObject.objectId,
        objectType: hgObject.objectType,
      },
      propertiesToFetchByObjectType,
    });
    runInAction(() => {
      upsertMany({
        entities: objects,
        state: store.hgObjects,
      });
      upsertMany({
        entities: associations,
        state: store.hgAssociations,
      });
      store.hgObjectsFetchingAssociations[hgObject.canonicalId] = false;
    });
  },
);

export const startAddingAssociatedObject = makeAction(
  "startAddingAssociatedObject",
  (params: { objectType: string; objectId: string }) => {
    const { objectType, objectId } = params;
    store.draftAddingAssociatedToChart = {
      objectType,
      objectId,
    };
  },
);

export const cancelAddingAssociatedObject = makeAction(
  "cancelAddingAssociatedObject",
  () => {
    store.draftAddingAssociatedToChart = undefined;
  },
);

export const addAssociatedObjectToChart = makeAction(
  "addAssociatedObjectToChart",
  (params: { hgObject: HGObject }) => {
    const { hgObject } = params;
    addObjectToChart({
      hgObject,
      fromAssociation: true,
    });
    store.draftAddingAssociatedToChart = undefined;
  },
);

export const openRelationshipMap = makeAction(
  "openRelationshipMap",
  (params: { relationshipMapId: string }) => {
    if (params.relationshipMapId === store.currentRelationshipMap?.id) {
      store.switchingRelationshipMap = false;
      return;
    }

    wrapTLDrawUpdate((tlApp) => {
      tlApp.patchState({ room: undefined });
      tlApp.loadDocument(makeNewInitialDocument("initial_document"));
    });
    store.currentRelationshipMap = {
      id: params.relationshipMapId,
      canUndo: false,
      canRedo: false,
    };
    store.switchingRelationshipMap = false;
    updateURLParam("mapId", params.relationshipMapId);
  },
);

export const reopenRelationshipMap = makeActionAsync(
  "reopenRelationshipMap",
  async (params: { relationshipMapId: string }) => {
    const { relationshipMapId } = params;

    const portalId = store.portalId;

    openRelationshipMap({
      relationshipMapId,
    });

    await firebaseApi.unarchiveRelationshipMap({
      portalId,
      id: relationshipMapId,
    });
  },
);

export const closeRelationshipMap = makeAction("closeRelationshipMap", () => {
  store.currentRelationshipMap = undefined;
  wrapTLDrawUpdate((tlApp) => {
    tlApp.patchState({ room: undefined });
    tlApp.loadDocument(makeNewInitialDocument("initial_document"));
  });
  updateURLParam("mapId", null);
});

export const updateRelationshipMapDetails = makeActionAsync(
  "updateRelationshipMapDetails",
  async (params: {
    nextName: string;
    nextDescription: string;
    nextIsTemplate: boolean;
    nextArchived: boolean;
    portalId: string;
    mapId: string;
  }) => {
    const { nextName, nextDescription, nextIsTemplate, portalId, mapId } =
      params;

    await firebaseApi.updateRelationshipMap({
      portalId: portalId,
      id: mapId,
      name: nextName,
      description: nextDescription,
      isTemplate: nextIsTemplate,
    });
  },
);

export const archiveRelationshipMap = makeActionAsync(
  "archiveRelationshipMap",
  async (params: { portalId: string; mapId: string }) => {
    const { portalId, mapId } = params;

    await firebaseApi.archiveRelationshipMap({
      portalId,
      id: mapId,
    });

    runInAction(() => {
      store.relationshipMapDetailsEditorVisible = false;
    });
    closeRelationshipMap();
  },
);

export const startSwitchingRelationshipMap = makeAction(
  "startSwitchingRelationshipMap",
  () => {
    store.switchingRelationshipMap = true;
  },
);

export const stopSwitchingRelationshipMap = makeAction(
  "stopSwitchingRelationshipMap",
  () => {
    store.switchingRelationshipMap = false;
  },
);

export const createNewRelationshipMap = makeActionAsync(
  "createNewRelationshipMap",
  async () => {
    const portalId = store.portalId;
    const id = firebaseApi.makeNewRelationshipMapId(store.portalId);

    // create the initial document
    await firebaseApi.createNewRelationshipMap({ portalId, id });

    store.currentRelationshipMap = {
      id,
      canRedo: false,
      canUndo: false,
    };
    store.switchingRelationshipMap = false;
    updateURLParam("mapId", id);
  },
);

export const createNewRelationshipMapFromTemplate = makeActionAsync(
  "createNewRelationshipMapFromTemplate",
  async (params: { templateMapId: string }) => {
    const { templateMapId } = params;
    const portalId = store.portalId;

    const accessToken = store.auth.accessToken;
    if (!accessToken) {
      throw new Error(
        "Cannot createNewRelationshipMapFromTemplate without accessToken",
      );
    }

    const id = firebaseApi.makeNewRelationshipMapId(store.portalId);

    // create the initial document (ideally server-side would do this after the liveblocks room is successfully created)
    await firebaseApi.createNewRelationshipMap({ portalId, id });

    // copy the template map into a new liveblocks document
    await hubgraphApi.createMap(
      {
        accessToken,
      },
      {
        id,
        fromTemplateId: templateMapId,
      },
    );

    store.currentRelationshipMap = {
      id,
      canRedo: false,
      canUndo: false,
    };
    store.switchingRelationshipMap = false;
    updateURLParam("mapId", id);
  },
);

export const checkForFetches = makeActionAsync(
  "checkForFetches",
  async (params: { force?: boolean } = {}) => {
    const { force = false } = params;

    const accessToken = store.auth.accessToken;
    if (!accessToken) {
      throw new Error("Cannot checkForFetches without accessToken");
    }

    const document = app.tlApp.document as TDDocument;

    const shapes = Object.values(document.pages.page_1.shapes);

    const hgObjectRefs = shapes
      .filter((shape) => shape.type === "card")
      .map((shape) => {
        assert(shape.type === "card");
        return {
          objectId: shape.meta.objectId,
          objectType: shape.meta.objectType,
        };
      });

    const neededObjectRefs: HGObjectRef[] = [];
    for (const hgObjectRef of hgObjectRefs) {
      if (force || !store.hgObjects[canonicalIdForHGObjectRef(hgObjectRef)]) {
        neededObjectRefs.push(hgObjectRef);
      }
    }

    console.log("needed object refs", neededObjectRefs);

    let entities: HGObject[] = [];
    let associations: HGAssociation[] = [];

    // fetch the objects first for faster loading on the chart
    const existingObjects = Object.values(store.hgObjects);
    const existingAssociationLabels = Object.values(store.hgAssociationLabels);

    // mark all objects as fetching
    console.log("marking objects as fetching...");
    runInAction(() => {
      for (const objectRef of neededObjectRefs) {
        store.hgObjectsFetchingAssociations[
          canonicalIdForHGObjectRef(objectRef)
        ] = true;
      }
    });

    const propertiesToFetchByObjectType =
      calculatePropertiesToFetchForObjectRefs({
        objectRefs: neededObjectRefs,
        displayProperties: app.store.portal?.["hg-display-properties"] || [],
        hgObjectSchemas: Object.values(app.store.hgObjectSchemas),
      });

    const propertiesToFetchForAllObjectTypes =
      calculatePropertiesToFetchForAllObjectTypes({
        displayProperties: app.store.portal?.["hg-display-properties"] || [],
        hgObjectSchemas: Object.values(app.store.hgObjectSchemas),
      });

    console.log("fetching objects first...", {
      neededObjectRefs,
      propertiesToFetchByObjectType,
    });
    const firstFetchObjectsRes = await useCases.fetchObjects({
      authState: { accessToken },
      objectRefs: neededObjectRefs,
      existingObjects: force ? [] : existingObjects,
      propertiesToFetchByObjectType,
    });
    runInAction(() => {
      console.log("upserting objects...", {
        count: firstFetchObjectsRes.objects.length,
      });
      upsertMany({
        entities: firstFetchObjectsRes.objects,
        state: store.hgObjects,
      });
    });

    console.log("fetching object associations...");
    for (const objectRef of neededObjectRefs) {
      // fetch the associations for this object
      const res = await useCases.fetchObjectAssociations({
        authState: { accessToken },
        existingAssociationLabels,
        existingObjects: force ? [] : existingObjects,
        objectRef,
        propertiesToFetchByObjectType: propertiesToFetchForAllObjectTypes,
      });
      associations = [...associations, ...res.associations];
      entities = [...entities, ...res.objects];
    }

    console.log("new data to assert", { associations, entities });

    if (force) {
      runInAction(() => {
        for (const hgObject of Object.values(store.hgObjects)) {
          delete store.hgObjects[hgObject.canonicalId];
        }
        for (const hgAssociation of Object.values(store.hgAssociations)) {
          delete store.hgAssociations[hgAssociation.canonicalId];
        }
      });
    }

    runInAction(() => {
      upsertMany({
        entities,
        state: store.hgObjects,
      });
      upsertMany({
        entities: associations,
        state: store.hgAssociations,
      });

      for (const objectRef of hgObjectRefs) {
        store.hgObjectsFetchingAssociations[
          canonicalIdForHGObjectRef(objectRef)
        ] = false;
      }
    });
  },
);

export const debouncedCheckForFetches = _.debounce(checkForFetches, 1000);

export const authenticationMaybeRefresh = makeAction(
  "authenticationMaybeRefresh",
  () => {
    try {
      // if we are using the local testing token then we can't refresh
      if (store.auth.queryToken) {
        return;
      }

      const accessToken = store.auth.accessToken;
      if (!accessToken) {
        return;
      }

      const decoded = jwtDecode<JwtPayload>(accessToken);

      const expiration = decoded.exp;
      if (!expiration) {
        return;
      }

      const nowSeconds = Date.now() / 1000;
      const diffSeconds = Math.floor(expiration - nowSeconds);
      if (diffSeconds < 60 * 5) {
        setTimeout(async () => {
          try {
            await authenticationRefresh();
          } catch (e) {
            console.error(e);
          }
        }, 0);
      }
    } catch (e) {
      console.error(e);
    }
  },
  {
    quiet: true,
  },
);

export const authenticationRefresh = makeActionAsync(
  "authenticationRefresh",
  async () => {
    const portalId = store.portalId;
    const refreshToken = store.auth.refreshToken;
    const nextAuthState = await authApi.refreshSession({
      portalId,
      refreshToken,
    });
    handleAuthenticateSuccess(nextAuthState);
  },
);

export const focusObject = makeAction(
  "focusObject",
  (params: { objectType: string; objectId: string }) => {
    const { objectType, objectId } = params;
    store.focusedObject = {
      objectType,
      objectId,
    };
  },
);

export const unfocusObject = makeAction("unfocusObject", () => {
  app.store.focusedObject = undefined;
});

export const startConfiguringDisplayProperties = makeAction(
  "startConfiguringDisplayProperties",
  (params: { objectType: string }) => {
    const existingDisplayProperties = _.cloneDeep(
      app.store.portal?.["hg-display-properties"] || [],
    );

    app.store.displayPropertiesConfigSession = {
      objectType: params.objectType,
      previousDisplayPropertyConfig: existingDisplayProperties,
    };
  },
);

export const finishConfiguringDisplayProperties = makeAction(
  "finishConfiguringDisplayProperties",
  () => {
    assert(app.store.displayPropertiesConfigSession);

    function displayPropertyKey(displayProperty: HGDisplayProperty): string {
      return `${displayProperty.objectType}:${displayProperty.name}`;
    }

    // if we have additional display properties then we need to fetch the objects again in case they have values for these properties
    const previousDisplayPropertyIndex = indexBy(
      app.store.displayPropertiesConfigSession.previousDisplayPropertyConfig,
      displayPropertyKey,
    );

    const haveNewDisplayPropertiesToFetch = (
      app.store.portal?.["hg-display-properties"] || []
    ).some((displayProperty) => {
      const key = displayPropertyKey(displayProperty);
      return !previousDisplayPropertyIndex[key];
    });

    if (haveNewDisplayPropertiesToFetch) {
      setTimeout(() => {
        checkForFetches({ force: true }).catch(console.error);
      }, 1);
    }

    app.store.displayPropertiesConfigSession = undefined;
  },
);

export const addDisplayProperty = makeActionAsync(
  "addDisplayProperty",
  async (params: { name: string; showOnCard: boolean; objectType: string }) => {
    const { name, objectType, showOnCard } = params;

    const portalId = store.portalId;
    const portal = store.portal;

    if (!portalId) {
      throw new Error("Cannot addDisplayProperty without portalId in store");
    }

    if (!portal) {
      throw new Error("Cannot addDisplayProperty without portal in store");
    }

    const existingDisplayProperties = portal["hg-display-properties"] || [];
    const nextDisplayProperties = _.chain(existingDisplayProperties)
      .filter(
        (displayProperty) =>
          !(
            displayProperty.name === name &&
            displayProperty.objectType === objectType
          ),
      )
      .push({
        name,
        showOnCard,
        objectType,
      })
      .value();

    const portalRef = firebaseApi.portalDocRef(portalId);

    const patch: {
      "hg-display-properties": HGDisplayProperty[];
    } = {
      "hg-display-properties": nextDisplayProperties,
    };

    await portalRef.update(patch);
  },
);

export const removeDisplayProperty = makeActionAsync(
  "removeDisplayProperty",
  async (params: { name: string; objectType: string }) => {
    const { name, objectType } = params;

    const portalId = store.portalId;
    const portal = store.portal;

    if (!portalId) {
      throw new Error("Cannot removeDisplayProperty without portalId in store");
    }

    if (!portal) {
      throw new Error("Cannot removeDisplayProperty without portal in store");
    }

    const existingDisplayProperties = portal["hg-display-properties"] || [];
    const nextDisplayProperties = _.chain(existingDisplayProperties)
      .filter(
        (displayProperty) =>
          !(
            displayProperty.name === name &&
            displayProperty.objectType === objectType
          ),
      )
      .value();

    const portalRef = firebaseApi.portalDocRef(portalId);

    const patch: {
      "hg-display-properties": HGDisplayProperty[];
    } = {
      "hg-display-properties": nextDisplayProperties,
    };

    await portalRef.update(patch);
  },
);

export const updateDisplayProperty = makeActionAsync(
  "updateDisplayProperty",
  async (params: { name: string; showOnCard: boolean; objectType: string }) => {
    const { name, objectType, showOnCard } = params;

    const portalId = store.portalId;
    const portal = store.portal;

    if (!portalId) {
      throw new Error("Cannot updateDisplayProperty without portalId in store");
    }

    if (!portal) {
      throw new Error("Cannot updateDisplayProperty without portal in store");
    }

    const existingDisplayProperties = portal["hg-display-properties"] || [];
    const nextDisplayProperties = _.chain(existingDisplayProperties)
      .map((displayProperty) => {
        if (
          displayProperty.name === name &&
          displayProperty.objectType === objectType
        ) {
          return {
            ...displayProperty,
            showOnCard,
          };
        } else {
          return displayProperty;
        }
      })
      .value();

    const portalRef = firebaseApi.portalDocRef(portalId);

    const patch: {
      "hg-display-properties": HGDisplayProperty[];
    } = {
      "hg-display-properties": nextDisplayProperties,
    };

    await portalRef.update(patch);
  },
);

export const openHSObjectInHubSpot = makeAction(
  "openHSObjectInHubSpot",
  (params: { objectRef: HGObjectRef }) => {
    const url = hubspotURLForObjectRef({
      portalId: store.portalId,
      portalHSDomain: "app.hubspot.com",
      objectRef: params.objectRef,
    });
    window.open(url, "_blank");
  },
);

export const togglePrivacyMode = makeAction("togglePrivacyMode", () => {
  store.privacyMode = !store.privacyMode;
});

export const toggleDevMenu = makeAction("toggleDevMenu", () => {
  store.devMenuVisible = !store.devMenuVisible;
});

export const showRelationshipMapDetailsEditor = makeAction(
  "showRelationshipMapDetailsEditor",
  () => {
    store.relationshipMapDetailsEditorVisible = true;
  },
);

export const hideRelationshipMapDetailsEditor = makeAction(
  "hideRelationshipMapDetailsEditor",
  () => {
    store.relationshipMapDetailsEditorVisible = false;
  },
);

export const setCanUndo = makeAction(
  "setCanUndo",
  (params: { canUndo: boolean }) => {
    if (!store.currentRelationshipMap) {
      return;
    }
    store.currentRelationshipMap.canUndo = params.canUndo;
  },
);

export const setCanRedo = makeAction(
  "setCanRedo",
  (params: { canRedo: boolean }) => {
    if (!store.currentRelationshipMap) {
      return;
    }
    store.currentRelationshipMap.canRedo = params.canRedo;
  },
);

/**
 * This is called by our liveblocks react hook so that we can tell in the
 * rest of the UI when we are waiting on the the liveblocks document to be
 * either setup or loaded. Not great but will do for now.
 */
export const setLoadingMultiplayer = makeAction(
  "setLoadingMultiplayer",
  (params: { loadingMultiplayer: boolean }) => {
    store.loadingMultiplayer = params.loadingMultiplayer;
  },
);
