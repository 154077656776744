import { useHotkeys } from "react-hotkeys-hook";
import * as actions from "../actions";
import { useHGApp } from "../store";

export function useKeyboardShortcuts() {
  const hgApp = useHGApp();

  const isCreatingDraftConnection = !!hgApp.store.draftConnection?.from;

  useHotkeys(
    "esc",
    (e) => {
      console.log("on esc");
      actions.cancelCreateAssociation();
    },
    {
      enabled: isCreatingDraftConnection,
    },
  );

  useHotkeys("option+shift+d", (e) => {
    actions.toggleDevMenu();
  });
}
