import React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./components/HGApp";
import "./App.css";
import { APP_VERSION } from "./config";

import * as mobx from "mobx";
// @ts-ignore
import mobxFormatters from "mobx-formatters";

mobxFormatters(mobx);

if (
  !new (class {
    x: any;
  })().hasOwnProperty("x")
)
  throw new Error("Transpiler is not configured correctly");

console.log(APP_VERSION);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement,
);

const renderApp = () => {
  root.render(<App />);
};

const renderAppStrictMode = () => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

if (import.meta.hot) {
  // import.meta.hot.on("vite:beforeUpdate", (e) =>
  //   console.log("before update", e),
  // );

  import.meta.hot.accept("./components/HGApp", () => {
    console.log("call renderApp from hot");
    renderApp();
  });
}

const STRICT_MODE = false;

if (STRICT_MODE) {
  renderAppStrictMode();
} else {
  renderApp();
}
