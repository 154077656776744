import React, { useLayoutEffect, useMemo } from "react";
import { useHGApp } from "../store";

const styles = new Map<string, HTMLStyleElement>();

const UID = `hg-dynamic-css`;

function makeCSS(params: { privacyMode: boolean }): string {
  const privacyModeCSS = `
  .hg-privacy.blur-none,
  .hg-privacy.blur-sm,
  .hg-privacy.blur,
  .hg-privacy.blur-md,
  .hg-privacy.blur-lg,
  .hg-privacy.blur-xl,
  .hg-privacy.blur-2xl,
  .hg-privacy.blur-3x {
    filter: none;
  }
  `;

  const finalCSS = [params.privacyMode ? "" : privacyModeCSS].join("\n");

  return finalCSS;
}

export function useStylesheet() {
  const hgApp = useHGApp();

  const privacyMode = hgApp.store.privacyMode;

  const css = useMemo(() => {
    return makeCSS({
      privacyMode,
    });
  }, [privacyMode]);

  React.useLayoutEffect(() => {
    let style: HTMLStyleElement;
    const existingElement = styles.get(UID);
    if (existingElement) {
      style = existingElement;
    } else {
      style = document.createElement("style");
      style.setAttribute("id", UID);
      document.head.appendChild(style);
      styles.set(UID, style);
    }

    style.innerHTML = css;

    return () => {
      if (style && document.head.contains(style)) {
        document.head.removeChild(style);
        styles.delete(UID);
      }
    };
  }, []);

  useLayoutEffect(() => {
    const existingElement = styles.get(UID);
    if (existingElement) {
      existingElement.innerHTML = css;
    }
  }, [css]);
}
