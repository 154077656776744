import * as ts from "io-ts";

export const HubSpotAPIObject = ts.type({
  id: ts.string,
  objectType: ts.string,
  properties: ts.record(ts.string, ts.union([ts.string, ts.null])),
});
export type HubSpotAPIObject = ts.TypeOf<typeof HubSpotAPIObject>;

export const HubSpotBatchObjectsReadAPIResponse = ts.type({
  results: ts.array(HubSpotAPIObject),
});
export type HubSpotBatchObjectsReadAPIResponse = ts.TypeOf<
  typeof HubSpotBatchObjectsReadAPIResponse
>;

export const HubSpotAPIAssociationLabel = ts.type({
  category: ts.union([
    ts.literal("HUBSPOT_DEFINED"),
    ts.literal("USER_DEFINED"),
    ts.literal("INTEGRATOR_DEFINED"),
  ]),
  typeId: ts.number,
  label: ts.union([ts.string, ts.null]),
});

export type HubSpotAPIAssociationLabel = ts.TypeOf<
  typeof HubSpotAPIAssociationLabel
>;

export const HubSpotAPIAssociation = ts.type({
  toObjectId: ts.string,
  associationTypes: ts.array(HubSpotAPIAssociationLabel),
});

export type HubSpotAPIAssociation = ts.TypeOf<typeof HubSpotAPIAssociation>;

export const HubSpotAssociationsListAPIResponse = ts.intersection([
  ts.type({
    results: ts.array(HubSpotAPIAssociation),
  }),
  ts.partial({
    paging: ts.partial({
      next: ts.partial({
        after: ts.string,
      }),
    }),
  }),
]);

export type HubSpotAssociationsListAPIResponse = ts.TypeOf<
  typeof HubSpotAssociationsListAPIResponse
>;

export const HubSpotBatchAPIAssociation = ts.type({
  from: ts.type({ id: ts.string }),
  to: ts.array(
    ts.type({
      toObjectId: ts.number,
      associationTypes: ts.array(HubSpotAPIAssociationLabel),
    }),
  ),
});
export type HubSpotBatchAPIAssociation = ts.TypeOf<
  typeof HubSpotBatchAPIAssociation
>;

export const HubSpotAssociationsBatchReadAPIResponse = ts.intersection([
  ts.type({
    results: ts.array(HubSpotBatchAPIAssociation),
  }),
  ts.partial({
    paging: ts.partial({
      next: ts.type({
        after: ts.string,
      }),
    }),
  }),
]);
export type HubSpotAssociationsBatchReadAPIResponse = ts.TypeOf<
  typeof HubSpotAssociationsBatchReadAPIResponse
>;

export const HubSpotSearchAPIResponse = ts.type({
  total: ts.number,
  results: ts.array(
    ts.type({
      id: ts.string,
      properties: ts.record(ts.string, ts.union([ts.string, ts.null])),
    }),
  ),
  // TODO: add paging when we need support for it
});

export const HubSpotReadAllPropertyGroupsAPIResponse = ts.intersection([
  ts.type({
    results: ts.array(
      ts.type({
        name: ts.string,
        label: ts.string,
        displayOrder: ts.number,
      }),
    ),
  }),
  ts.partial({
    paging: ts.partial({
      next: ts.type({
        after: ts.string,
      }),
    }),
  }),
]);
export type HubSpotReadAllPropertyGroupsAPIResponse = ts.TypeOf<
  typeof HubSpotReadAllPropertyGroupsAPIResponse
>;

const HubSpotAPIProperty = ts.intersection([
  ts.type({
    groupName: ts.string,
    hidden: ts.boolean,
    name: ts.string,
    displayOrder: ts.number,
    label: ts.string,
    type: ts.string,
    fieldType: ts.string,
  }),
  ts.partial({
    options: ts.array(
      ts.intersection([
        ts.type({
          label: ts.string,
          value: ts.string,
          displayOrder: ts.number,
          hidden: ts.boolean,
        }),
        ts.partial({
          description: ts.string,
        }),
      ]),
    ),
  }),
]);

export const HubSpotReadAllPropertiesAPIResponse = ts.intersection([
  ts.type({
    results: ts.array(HubSpotAPIProperty),
  }),
  ts.partial({
    paging: ts.partial({
      next: ts.type({
        after: ts.string,
      }),
    }),
  }),
]);
export type HubSpotReadAllPropertiesAPIResponse = ts.TypeOf<
  typeof HubSpotReadAllPropertiesAPIResponse
>;

export type HubSpotSearchAPIResponse = ts.TypeOf<
  typeof HubSpotSearchAPIResponse
>;

export const HubSpotOwnersGetAPIResponse = ts.intersection([
  ts.type({
    id: ts.string,
    userId: ts.number,
  }),
  ts.partial({
    firstName: ts.string,
    lastName: ts.string,
    email: ts.string,
  }),
]);

export type HubSpotOwnersGetAPIResponse = ts.TypeOf<
  typeof HubSpotOwnersGetAPIResponse
>;

export const HubSpotGetSchemasResponse = ts.type({
  results: ts.array(
    ts.type({
      id: ts.string,
      objectTypeId: ts.string,
      properties: ts.array(HubSpotAPIProperty),
      associations: ts.array(
        ts.type({
          id: ts.string,
          fromObjectTypeId: ts.string,
          toObjectTypeId: ts.string,
          name: ts.string,
        }),
      ),
      labels: ts.type({
        singular: ts.string,
        plural: ts.string,
      }),
      primaryDisplayProperty: ts.string,
      secondaryDisplayProperties: ts.array(ts.string),
      searchableProperties: ts.array(ts.string),
      requiredProperties: ts.array(ts.string),
      name: ts.string,
    }),
  ),
});
export type HubSpotGetSchemasResponse = ts.TypeOf<
  typeof HubSpotGetSchemasResponse
>;
