import type { HGAssociationLabel, HGObject, HGObjectRef } from "../domain";
import * as hubspot from "../api/hubspot-api";
import * as firebaseApi from "../api/firebase";
import * as domain from "../domain";
import _ from "lodash";
import { indexBy, withLocalDevCache } from "../utils";

export async function fetchInitialHubSpotDependencies(params: {
  supportedObjectTypes: string[];
  // existingHSProperties: domain.HSProperty[]
  authState: domain.AuthState;
}): Promise<{
  propertyGroups: domain.HSPropertyGroup[];
  properties: domain.HSProperty[];
  associationLabels: domain.HGAssociationLabel[];
}> {
  const { authState, supportedObjectTypes } = params;

  const associationLabels = await hubspot.fetchAssociationLabels({
    objectTypes: supportedObjectTypes,
    authState,
  });

  const propertyGroups = await hubspot.fetchAllPropertyGroupDefinitions({
    objectTypes: supportedObjectTypes,
    authState,
  });
  const properties = await hubspot.fetchAllPropertyDefinitions({
    objectTypes: supportedObjectTypes,
    authState,
  });

  return {
    propertyGroups,
    properties,
    associationLabels,
  };
}

export async function fetchObjects(params: {
  authState: domain.AuthState;
  objectRefs: HGObjectRef[];
  existingObjects: HGObject[];
  propertiesToFetchByObjectType: Record<string, string[] | undefined>;
}): Promise<{ objects: HGObject[] }> {
  console.log("use-cases/fetchObjects", params);

  const {
    authState,
    objectRefs,
    existingObjects,
    propertiesToFetchByObjectType,
  } = params;

  const fetchedObjectIds = indexBy(
    existingObjects,
    domain.canonicalIdForHGObjectRef,
  );

  const toFetchObjectRefs = objectRefs.filter((objectRef) => {
    const id = domain.canonicalIdForHGObjectRef(objectRef);
    return typeof fetchedObjectIds[id] === "undefined";
  });

  const objects = await hubspot.fetchObjects({
    authState,
    objectRefs: toFetchObjectRefs,
    propertiesToFetchByObjectType,
  });

  return { objects };
}

export async function fetchObjectAssociations(params: {
  authState: domain.AuthState;
  objectRef: HGObjectRef;
  existingObjects: HGObject[];
  existingAssociationLabels: HGAssociationLabel[];
  propertiesToFetchByObjectType: Record<string, string[]>;
}): Promise<{
  associations: domain.HGAssociation[];
  objects: domain.HGObject[];
}> {
  console.log("use-cases/fetchObjectAssociations", params);

  const {
    authState,
    objectRef: level0ObjectRef,
    existingObjects,
    existingAssociationLabels: associationLabels,
    propertiesToFetchByObjectType,
  } = params;

  console.log("associationLabels", associationLabels);

  const { associations: level0Associations, level1ObjectRefs } =
    await hubspot.fetchAssociationsForLevel0ObjectRef({
      authState,
      level0ObjectRef,
      associationLabels,
    });

  // const { associations: level1Associations, level2ObjectRefs } =
  //   await hubspot.fetchAssociationsForLevel1ObjectRefs({
  //     authState,
  //     associationLabels,
  //     level0ObjectRef,
  //     level1ObjectRefs: level1ObjectRefs,
  //   });

  // console.log("level1Associations/level2ObjectRefs", {
  //   level1Associations,
  //   level2ObjectRefs,
  // });

  const objectRefsToFetch = [
    level0ObjectRef,
    ...level1ObjectRefs,
    // ...level2ObjectRefs,
  ];

  console.log("objectRefsToFetch", objectRefsToFetch);

  const hgObjects = await hubspot.fetchObjects({
    authState,
    objectRefs: objectRefsToFetch,
    propertiesToFetchByObjectType,
  });

  let allAssociations = [
    ...level0Associations,
    // ...level1Associations
  ];

  // need to remove "duplicate" primary edges here because each level has the opportunity to add
  // in duplicate edges as we fetch "backwards" (e.g. associations from level 2 -> level 1)
  // allAssociations = domain.removeDuplicatePrimaryAssociations({
  //   hgAssociationLabels: associationLabels,
  //   hgAssociations: allAssociations,
  // });

  console.log("allAssociations", allAssociations);

  return {
    objects: [...hgObjects],
    associations: allAssociations,
  };
}

export async function createAssociation(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  associationCategory: HGAssociationLabel["category"];
  associationTypeId: number;
}): Promise<{ associations: domain.HGAssociation[] }> {
  console.log("createAssociation params", params);

  const { associations } = await hubspot.createAssociation(params);

  return {
    associations,
  };
}

export async function createAssociationWithNewLabel(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  label: string;
  name: string;
}): Promise<boolean> {
  // console.log("createAssociationWithNewLabel params", params);

  // const {
  //   authState,
  //   fromObjectType,
  //   fromObjectId,
  //   toObjectId,
  //   toObjectType,
  //   label,
  //   name,
  // } = params;

  // const associationLabelRes = await hubspot.createAssociationLabel({
  //   authState,
  //   fromObjectType,
  //   label,
  //   name,
  //   toObjectType,
  // });

  // const typeId = associationLabelRes.typeId;
  // const category = associationLabelRes.category;

  // await hubspot.createAssociation({
  //   authState,
  //   associationCategory: category,
  //   associationTypeId: typeId,
  //   fromObjectId,
  //   fromObjectType,
  //   toObjectId,
  //   toObjectType,
  // });

  return true;
}

export async function removeAssociation(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  associationCategory: HGAssociationLabel["category"];
  associationTypeId: number;
}): Promise<{ associations: domain.HGAssociation[] }> {
  const {
    authState,
    fromObjectType,
    fromObjectId,
    toObjectType,
    toObjectId,
    associationCategory,
    associationTypeId,
  } = params;

  console.log("removeAssociation params", params);

  const { associations } = await hubspot.removeAssociation({
    authState,
    fromObjectType,
    fromObjectId,
    toObjectType,
    toObjectId,
    associationCategory,
    associationTypeId,
  });

  return { associations };
}

interface DependencyEventSchemasDiscovered {
  type: "DependencyEventSchemasDiscovered";
  customObjectsSupported: boolean;
  schemas: domain.HGObjectSchema[];
}

interface DependencyEventAssociationLabelsFetched {
  type: "DependencyEventAssociationLabelsFetched";
  associationLabels: domain.HGAssociationLabel[];
  fromRemote: true;
}

interface DependencyEventPropertyGroupsFetched {
  type: "DependencyEventPropertyGroupsFetched";
  propertyGroups: domain.HSPropertyGroup[];
  fromRemote: boolean;
}

interface DependencyEventPropertiesFetched {
  type: "DependencyEventPropertiesFetched";
  properties: domain.HSProperty[];
  fromRemote: boolean;
}

type DependencyEvent =
  | DependencyEventSchemasDiscovered
  | DependencyEventAssociationLabelsFetched
  | DependencyEventPropertyGroupsFetched
  | DependencyEventPropertiesFetched;

export async function ensureHubSpotDependencies(params: {
  portalId: string;
  accessToken: string;
  onDependency: (event: DependencyEvent) => void;
}) {
  const { portalId, accessToken, onDependency } = params;

  // figure out which schemas we can support first
  console.log("discovering supported object schemas...");
  const { schemas, customObjectsSupported } = await withLocalDevCache(
    "supportedSchemas",
    async () => {
      return await hubspot.discoverSupportedSchemas({
        authState: { accessToken },
      });
    },
  );

  onDependency({
    type: "DependencyEventSchemasDiscovered",
    customObjectsSupported: customObjectsSupported,
    schemas,
  });

  const supportedObjectTypes = domain.calculateSupportedObjectTypes(schemas);

  console.log("supportedObjectTypes", supportedObjectTypes);

  console.log("fetching association labels...");
  const associationLabels = await withLocalDevCache(
    "associationLabels",
    async () => {
      return await hubspot.fetchAssociationLabels({
        objectTypes: supportedObjectTypes,
        authState: { accessToken },
      });
    },
  );

  onDependency({
    type: "DependencyEventAssociationLabelsFetched",
    associationLabels,
    fromRemote: true,
  });

  console.log("fetching/loading hubspot properties and property groups...");

  const localStorageKey = `${portalId}-initialHubSpotDependencies`;
  const fromLocalStorage = localStorage.getItem(localStorageKey);

  interface StorableHubSpotDependencies {
    properties: domain.HSProperty[];
    propertyGroups: domain.HSPropertyGroup[];
  }

  function storeHubSpotInitialDependencies(
    dependencies: StorableHubSpotDependencies,
  ) {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(dependencies, null, 2),
    );
  }

  async function refreshHubSpotInitialDependencies(): Promise<StorableHubSpotDependencies> {
    const propertyGroups = await hubspot.fetchAllPropertyGroupDefinitions({
      objectTypes: supportedObjectTypes,
      authState: { accessToken },
    });
    const properties = await hubspot.fetchAllPropertyDefinitions({
      objectTypes: supportedObjectTypes,
      authState: { accessToken },
    });

    const result = { propertyGroups, properties };

    return result;
  }

  function fireDependencyEvents(
    dependencies: StorableHubSpotDependencies,
    fromRemote: boolean,
  ) {
    onDependency({
      type: "DependencyEventPropertiesFetched",
      properties: dependencies.properties,
      fromRemote: fromRemote,
    });

    onDependency({
      type: "DependencyEventPropertyGroupsFetched",
      propertyGroups: dependencies.propertyGroups,
      fromRemote: fromRemote,
    });
  }

  if (fromLocalStorage) {
    console.log(
      "loading hubspot dependencies from storage and refreshing in the background...",
    );
    const localDependencies = JSON.parse(
      fromLocalStorage,
    ) as Awaited<StorableHubSpotDependencies>;
    fireDependencyEvents(localDependencies, false);

    try {
      const remoteDependencies = await refreshHubSpotInitialDependencies();
      fireDependencyEvents(remoteDependencies, true);
      storeHubSpotInitialDependencies(remoteDependencies);
    } catch (e) {
      console.error(e);
    }
  } else {
    console.log(
      "no hubspot dependencies in local storage, waiting for them...",
    );
    const remoteDependencies = await refreshHubSpotInitialDependencies();
    fireDependencyEvents(remoteDependencies, true);
    storeHubSpotInitialDependencies(remoteDependencies);
  }
}

export async function maybeAddDefaultDisplayProperties(params: {
  portalId: string;
}): Promise<void> {
  return await firebaseApi.addDefaultDisplayPropertiesIfRequired(params);
}
