import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import _ from "lodash";

const INITIAL_DOCUMENT: TDDraw.TDDocument = {
  id: "document_1",
  version: 15.4,
  pageStates: {
    page_1: {
      id: "page_1",
      selectedIds: [],
      camera: {
        point: [400, 200],
        zoom: 0.6,
      },
    },
  },
  assets: {},
  name: "something",
  pages: {
    page_1: {
      id: "page_1",
      name: "Some PAge",
      shapes: {
        // rect_1: {
        //   id: "rect_1",
        //   type: TDDraw.TDShapeType.Rectangle,
        //   parentId: "page_1",
        //   name: "Rect",
        //   childIndex: 1,
        //   point: [200, 200],
        //   size: [100, 100],
        //   style: {
        //     dash: TDDraw.DashStyle.Draw,
        //     size: TDDraw.SizeStyle.Small,
        //     color: TDDraw.ColorStyle.Blue,
        //   },
        // },
        // shape_1: {
        //   id: "shape_1",
        //   type: TDDraw.TDShapeType.Card,
        //   parentId: "page_1",
        //   name: "Card",
        //   childIndex: 1,
        //   point: [800, 100],
        //   size: [100, 100],
        //   style: {
        //     dash: TDDraw.DashStyle.Draw,
        //     size: TDDraw.SizeStyle.Large,
        //     color: TDDraw.ColorStyle.Blue,
        //   },
        //   meta: {
        //     objectType: "company",
        //     objectId: "9205252240",
        //   },
        // },
        // shape_2: {
        //   id: "shape_2",
        //   type: TDDraw.TDShapeType.Card,
        //   parentId: "page_1",
        //   name: "Card",
        //   childIndex: 1,
        //   point: [1200, 500],
        //   size: [100, 100],
        //   style: {
        //     dash: TDDraw.DashStyle.Draw,
        //     size: TDDraw.SizeStyle.Large,
        //     color: TDDraw.ColorStyle.Blue,
        //   },
        //   meta: {
        //     objectType: "contact",
        //     objectId: "7201",
        //   },
        // },
        // shape_3: {
        //   id: "shape_3",
        //   type: TDDraw.TDShapeType.Card,
        //   parentId: "page_1",
        //   name: "Card",
        //   childIndex: 1,
        //   point: [400, 500],
        //   size: [100, 100],
        //   style: {
        //     dash: TDDraw.DashStyle.Draw,
        //     size: TDDraw.SizeStyle.Large,
        //     color: TDDraw.ColorStyle.Blue,
        //   },
        //   meta: {
        //     objectType: "contact",
        //     objectId: "7151",
        //   },
        // },
        // shape_4: {
        //   id: "shape_4",
        //   type: TDDraw.TDShapeType.Card,
        //   parentId: "page_1",
        //   name: "Card",
        //   childIndex: 1,
        //   point: [800, 800],
        //   size: [100, 100],
        //   style: {
        //     dash: TDDraw.DashStyle.Draw,
        //     size: TDDraw.SizeStyle.Large,
        //     color: TDDraw.ColorStyle.Blue,
        //   },
        //   meta: {
        //     objectType: "deal",
        //     objectId: "9631380020",
        //   },
        // },
      },
      bindings: {},
    },
  },
};

export function makeNewInitialDocument(id: string): TDDraw.TDDocument {
  const newDoc = _.cloneDeep(INITIAL_DOCUMENT);
  return {
    ...newDoc,
    id,
  };
}
