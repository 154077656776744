import _ from "lodash";

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = import.meta.env
  .VITE_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID;
export const FIREBASE_MESSAGE_SENDER_ID = import.meta.env
  .VITE_FIREBASE_MESSAGE_SENDER_ID;
export const FIREBASE_DATABASE_URL = import.meta.env.VITE_FIREBASE_DATABASE_URL;

export const LIVEBLOCKS_PUBLIC_API_KEY = import.meta.env
  .VITE_LIVEBLOCKS_PUBLIC_API_KEY;

export const APP_BASE_URL = window.location.origin;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const IS_PROD = import.meta.env.PROD;

// injected at build time, not explicit env var
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;

function readURLState(): {
  portalId: string;
  objectId: string;
  objectType: string;
  authCode: string;
  userEmail: string;
  userId: string;
  mapId: string | null;
  queryToken: string | null;
  impersonation: boolean;
} {
  const queryParamString = window.location.search;
  const params = new URLSearchParams(queryParamString);

  const objectId = params.get("object-id");
  const objectType = params.get("object-type");
  const portalId = params.get("portal-id");
  const userEmail = params.get("userEmail");
  const userId = params.get("userId");
  const mapId = params.get("mapId");
  const localTestingToken = params.get("local-testing-token");
  const token = params.get("token");
  const impersonation = params.get("impersonation");

  function isValidString(s: string | null): s is string {
    return typeof s === "string" && !_.isEmpty(s);
  }

  // TODO: are we always going to have `userEmail` and `userId`?
  if (
    !isValidString(objectId) ||
    !isValidString(objectType) ||
    !isValidString(portalId) ||
    !isValidString(userEmail) ||
    !isValidString(userId)
  ) {
    console.error("missing required initial config from query params", {
      queryParamString,
    });
    throw new Error("Missing required initial config from query params");
  }

  const searchFragment =
    _.nth(decodeURIComponent(document.location.href).split("#"), 1) || "";

  const authCode = _.nth(searchFragment.match(/auth-code=(.*)/), 1) || "";

  let queryToken: string | null = null;
  if (localTestingToken === "true") {
    queryToken = `och-local-bearer-token:${portalId}:${userEmail}`;
  } else if (typeof token === "string") {
    queryToken = token;
  }

  return {
    portalId,
    objectId,
    objectType,
    authCode,
    userEmail,
    userId,
    mapId: mapId,
    impersonation: impersonation === "true",
    queryToken,
  };
}

export const INITIAL_URL_STATE = readURLState();
