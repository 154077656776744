import Bottleneck from "bottleneck";
import * as ts from "io-ts";
import * as domain from "../domain";
import * as HubSpotAPITypes from "./hubspot-api-types";
import { API_BASE_URL } from "../config";
import _ from "lodash";
import { assert, parseOrThrow } from "../utils";

const baseUrl = `${API_BASE_URL}/api/hubspotproxy`;

const limiter = new Bottleneck({
  maxConcurrent: 3,
  minTime: (10 * 1000) / 100,
});

function baseHeaders(): { [k: string]: string } {
  return {
    "x-och-app": "och",
    "content-type": "application/json",
    accept: "application/json",
  };
}

function headersWithAuth(authState: domain.AuthState): { [k: string]: string } {
  return {
    authorization: `Bearer ${authState.accessToken}`,
    ...baseHeaders(),
  };
}

function fetchRateLimited(
  input: URL | RequestInfo,
  init?: RequestInit,
): ReturnType<typeof fetch> {
  return limiter.schedule(async () => {
    return await fetch(input, init);
  });
}

async function fetchAllObjectPropertyDefinitions(params: {
  authState: domain.AuthState;
  objectType: string;
}): Promise<domain.HSProperty[]> {
  const { authState, objectType } = params;

  let apiResults: HubSpotAPITypes.HubSpotReadAllPropertiesAPIResponse["results"] =
    [];
  let after: string | undefined = "0";

  while (after) {
    const res = await fetchRateLimited(
      `${baseUrl}/crm/v3/properties/${objectType}`,
      {
        method: "GET",
        headers: {
          ...headersWithAuth(authState),
        },
      },
    );

    const body = await res.json();

    const decoded = parseOrThrow(
      HubSpotAPITypes.HubSpotReadAllPropertiesAPIResponse,
      body,
    );

    apiResults = [...apiResults, ...decoded.results];
    after = decoded.paging?.next?.after;
  }

  const hsProperties = _.map(apiResults, (apiProperty) => {
    // console.log("apiProperty", {
    //   ...apiProperty,
    //   objectType,
    //   canonicalId: domain.canonicalIdForHSProperty({
    //     objectType,
    //     name: apiProperty.name,
    //   }),
    // });
    return parseOrThrow(domain.HSProperty, {
      ...apiProperty,
      objectType,
      canonicalId: domain.canonicalIdForHSProperty({
        objectType,
        name: apiProperty.name,
      }),
    });
  });

  return hsProperties;
}

async function fetchAllObjectPropertyGroupDefinitions(params: {
  authState: domain.AuthState;
  objectType: string;
}): Promise<domain.HSPropertyGroup[]> {
  const { authState, objectType } = params;

  let apiResults: HubSpotAPITypes.HubSpotReadAllPropertyGroupsAPIResponse["results"] =
    [];
  let after: string | undefined = "0";

  while (after) {
    const res = await fetchRateLimited(
      `${baseUrl}/crm/v3/properties/${objectType}/groups`,
      {
        method: "GET",
        headers: {
          ...headersWithAuth(authState),
        },
      },
    );

    const body = await res.json();

    const decoded = parseOrThrow(
      HubSpotAPITypes.HubSpotReadAllPropertyGroupsAPIResponse,
      body,
    );

    apiResults = [...apiResults, ...decoded.results];
    after = decoded.paging?.next?.after;
  }

  const hsPropertyGroups = _.map(apiResults, (apiPropertyGroup) => {
    return parseOrThrow(domain.HSPropertyGroup, {
      ...apiPropertyGroup,
      objectType,
      canonicalId: domain.canonicalIdForHSPropertyGroup({
        objectType,
        name: apiPropertyGroup.name,
      }),
    });
  });

  return hsPropertyGroups;
}

export async function fetchAllPropertyDefinitions(params: {
  objectTypes: string[];
  authState: domain.AuthState;
}): Promise<domain.HSProperty[]> {
  const { objectTypes, authState } = params;

  let propertyDefinitions: domain.HSProperty[] = [];

  for (const objectType of objectTypes) {
    const defs = await fetchAllObjectPropertyDefinitions({
      authState,
      objectType,
    });
    propertyDefinitions = [...propertyDefinitions, ...defs];
  }

  return propertyDefinitions;
}

export async function fetchAllPropertyGroupDefinitions(params: {
  objectTypes: string[];
  authState: domain.AuthState;
}): Promise<domain.HSPropertyGroup[]> {
  const { objectTypes, authState } = params;

  let propertyGroupDefinitions: domain.HSPropertyGroup[] = [];

  for (const objectType of objectTypes) {
    const defs = await fetchAllObjectPropertyGroupDefinitions({
      authState,
      objectType,
    });
    propertyGroupDefinitions = [...propertyGroupDefinitions, ...defs];
  }

  return propertyGroupDefinitions;
}

export async function fetchAssociationLabels(params: {
  objectTypes: string[];
  authState: domain.AuthState;
}): Promise<domain.HGAssociationLabel[]> {
  const { objectTypes, authState } = params;

  type HubSpotAPIAssociationLabelWithFromToObjectTypes =
    HubSpotAPITypes.HubSpotAPIAssociationLabel & {
      fromObjectType: string;
      toObjectType: string;
    };

  const fetches: Promise<HubSpotAPIAssociationLabelWithFromToObjectTypes[]>[] =
    [];
  for (const fromObjectType of objectTypes) {
    for (const toObjectType of objectTypes) {
      if (
        fromObjectType === toObjectType &&
        !(fromObjectType === "company" && toObjectType === "company")
      ) {
        continue;
      }

      const thunk = async () => {
        const ExpectedBody = ts.interface({
          results: ts.array(HubSpotAPITypes.HubSpotAPIAssociationLabel),
        });

        const res = await fetchRateLimited(
          `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/labels`,
          {
            method: "GET",
            headers: {
              ...headersWithAuth(authState),
            },
          },
        );

        const body = await res.json();

        const decoded = parseOrThrow(ExpectedBody, body);

        const hgAssociationLabels: HubSpotAPIAssociationLabelWithFromToObjectTypes[] =
          _.map(decoded.results, (result) => {
            return {
              ...result,
              fromObjectType,
              toObjectType,
            };
          });

        console.log("labels request finished", {
          fromObjectType,
          toObjectType,
        });

        return hgAssociationLabels;
      };

      fetches.push(thunk());
    }
  }

  // wait for all the label information for each object type pair
  const results = await Promise.all(fetches);

  let hsAssociationLabels = results.flatMap((result) => result);
  // add in the labels for the built-in parent/child associations
  hsAssociationLabels = _.map(hsAssociationLabels, (hsAssociationLabel) => {
    if (
      hsAssociationLabel.fromObjectType === "company" &&
      hsAssociationLabel.toObjectType === "company"
    ) {
      if (
        hsAssociationLabel.typeId === 13 ||
        hsAssociationLabel.typeId === 14
      ) {
        return {
          ...hsAssociationLabel,
          label: "Parent to Child",
        };
      } else {
        return hsAssociationLabel;
      }
    } else {
      return hsAssociationLabel;
    }
  });

  // console.log("hs labels", hsAssociationLabels);

  // convert the HubSpot structure into our domain model, which involves:
  // - collapsing pairs of associations into one association
  //   - HubSpot associations are always two way, and represented by two
  //     unique association records. It’s a bit overkill for our use-case,
  //     so we'll just represent one way.
  // - renaming "Primary" associations for company records so that we can
  //   group on them - we don't really need the HubSpot labels maybe?
  //   - e.g. contact->company is labelled as "Primary", but company->contact
  //     is labelled as "Primary Company" even though they are the same
  //     association (represented in two directions)

  const hsAssociationLabelsByLabel = _.chain(hsAssociationLabels)
    .map((associationLabel) => {
      if (
        associationLabel.category === "HUBSPOT_DEFINED" &&
        associationLabel.label?.startsWith("Primary")
      ) {
        return {
          ...associationLabel,
          label: "Primary",
        };
      } else {
        return associationLabel;
      }
    })
    .groupBy((associationLabel) => {
      const objectPart = [
        associationLabel.fromObjectType,
        associationLabel.toObjectType,
      ]
        .sort()
        .join(":");
      return `${associationLabel.category}:${objectPart}:${associationLabel.label}`;
    })
    .reduce((acc, associationLabels) => {
      // console.log("acc/associationLabels", { acc, associationLabels });

      // some invariants that should not be broken here
      // - there should always be two associationLabels (one in each direction)
      // - we’ve filtered out the label-less associationLabels
      // - there should only be two object types mentioned, because the two
      //   associationLabels should mirror each other
      if (associationLabels.length !== 2) {
        return acc;
      }

      const associationLabelObjectTypeA = associationLabels[0];
      const associationLabelObjectTypeB = associationLabels[1];

      let mentionedObjectTypes = _.uniq([
        associationLabelObjectTypeA.fromObjectType,
        associationLabelObjectTypeA.toObjectType,
        associationLabelObjectTypeB.fromObjectType,
        associationLabelObjectTypeB.toObjectType,
      ]);

      // same object to same object association
      if (mentionedObjectTypes.length === 1) {
        mentionedObjectTypes = [
          mentionedObjectTypes[0],
          mentionedObjectTypes[0],
        ];
      }

      assert(mentionedObjectTypes.length === 2);

      const canonicalId = domain.canonicalIdForAssociationLabel({
        category: associationLabelObjectTypeA.category,
        label: associationLabelObjectTypeA.label,
        objectTypeA: mentionedObjectTypes[0],
        objectTypeB: mentionedObjectTypes[1],
      });

      const associationLabelDefinitions = _.map(
        [associationLabelObjectTypeA, associationLabelObjectTypeB],
        (hsAssociation) => {
          return {
            fromObjectType: hsAssociation.fromObjectType,
            toObjectType: hsAssociation.toObjectType,
            typeId: hsAssociation.typeId,
          };
        },
      );

      const label = associationLabelObjectTypeA.label;
      const category = associationLabelObjectTypeA.category;
      const hgAssociationLabel: domain.HGAssociationLabel = {
        canonicalId,
        associationLabelDefinitions,
        category,
        label,
        color: domain.colorForCanonicalAssociationLabelId({
          canonicalId,
          category,
          label,
        }),
      };

      acc.push(hgAssociationLabel);

      return acc;
    }, [] as domain.HGAssociationLabel[])
    .value();

  // console.log(
  //   "hgAssociations after fetch",
  //   _.values(hsAssociationLabelsByLabel),
  // );

  // console.log(
  //   "canonicalAssociationLabelIds",
  //   _.map(
  //     _.values(hsAssociationLabelsByLabel),
  //     (labelDef) => labelDef.canonicalId,
  //   ),
  // );

  return hsAssociationLabelsByLabel;
}

// TODO: implement paging
async function associationsListBatchRead(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  toObjectType: string;
  objectIds: string[];
}): Promise<HubSpotAPITypes.HubSpotAssociationsBatchReadAPIResponse> {
  const { authState, fromObjectType, toObjectType, objectIds } = params;

  console.log("HSAPI: making call associationsListBatchRead...", {
    fromObjectType,
    toObjectType,
    objectIds,
  });

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/batch/read`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        inputs: _.map(objectIds, (objectId) => {
          return { id: objectId };
        }),
      }),
    },
  );
  const body = await res.json();

  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotAssociationsBatchReadAPIResponse,
    body,
  );

  console.log("HSAPI: associationsListBatchRead finished", {
    response: decoded,
    fromObjectType,
    toObjectType,
    objectIds,
  });

  return decoded;
}

async function fetchAssociationsForObjectRefs(params: {
  authState: domain.AuthState;
  associationLabels: domain.HGAssociationLabel[];
  objectRefs: domain.HGObjectRef[];
}): Promise<domain.HGAssociation[]> {
  let results: domain.HGAssociation[] = [];

  const { authState, objectRefs, associationLabels } = params;

  // essentially what we are trying to do here is fetch for all the associationLabels
  // we care about in one direction. Think about it as fetches across the edges that
  // we have already collapsed into one edge (rather than two edges of different
  // directions, which is how HubSpot has modelled it).
  //
  // there’s a simple approach (for one-direction of each association label, fetch
  // the associations for a bunch of objectIds), but it results in a lot of API calls.
  //
  // there’s an API endpoint that takes a fromObjectType+toObjectType pair, and just
  // returns all the associations between those - this is more efficient, but requires
  // more bookeeping on our side - we are going to have to figure out which
  // HGAssociationLabel matches up to the returned association from the HubSpot-side,
  // or be very careful to only process the same-direction associations that we have
  // requested via fromObjectType+toObjectType

  console.log("fetchAssociationsForObjectRefs", params);

  const mentionedFromObjectTypes: string[] = _.chain(objectRefs)
    .map((ref) => ref.objectType)
    .uniq()
    .value();

  console.log("mentionedFromObjectTypes", mentionedFromObjectTypes);

  const objectTypePairsToFetch: [
    fromObjectType: string,
    toObjectType: string,
  ][] = _.chain(associationLabels)
    .flatMap((associationLabel) => {
      const labelDefinitions = _.filter(
        associationLabel.associationLabelDefinitions,
        (labelDefinition) => {
          return _.includes(
            mentionedFromObjectTypes,
            labelDefinition.fromObjectType,
          );
        },
      );

      if (!_.isEmpty(labelDefinitions)) {
        return _.map(labelDefinitions, (labelDefinition) => {
          const pair: [fromObjectType: string, toObjectType: string] = [
            labelDefinition.fromObjectType,
            labelDefinition.toObjectType,
          ];
          return pair;
        });
      }
    })
    .compact()
    .uniqBy(
      ([fromObjectType, toObjectType]) => `${fromObjectType}:${toObjectType}`,
    )
    .value();

  console.log("objectTypePairsToFetch", objectTypePairsToFetch);

  const associationLabelsByCanonicalId = _.reduce(
    associationLabels,
    (acc, associationLabel) => {
      acc[associationLabel.canonicalId] = associationLabel;
      return acc;
    },
    {} as Record<string, domain.HGAssociationLabel>,
  );

  console.log("associationLabelsByCanonicalId", associationLabelsByCanonicalId);

  const objectRefsByObjectType = _.groupBy(objectRefs, (ref) => ref.objectType);

  for (const [fromObjectType, toObjectType] of objectTypePairsToFetch) {
    console.log("processing fromObjectType/toObjectType pair", [
      fromObjectType,
      toObjectType,
    ]);
    const objectRefs = objectRefsByObjectType[fromObjectType];
    const objectIdBatches = _.chunk(
      _.map(objectRefs, (ref) => ref.objectId),
      100,
    );

    for (const objectIds of objectIdBatches) {
      console.log("making api call for batch of ids", [
        fromObjectType,
        toObjectType,
        objectIdBatches,
      ]);
      let apiResponse = await associationsListBatchRead({
        authState,
        fromObjectType,
        toObjectType,
        objectIds,
      });

      const hgAssociations = domain.hsAPIAssociationsToHGAssociation({
        fromObjectType,
        toObjectType,
        apiResults: apiResponse.results,
      });

      for (const hgAssociation of hgAssociations) {
        results.push(hgAssociation);
      }
    }
  }

  // const resultsWithoutDupes = domain.removeDuplicatePrimaryAssociations({
  //   hgAssociationLabels: associationLabels,
  //   hgAssociations: results,
  // });

  const resultsWithoutDupes = results;

  return resultsWithoutDupes;
}

export async function fetchAssociationsForLevel0ObjectRef(params: {
  authState: domain.AuthState;
  associationLabels: domain.HGAssociationLabel[];
  level0ObjectRef: domain.HGObjectRef;
}): Promise<{
  associations: domain.HGAssociation[];
  level1ObjectRefs: domain.HGObjectRef[];
}> {
  const { authState, level0ObjectRef, associationLabels } = params;

  // fetch all objects directly connected to this one through the valid associationLabels passed
  const associations = await fetchAssociationsForObjectRefs({
    authState,
    associationLabels,
    objectRefs: [level0ObjectRef],
  });

  const level1ObjectRefs = _.chain(associations)
    .flatMap((hgAssociation) => {
      return [hgAssociation.fromObjectRef, hgAssociation.toObjectRef];
    })
    .uniqBy(domain.canonicalIdForHGObjectRef)
    .filter((objectRef) => !domain.objectRefsEqual(objectRef, level0ObjectRef))
    .value();

  return { associations, level1ObjectRefs };
}

// TODO: Check this actually works - not tested yet
// export async function fetchAssociationsForLevel1ObjectRefs(params: {
//   authState: domain.AuthState;
//   associationLabels: domain.HGAssociationLabel[];
//   level0ObjectRef: domain.HGObjectRef;
//   level1ObjectRefs: domain.HGObjectRef[];
// }): Promise<{
//   associations: domain.HGAssociation[];
//   level2ObjectRefs: domain.HGObjectRef[];
// }> {
//   const { authState, associationLabels, level0ObjectRef, level1ObjectRefs } =
//     params;

//   console.log("fetchAssociationsForLevel1ObjectRefs params", params);

//   const associations = await fetchAssociationsForObjectRefs({
//     authState,
//     associationLabels,
//     objectRefs: level1ObjectRefs,
//   });

//   // don't want to include any associations to level0 object, as that association
//   // should have already been returned as as level0 association

//   const validAssociations = _.filter(associations, (association) => {
//     return !domain.objectRefsEqual(association.fromObjectRef, level0ObjectRef);
//   });

//   console.log("valid associations", validAssociations);

//   const level1ObjectsById = indexBy(
//     level1ObjectRefs,
//     domain.canonicalIdForHGObjectRef,
//   );

//   const level2ObjectRefs = _.chain(validAssociations)
//     .filter((association) => {
//       return (
//         !domain.objectRefsEqual(association.toObjectRef, level0ObjectRef) &&
//         !level1ObjectsById[
//           domain.canonicalIdForHGObjectRef(association.toObjectRef)
//         ]
//       );
//     })
//     .map((assoication) => {
//       return assoication.toObjectRef;
//     })
//     .value();

//   return { associations: validAssociations, level2ObjectRefs };
// }

// TODO: Check this actually works - not tested yet
// export async function fetchAssociationsForLevel2ObjectRefs(params: {
//   authState: domain.AuthState;
//   associationLabels: domain.HGAssociationLabel[];
//   level0ObjectRef: domain.HGObjectRef;
//   level1ObjectRefs: domain.HGObjectRef[];
//   level2ObjectRefs: domain.HGObjectRef[];
// }): Promise<{
//   associations: domain.HGAssociation[];
//   level3ObjectRefs: domain.HGObjectRef[];
// }> {
//   const {
//     authState,
//     associationLabels,
//     level0ObjectRef,
//     level1ObjectRefs,
//     level2ObjectRefs,
//   } = params;

//   const level1ObjectRefsById = indexBy(
//     level1ObjectRefs,
//     domain.canonicalIdForHGObjectRef,
//   );
//   const level2ObjectsRefsById = indexBy(
//     level2ObjectRefs,
//     domain.canonicalIdForHGObjectRef,
//   );

//   const associations = await fetchAssociationsForObjectRefs({
//     authState,
//     associationLabels,
//     objectRefs: level2ObjectRefs,
//   });

//   console.log("level2 associations", associations);

//   // don't want to return associations to level1 objects, as we should have already fetched
//   // these when fetching level1 associations
//   const validAssociations = _.filter(associations, (association) => {
//     const toCanonicalId = domain.canonicalIdForHGObjectRef(
//       association.toObjectRef,
//     );
//     return !level1ObjectRefsById[toCanonicalId];
//   });

//   const level3ObjectRefs = _.chain(validAssociations)
//     .filter((association) => {
//       const toRefId = domain.canonicalIdForHGObjectRef(association.toObjectRef);
//       return (
//         !domain.objectRefsEqual(level0ObjectRef, association.toObjectRef) &&
//         !level1ObjectRefsById[toRefId] &&
//         !level2ObjectsRefsById[toRefId]
//       );
//     })
//     .map((association) => {
//       return association.toObjectRef;
//     })
//     .value();

//   console.log("valid associations", validAssociations);

//   return { associations: validAssociations, level3ObjectRefs };
// }

async function hubspotObjectsBatchRead(params: {
  authState: domain.AuthState;
  objectType: string;
  ids: string[];
  properties: string[];
}): Promise<HubSpotAPITypes.HubSpotBatchObjectsReadAPIResponse> {
  const { authState, objectType, ids, properties } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/objects/${objectType}/batch/read`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        properties,
        inputs: _.map(ids, (id) => {
          return { id };
        }),
      }),
    },
  );

  const ExpectedBody = ts.type({
    results: ts.array(
      ts.type({
        id: ts.string,
        properties: ts.record(ts.string, ts.union([ts.string, ts.null])),
      }),
    ),
  });

  const body = await res.json();

  const decoded1 = parseOrThrow(ExpectedBody, body);

  const withObjectType = {
    results: _.map(decoded1.results, (result) => {
      return {
        ...result,
        objectType,
      };
    }),
  };

  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotBatchObjectsReadAPIResponse,
    withObjectType,
  );

  return decoded;
}

export async function fetchObjects(params: {
  authState: domain.AuthState;
  objectRefs: domain.HGObjectRef[];
  propertiesToFetchByObjectType: Record<string, string[] | undefined>;
}): Promise<domain.HGObject[]> {
  const { authState, objectRefs, propertiesToFetchByObjectType } = params;

  const allResults: HubSpotAPITypes.HubSpotAPIObject[] = [];

  const byObjectType = _.groupBy(objectRefs, (o) => o.objectType);

  for (const [objectType, objectInputs] of Object.entries(byObjectType)) {
    const batchedIds = _.chunk(
      _.map(objectInputs, (o) => o.objectId),
      100,
    );

    for (const ids of batchedIds) {
      const apiRes = await hubspotObjectsBatchRead({
        authState,
        objectType,
        ids,
        properties: propertiesToFetchByObjectType[objectType] || [],
      });

      for (const result of apiRes.results) {
        const o: HubSpotAPITypes.HubSpotAPIObject = {
          id: result.id,
          objectType,
          properties: result.properties,
        };
        allResults.push(o);
      }
    }
  }

  // convert to HGObjects
  const hgObjects = _.map(allResults, (hsObject) => {
    const { objectType, id: objectId } = hsObject;
    const hgObject: domain.HGObject = {
      canonicalId: domain.canonicalIdForHGObjectRef({
        objectType,
        objectId,
      }),
      objectId,
      objectType,
      properties: hsObject.properties,
      isFetched: true,
    };
    return hgObject;
  });

  return hgObjects;
}

export async function searchObjects(params: {
  authState: domain.AuthState;
  objectType: string;
  query: string;
  after?: string;
  propertiesToFetch: string[];
}): Promise<domain.HGObject[]> {
  const { authState, query, objectType, propertiesToFetch } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/objects/${objectType}/search`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        query,
        properties: propertiesToFetch,
      }),
    },
  );

  const extras =
    objectType === "contact"
      ? {
          jobtitle: "Senior Inbound Sales Professor",
          notes_last_updated: "2022-06-10",
        }
      : {};

  const body = await res.json();

  const decoded1 = parseOrThrow(HubSpotAPITypes.HubSpotSearchAPIResponse, body);

  const hgObjects: domain.HGObject[] = _.map(decoded1.results, (result) => {
    const hgObject: domain.HGObject = {
      objectType,
      canonicalId: domain.canonicalIdForHGObjectRef({
        objectType,
        objectId: result.id,
      }),
      isFetched: true,
      objectId: result.id,
      properties: result.properties,
    };
    return hgObject;
  });

  console.log("search result", hgObjects);

  return hgObjects;
}

async function createAssociationHubSpotAPI(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  associationCategory: domain.HGAssociationLabel["category"];
  associationTypeId: number;
}): Promise<void> {
  const {
    authState,
    fromObjectType,
    fromObjectId,
    toObjectType,
    toObjectId,
    associationCategory,
    associationTypeId,
  } = params;

  const body: {
    inputs: {
      from: { id: string };
      to: { id: string };
      types: {
        associationCategory: domain.HGAssociationLabel["category"];
        associationTypeId: number;
      }[];
    }[];
  } = {
    inputs: [
      {
        from: { id: fromObjectId },
        to: { id: toObjectId },
        types: [
          {
            associationCategory,
            associationTypeId,
          },
        ],
      },
    ],
  };

  console.log(
    "creating specific association between objects via Asssociations V4 batch API",
    {
      fromObjectType,
      toObjectType,
      body,
    },
  );

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/batch/create`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify(body),
    },
  );

  console.log("create specific association between objects res", res);

  return undefined;
}

export async function createAssociation(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  associationCategory: domain.HGAssociationLabel["category"];
  associationTypeId: number;
}): Promise<{ associations: domain.HGAssociation[] }> {
  const {
    authState,
    fromObjectId,
    fromObjectType,
    toObjectId,
    toObjectType,
    associationCategory,
    associationTypeId,
  } = params;

  console.log("hubspot-api createAssociation", params);

  // TODO: not sure if we have to fetch all the existing associations when adding in a new one? PUT & API docs suggests we are updating all the associations between the two objects?

  const existingAssociationsRes = await associationsListBatchRead({
    authState,
    fromObjectType,
    toObjectType,
    objectIds: [fromObjectId],
  });
  const existingAssociations = existingAssociationsRes.results;

  console.log("existing associations", existingAssociations);

  console.log("calling createAssociationViaV4BatchAPI ", {
    authState,
    fromObjectType,
    fromObjectId,
    toObjectType,
    toObjectId,
    associationTypeId,
  });
  await createAssociationHubSpotAPI({
    authState,
    fromObjectType,
    fromObjectId,
    toObjectType,
    toObjectId,
    associationTypeId,
    associationCategory,
  });

  const existingAssociationsAfterForObjectARes =
    await associationsListBatchRead({
      authState,
      fromObjectType,
      toObjectType,
      objectIds: [fromObjectId],
    });
  const existingAssociationsAfterForObjectA =
    existingAssociationsAfterForObjectARes.results;

  const existingAssociationsAfterForObjectBRes =
    await associationsListBatchRead({
      authState,
      fromObjectType: toObjectType,
      toObjectType: fromObjectType,
      objectIds: [toObjectId],
    });
  const existingAssociationsAfterForObjectB =
    existingAssociationsAfterForObjectBRes.results;

  const existingAssociationsAfter = [
    ...existingAssociationsAfterForObjectA,
    ...existingAssociationsAfterForObjectB,
  ];

  console.log("existing associationsAfter", existingAssociationsAfter);

  const nextHGAssociationsObjectA = domain.hsAPIAssociationsToHGAssociation({
    fromObjectType,
    toObjectType,
    apiResults: existingAssociationsAfterForObjectA,
  });

  const nextHGAssociationsObjectB = domain.hsAPIAssociationsToHGAssociation({
    fromObjectType: toObjectType,
    toObjectType: fromObjectType,
    apiResults: existingAssociationsAfterForObjectB,
  });

  const nextHGAssociations = _.uniqBy(
    [...nextHGAssociationsObjectA, ...nextHGAssociationsObjectB],
    (hgAssociation) => hgAssociation.canonicalId,
  );

  console.log("nextHGAssociations", nextHGAssociations);

  return {
    associations: nextHGAssociations,
  };
}

export async function removeAssociation(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  associationCategory: domain.HGAssociationLabel["category"];
  associationTypeId: number;
}): Promise<{ associations: domain.HGAssociation[] }> {
  const {
    authState,
    fromObjectId,
    fromObjectType,
    toObjectId,
    toObjectType,
    associationCategory,
    associationTypeId,
  } = params;

  const existingAssociationsRes = await associationsListBatchRead({
    authState,
    fromObjectType,
    toObjectType,
    objectIds: [fromObjectId],
  });
  const existingAssociations = existingAssociationsRes.results;
  console.log("existing associations", existingAssociations);

  const body: {
    inputs: {
      from: { id: string };
      to: { id: string };
      types: {
        associationCategory: domain.HGAssociationLabel["category"];
        associationTypeId: number;
      }[];
    }[];
  } = {
    inputs: [
      {
        from: { id: fromObjectId },
        to: { id: toObjectId },
        types: [
          {
            associationCategory,
            associationTypeId,
          },
        ],
      },
    ],
  };

  console.log("sending archive for specific association between objects", {
    fromObjectType,
    toObjectType,
    body,
  });

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/batch/labels/archive`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify(body),
    },
  );

  console.log("archiving specific association res", res);

  const existingAssociationsAfterRes = await associationsListBatchRead({
    authState,
    fromObjectType,
    toObjectType,
    objectIds: [fromObjectId],
  });
  const existingAssociationsAfter = existingAssociationsAfterRes.results;

  console.log("existing associations", existingAssociationsAfter);

  const hgAssociations = domain.hsAPIAssociationsToHGAssociation({
    fromObjectType,
    toObjectType,
    apiResults: existingAssociationsAfter,
  });

  return {
    associations: hgAssociations,
  };
}

export async function createAssociationLabel(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  toObjectType: string;
  label: string;
  name: string;
}): Promise<{
  category: string;
  typeId: number;
  label: string;
}> {
  const { authState, fromObjectType, toObjectType, label, name } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/labels`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        label,
        name,
      }),
    },
  );

  const ExpectedQuery = ts.type({
    results: ts.array(
      ts.type({
        category: ts.string,
        typeId: ts.number,
        label: ts.string,
      }),
    ),
  });

  const json = await res.json();

  const decoded = parseOrThrow(ExpectedQuery, json);

  const firstResult = _.first(decoded.results);

  if (!firstResult) {
    throw new Error("Failed to create association label");
  }

  return firstResult;
}

export async function fetchOwner(params: {
  authState: domain.AuthState;
  userId: string;
}): Promise<domain.HSUser> {
  const { authState, userId } = params;

  try {
    const res = await fetchRateLimited(
      `${baseUrl}/crm/v3/owners/${userId}?idProperty=userId`,
      {
        method: "GET",
        headers: {
          ...headersWithAuth(authState),
        },
      },
    );

    const body = await res.json();

    const decoded = parseOrThrow(
      HubSpotAPITypes.HubSpotOwnersGetAPIResponse,
      body,
    );

    return decoded;
  } catch (e) {
    return {
      id: userId,
    };
  }
}

export async function fetchSchemas(params: {
  authState: domain.AuthState;
}): Promise<domain.HGObjectSchema[]> {
  const { authState } = params;

  const res = await fetchRateLimited(`${baseUrl}/crm/v3/schemas`, {
    method: "GET",
    headers: {
      ...headersWithAuth(authState),
    },
  });

  const body = await res.json();

  const decoded = parseOrThrow(HubSpotAPITypes.HubSpotGetSchemasResponse, body);

  const schemas = decoded.results.map((schema) => {
    const objectTypeId = schema.objectTypeId;
    return {
      ...schema,
      canonicalId: objectTypeId,
      properties: schema.properties.map((property) => {
        return parseOrThrow(domain.HSProperty, {
          ...property,
          objectType: objectTypeId,
          canonicalId: domain.canonicalIdForHSProperty({
            objectType: objectTypeId,
            name: property.name,
          }),
        });
      }),
    };
  });

  return schemas;
}

async function fetchCurrentScopes(params: {
  authState: domain.AuthState;
}): Promise<string[]> {
  const { authState } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/oauth/v1/access-tokens/$HUBSPOT_ACCESS_TOKEN`,
    {
      method: "GET",
      headers: {
        ...headersWithAuth(authState),
      },
    },
  );

  const body = await res.json();

  const ExpectedBody = ts.type({
    scopes: ts.array(ts.string),
  });

  const decoded = parseOrThrow(ExpectedBody, body);

  return decoded.scopes;
}

export async function discoverSupportedSchemas(params: {
  authState: domain.AuthState;
}): Promise<{
  schemas: domain.HGObjectSchema[];
  customObjectsSupported: boolean;
}> {
  const { authState } = params;

  // check scopes first to see if custom objects are supported
  const currentScopes = await fetchCurrentScopes({ authState });

  const customObjectsSupported = domain.customObjectsSupported(currentScopes);

  if (customObjectsSupported) {
    const schemas = await fetchSchemas({ authState });
    return { schemas, customObjectsSupported: true };
  } else {
    return {
      customObjectsSupported,
      schemas: [],
    };
  }
}
