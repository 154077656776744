import { CardShape, TDShape } from "@orgcharthub/tldraw-tldraw";
import * as ts from "io-ts";
import _ from "lodash";
import * as theme from "../theme";
import { indexBy } from "../utils";
import firebase from "firebase/app";
import { DateTime } from "luxon";
import { HubSpotAssociationsBatchReadAPIResponse } from "../api/hubspot-api-types";

export interface AuthState {
  accessToken: string;
}

export const GRID_SIZE = 20;

const SCOPES_REQUIRED_FOR_CUSTOM_OBJECT_SUPPORT = [
  "crm.objects.custom.read",
  "crm.objects.custom.write",
  "crm.schemas.custom.read",
] as const;

export const DEFAULT_DISPLAY_PROPERTIES: Readonly<HGDisplayProperty[]> = [
  // contacts
  {
    name: "firstname",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "lastname",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "jobtitle",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "notes_last_updated",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "company",
    objectType: "contact",
    showOnCard: false,
  },
  {
    name: "email",
    objectType: "contact",
    showOnCard: false,
  },

  // companies
  {
    name: "name",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "hubspot_owner_id",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "hs_ideal_customer_profile",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "num_associated_deals",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "hs_is_target_account",
    objectType: "company",
    showOnCard: false,
  },
  {
    name: "annualrevenue",
    objectType: "company",
    showOnCard: false,
  },
  {
    name: "description",
    objectType: "company",
    showOnCard: false,
  },

  // deals
  {
    name: "dealname",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "amount",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "closedate",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "hubspot_owner_id",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "description",
    objectType: "deal",
    showOnCard: false,
  },
  {
    name: "dealstage",
    objectType: "deal",
    showOnCard: false,
  },
] as const;

export function colorForCanonicalAssociationLabelId(
  associationLabel: Pick<
    HGAssociationLabel,
    "category" | "label" | "canonicalId"
  >,
): RelationshipLineColorConfigColor {
  console.log("colorForCanonicalAssociationLabelId", associationLabel);
  if (
    associationLabel.category === "HUBSPOT_DEFINED" &&
    associationLabel.label === "Primary"
  ) {
    return "primarySlate";
  } else if (
    associationLabel.category === "HUBSPOT_DEFINED" &&
    associationLabel.label === null
  ) {
    return "unlabelledSlate";
  } else {
    return "blue";
  }

  // const idMap: {
  //   [id: string]: string | undefined;
  // } = {
  //   // "associationLabel:company/contact:Primary:HUBSPOT_DEFINED": "slate",
  //   // "associationLabel:company/contact:<unlabled>:HUBSPOT_DEFINED": "slate",
  //   // "associationLabel:company/contact:Decision Maker:USER_DEFINED": "orange",
  //   // "associationLabel:contact/deal:Contracting Partner:USER_DEFINED": "purple",
  //   // "associationLabel:contact/deal:Deal Account Manager:USER_DEFINED": "pink",
  //   // "associationLabel:contact/deal:<unlabled>:HUBSPOT_DEFINED": "violet",
  //   // "associationLabel:company/deal:Primary:HUBSPOT_DEFINED": "slate",
  //   // "associationLabel:company/deal:<unlabled>:HUBSPOT_DEFINED": "slate",
  //   // "associationLabel:company/deal:Deal Partner Organisation:USER_DEFINED":
  //   //   "cyan",
  //   // "associationLabel:company/company:Parent company to child company:HUBSPOT_DEFINED":
  //   //   "lime",
  //   // // custom object
  //   // "associationLabel:2-4732493/contact:Interested In:USER_DEFINED": "orange",
  //   // "associationLabel:2-4732493/contact:<unlabled>:USER_DEFINED": "slate",
  //   // "associationLabel:2-4732493/deal:<unlabled>:USER_DEFINED": "slate",
  //   // "associationLabel:2-4732493/deal:Deal Property Development:USER_DEFINED":
  //   //   "purple",
  //   // // buying-role-associations
  //   // "associationLabel:contact/deal:Budget Holder:USER_DEFINED": "orange",
  //   // "associationLabel:contact/deal:Blocker:USER_DEFINED": "red",
  //   // "associationLabel:contact/deal:Champion:USER_DEFINED": "green",
  //   // "associationLabel:contact/deal:Decision Maker:USER_DEFINED": "violet",
  // };

  // let color = idMap[associationLabel.canonicalId];

  // if (!color) {
  //   color = _.shuffle(
  //     Object.values(RELATIONSHIP_LINE_COLOR_CONFIGS).map(
  //       (config) => config.name,
  //     ),
  //   )[0];
  // }

  // return color;
}

const HGCanvasNodeDebugInfo = ts.partial({
  debug: ts.type({
    color: ts.string,
    label: ts.string,
  }),
});

export const HGCanvasObjectCollectionNode = ts.intersection([
  ts.type({
    type: ts.literal("objectCollection"),
    id: ts.string,
    objectType: ts.string,
    objectIds: ts.array(ts.string),
  }),
  ts.partial({
    parentId: ts.string,
  }),
  HGCanvasNodeDebugInfo,
]);
export type HGCanvasObjectCollectionNode = ts.TypeOf<
  typeof HGCanvasObjectCollectionNode
>;

export const HGCanvasObjectNode = ts.intersection([
  ts.type({
    type: ts.literal("object"),
    objectType: ts.string,
    id: ts.string,
  }),
  ts.partial({
    parentId: ts.string,
  }),
  HGCanvasNodeDebugInfo,
]);
export type HGCanvasObjectNode = ts.TypeOf<typeof HGCanvasObjectNode>;

export const HGCanvasObjectLoaderNode = ts.intersection([
  ts.type({
    type: ts.literal("loaderNode"),
    id: ts.string,
    objectType: ts.string,
    objectId: ts.string,
  }),
  HGCanvasNodeDebugInfo,
]);
export type HGCanvasObjectLoaderNode = ts.TypeOf<
  typeof HGCanvasObjectLoaderNode
>;

export const HGCanvasCompanyContainerNode = ts.intersection([
  ts.type({
    type: ts.literal("companyContainer"),
    id: ts.string,
  }),
  HGCanvasNodeDebugInfo,
]);
export type HGCanvasCompanyContainerNode = ts.TypeOf<
  typeof HGCanvasCompanyContainerNode
>;

export const HGCanvasNode = ts.union([
  HGCanvasCompanyContainerNode,
  HGCanvasObjectCollectionNode,
  HGCanvasObjectNode,
  HGCanvasObjectLoaderNode,
]);
export type HGCanvasNode = ts.TypeOf<typeof HGCanvasNode>;

export const HGCanvasEdge = ts.type({
  id: ts.string,
  source: ts.string,
  target: ts.string,
  // TODO: sepearate these into different edge types? can't have `isPrimary` and `isLoader` together
  isPrimary: ts.boolean,
  isLoader: ts.boolean,
  isCollection: ts.boolean,
  isUnlabelled: ts.boolean,
});
export type HGCanvasEdge = ts.TypeOf<typeof HGCanvasEdge>;

export const HGObjectRef = ts.type({
  objectType: ts.string,
  objectId: ts.string,
});
export type HGObjectRef = ts.TypeOf<typeof HGObjectRef>;

export function canonicalIdForHGObjectRef(objectRef: HGObjectRef): string {
  return `${objectRef.objectType}:${objectRef.objectId}`;
}

export function canonicalIdForHSPropertyGroup(params: {
  objectType: string;
  name: string;
}): string {
  const { name, objectType } = params;
  return `PropertyGroup:${objectType}:${name}`;
}

export function canonicalIdForHSProperty(params: {
  objectType: string;
  name: string;
}): string {
  const { name, objectType } = params;
  return `Property:${objectType}:${name}`;
}

export const HGObject = ts.intersection([
  ts.type({
    objectType: ts.string,
    objectId: ts.string,
    canonicalId: ts.string,

    /** local custom properties that we'll keep track of **/
    isFetched: ts.boolean,
  }),
  ts.partial({
    properties: ts.record(ts.string, ts.union([ts.string, ts.null])),
  }),
]);
export type HGObject = ts.TypeOf<typeof HGObject>;

export const HGAssociationLabel = ts.type({
  canonicalId: ts.string,
  label: ts.union([ts.string, ts.null]),
  category: ts.union([
    ts.literal("HUBSPOT_DEFINED"),
    ts.literal("USER_DEFINED"),
    ts.literal("INTEGRATOR_DEFINED"),
  ]),
  associationLabelDefinitions: ts.array(
    ts.type({
      fromObjectType: ts.string,
      toObjectType: ts.string,
      typeId: ts.number,
    }),
  ),
  color: ts.string,
});
export type HGAssociationLabel = ts.TypeOf<typeof HGAssociationLabel>;

export function canonicalIdForAssociationLabel(params: {
  objectTypeA: string;
  objectTypeB: string;
  category: string;
  label: string | null;
}): string {
  const { objectTypeA, objectTypeB, category, label } = params;
  const objectTypePart = [objectTypeA, objectTypeB].sort().join("/");
  return [
    "associationLabel",
    objectTypePart,
    label || "<unlabled>",
    category,
  ].join(":");
}

export const HGAssociation = ts.type({
  canonicalId: ts.string,
  fromObjectRef: HGObjectRef,
  toObjectRef: HGObjectRef,
  associationLabelCanonicalId: ts.string,
});
export type HGAssociation = ts.TypeOf<typeof HGAssociation>;

const HSPropertyBase = ts.type({
  /* objectType and canonicalId are not actually present, but we need identity across different object types */
  objectType: ts.string,
  canonicalId: ts.string,

  groupName: ts.string,
  hidden: ts.boolean,
  name: ts.string,
  displayOrder: ts.number,
  label: ts.string,
  description: ts.string,
});

const HSPropertyBool = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("bool"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("booleancheckbox"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal(""),
    // ]),
  }),
]);
export const HSPropertyEnumeration = ts.intersection([
  HSPropertyBase,
  ts.intersection([
    ts.type({
      type: ts.literal("enumeration"),
      fieldType: ts.string,
      // fieldType: ts.union([
      //   ts.literal("booleancheckbox"),
      //   ts.literal("checkbox"),
      //   ts.literal("radio"),
      //   ts.literal("select"),
      //   ts.literal("calculation_equation"),
      //   ts.literal("calculation_read_time"),
      //   ts.literal("calculation_rollup"),
      //   ts.literal("calculation_score"),
      //   ts.literal("number"),
      // ]),
      options: ts.array(
        ts.intersection([
          ts.type({
            label: ts.string,
            value: ts.string,
            displayOrder: ts.number,
          }),
          ts.partial({
            description: ts.string,
          }),
        ]),
      ),
    }),
    ts.partial({
      referencedObjectType: ts.string,
    }),
  ]),
]);

export type HSPropertyEnumeration = ts.TypeOf<typeof HSPropertyEnumeration>;
const HSPropertyDate = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("date"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("date"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal("number"),
    // ]),
  }),
]);
const HSPropertyDateTime = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("datetime"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("date"),
    //   ts.literal("text"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal("number"),
    // ]),
  }),
]);
const HSPropertyNumber = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("number"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("number"),
    //   ts.literal("text"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    // ]),
  }),
]);
const HSPropertyPhoneNumber = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("phone_number"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("phonenumber"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    // ]),
  }),
]);
const HSPropertyString = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("string"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("file"),
    //   ts.literal("text"),
    //   ts.literal("textarea"),
    //   ts.literal("phonenumber"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal("number"),
    // ]),
  }),
]);

export const HSProperty = ts.union([
  HSPropertyBool,
  HSPropertyEnumeration,
  HSPropertyDate,
  HSPropertyDateTime,
  HSPropertyNumber,
  HSPropertyPhoneNumber,
  HSPropertyString,
]);
export type HSProperty = ts.TypeOf<typeof HSProperty>;

export const HSPropertyGroup = ts.type({
  /* objectType and canonicalId are not actually present, but we need identity across different object types */
  objectType: ts.string,
  canonicalId: ts.string,

  name: ts.string,
  displayOrder: ts.number,
  label: ts.string,
});
export type HSPropertyGroup = ts.TypeOf<typeof HSPropertyGroup>;

export const HSUser = ts.intersection([
  ts.type({
    id: ts.string,
  }),
  ts.partial({
    firstName: ts.string,
    lastName: ts.string,
    email: ts.string,
  }),
]);
export type HSUser = ts.TypeOf<typeof HSUser>;

export function canonicalIdForAssociation(params: {
  objectRefA: HGObjectRef;
  objectRefB: HGObjectRef;
  associationLabelCanonicalId: string;
}): string {
  const {
    objectRefA,
    objectRefB,
    associationLabelCanonicalId: associationCanonicalId,
  } = params;

  const objectRefParts = [objectRefA, objectRefB]
    .map((objectRef) => [objectRef.objectType, objectRef.objectId].join("/"))
    .sort()
    .join(":");

  return ["association", `(${associationCanonicalId})`, objectRefParts].join(
    ":",
  );
}

export function hgAssociationLabelMentionedObjectTypes(
  hgAssociationLabel: HGAssociationLabel,
): string[] {
  const { fromObjectType, toObjectType } =
    hgAssociationLabel.associationLabelDefinitions[0];
  return [fromObjectType, toObjectType];
}

export function objectTypesListsEqual(
  objectTypesListA: string[],
  objectTypesListB: string[],
): boolean {
  return (
    objectTypesListA.sort().join(":") === objectTypesListB.sort().join(":")
  );
}

export function isPrimaryAssociation(
  associationLabel: HGAssociationLabel,
): boolean {
  // TODO: not sure this is actually an accurate test for primary association, not really thought through
  return (
    associationLabel.category === "HUBSPOT_DEFINED" &&
    (associationLabel.label === "Primary" ||
      associationLabel.label === "Parent to Child" ||
      associationLabel.label === null)
  );
}

export function isStrictPrimaryAssociation(
  associationLabel: HGAssociationLabel,
): boolean {
  return (
    associationLabel.label === "Primary" &&
    associationLabel.category === "HUBSPOT_DEFINED"
  );
}

export function isParentChildAssociation(
  associationLabel: HGAssociationLabel,
): boolean {
  return (
    associationLabel.category === "HUBSPOT_DEFINED" &&
    associationLabel.label === "Parent to Child"
  );
}

const isPrimaryAssociationLabelBetweenObjectTypes = (
  objectTypes: [objectTypeA: string, objectTypeB: string],
  associationLabel: HGAssociationLabel,
): boolean => {
  const associationLabelDefinition =
    associationLabel.associationLabelDefinitions[0];

  const mentionedObjectTypes = new Set([
    associationLabelDefinition.fromObjectType,
    associationLabelDefinition.toObjectType,
  ]);

  console.log("mentionedObjectTypes", mentionedObjectTypes);
  console.log("objectTypes", objectTypes);

  return (
    objectTypes.every((objectType) => mentionedObjectTypes.has(objectType)) &&
    associationLabel.category === "HUBSPOT_DEFINED" &&
    associationLabel.label === "Primary"
  );
};

const isPrimaryAssociationLabelBetweenContactAndCompany = (
  associationLabel: HGAssociationLabel,
): boolean => {
  return isPrimaryAssociationLabelBetweenObjectTypes(
    ["company", "contact"],
    associationLabel,
  );
};

const isPrimaryAssociationLabelBetweenCompanyAndDeal = (
  associationLabel: HGAssociationLabel,
): boolean => {
  console.log("associatioNLabel", associationLabel);
  return isPrimaryAssociationLabelBetweenObjectTypes(
    ["company", "deal"],
    associationLabel,
  );
};

export function isPrimaryCompanyAssociationLabel(
  hgAssociationLabel: HGAssociationLabel,
): boolean {
  return (
    isPrimaryAssociationLabelBetweenCompanyAndDeal(hgAssociationLabel) ||
    isPrimaryAssociationLabelBetweenContactAndCompany(hgAssociationLabel)
  );
}

export function isUnlabelledAssociationLabel(
  hgAssociationLabel: HGAssociationLabel,
): boolean {
  return hgAssociationLabel.label === null;
}

export type HGObjectRefPair = [
  objectRefA: HGObjectRef,
  objectRefB: HGObjectRef,
];

export function objectRefPairId(objectRefPair: HGObjectRefPair): string {
  const [refA, refB] = objectRefPair;
  return [canonicalIdForHGObjectRef(refA), canonicalIdForHGObjectRef(refB)]
    .sort()
    .join("/");
}

export function objectRefsEqual(a: HGObjectRef, b: HGObjectRef): boolean {
  return a.objectId === b.objectId && a.objectType === b.objectType;
}

/**
 * Makes it easy to "pick" an HGObjectRef from something that already has
 * both the `objectType` and the `objectId` fields (for example, an HGObject)
 */
export function makeObjectRef(obj: {
  objectType: string;
  objectId: string;
}): HGObjectRef {
  return {
    objectId: obj.objectId,
    objectType: obj.objectType,
  };
}

export function objectDisplayName(hgObject: HGObject): string {
  switch (hgObject.objectType) {
    case "company": {
      const name = hgObject.properties?.name;
      const domain = hgObject.properties?.domain;
      return name || domain || hgObject.objectId;
    }

    case "contact": {
      const firstName = hgObject.properties?.first_name;
      const lastName = hgObject.properties?.last_name;
      const email = hgObject.properties?.email;

      if (firstName || lastName) {
        return _.filter([firstName, lastName], _.identity).join(" ");
      } else if (email) {
        return email;
      } else {
        return hgObject.objectId;
      }
    }

    case "deal": {
      const name = hgObject.properties?.dealname;
      return name || hgObject.objectId;
    }

    case "2-4732493": {
      const name = hgObject.properties?.development_name;
      return name || hgObject.objectId;
    }

    case "2-7769356": {
      const name = hgObject.properties?.name;
      return name || hgObject.objectId;
    }

    default: {
      return hgObject.objectId;
    }
  }
}

export type RelationshipLineColorConfig = {
  name: string;
  labelBackgroundColor: string;
  labelBackgroundColorClass: string;
  labelTextColor: string;
  labelTextColorClass: string;
  lineColor: string;
};

export type RelationshipLineColorConfigColor =
  | "draft"
  | "slate"
  | "primarySlate"
  | "unlabelledSlate"
  | "red"
  | "green"
  | "orange"
  | "purple"
  | "violet"
  | "pink"
  | "yellow"
  | "blue"
  | "cyan"
  | "lime";
export const RELATIONSHIP_LINE_COLOR_CONFIGS: {
  [configName in RelationshipLineColorConfigColor]: RelationshipLineColorConfig;
} = {
  draft: {
    name: "draft",
    labelBackgroundColor: theme.colors.cyan["500"],
    labelBackgroundColorClass: "bg-cyan-500",
    labelTextColor: theme.colors.cyan["500"],
    labelTextColorClass: "text-cyan-100",
    lineColor: theme.colors.cyan["500"],
  },
  primarySlate: {
    name: "primarySlate",
    labelBackgroundColor: theme.colors.slate["400"],
    labelBackgroundColorClass: "bg-slate-400",
    labelTextColor: theme.colors.slate["800"],
    labelTextColorClass: "text-slate-800",
    lineColor: theme.colors.slate["400"],
  },
  unlabelledSlate: {
    name: "unlabelledSlate",
    labelBackgroundColor: theme.colors.slate["300"],
    labelBackgroundColorClass: "bg-slate-300",
    labelTextColor: theme.colors.slate["600"],
    labelTextColorClass: "text-slate-600",
    lineColor: theme.colors.slate["300"],
  },
  slate: {
    name: "slate",
    labelBackgroundColor: theme.colors.slate["500"],
    labelBackgroundColorClass: "bg-slate-500",
    labelTextColor: theme.colors.slate["500"],
    labelTextColorClass: "text-slate-100",
    lineColor: theme.colors.slate["500"],
  },
  red: {
    name: "red",
    labelBackgroundColor: theme.colors.red["500"],
    labelBackgroundColorClass: "bg-red-500",
    labelTextColor: theme.colors.red["100"],
    labelTextColorClass: "text-red-100",
    lineColor: theme.colors.red["500"],
  },
  green: {
    name: "green",
    labelBackgroundColor: theme.colors.green["500"],
    labelBackgroundColorClass: "bg-green-500",
    labelTextColor: theme.colors.green["100"],
    labelTextColorClass: "text-green-100",
    lineColor: theme.colors.green["500"],
  },
  orange: {
    name: "orange",
    labelBackgroundColor: theme.colors.orange["500"],
    labelBackgroundColorClass: "bg-orange-500",
    labelTextColor: theme.colors.orange["100"],
    labelTextColorClass: "text-orange-100",
    lineColor: theme.colors.orange["500"],
  },
  purple: {
    name: "purple",
    labelBackgroundColor: theme.colors.purple["500"],
    labelBackgroundColorClass: "bg-purple-500",
    labelTextColor: theme.colors.purple["100"],
    labelTextColorClass: "text-purple-100",
    lineColor: theme.colors.purple["500"],
  },
  pink: {
    name: "pink",
    labelBackgroundColor: theme.colors.pink["500"],
    labelBackgroundColorClass: "bg-pink-500",
    labelTextColor: theme.colors.pink["100"],
    labelTextColorClass: "text-pink-100",
    lineColor: theme.colors.pink["500"],
  },
  violet: {
    name: "violet",
    labelBackgroundColor: theme.colors.violet["500"],
    labelBackgroundColorClass: "bg-violet-500",
    labelTextColor: theme.colors.violet["100"],
    labelTextColorClass: "text-violet-100",
    lineColor: theme.colors.violet["500"],
  },
  yellow: {
    name: "yellow",
    labelBackgroundColor: theme.colors.yellow["500"],
    labelBackgroundColorClass: "bg-yellow-500",
    labelTextColor: theme.colors.yellow["100"],
    labelTextColorClass: "text-yellow-100",
    lineColor: theme.colors.yellow["500"],
  },
  blue: {
    name: "blue",
    labelBackgroundColor: theme.colors.blue["500"],
    labelBackgroundColorClass: "bg-blue-500",
    labelTextColor: theme.colors.blue["100"],
    labelTextColorClass: "text-blue-100",
    lineColor: theme.colors.blue["500"],
  },
  cyan: {
    name: "cyan",
    labelBackgroundColor: theme.colors.cyan["500"],
    labelBackgroundColorClass: "bg-cyan-500",
    labelTextColor: theme.colors.cyan["100"],
    labelTextColorClass: "text-cyan-100",
    lineColor: theme.colors.cyan["500"],
  },
  lime: {
    name: "lime",
    labelBackgroundColor: theme.colors.lime["500"],
    labelBackgroundColorClass: "bg-lime-500",
    labelTextColor: theme.colors.lime["100"],
    labelTextColorClass: "text-lime-100",
    lineColor: theme.colors.lime["500"],
  },
} as const;

export function hubspotURLForObjectRef(params: {
  portalId: string;
  portalHSDomain: string;
  objectRef: HGObjectRef;
}): string {
  const { portalId, portalHSDomain, objectRef } = params;
  return `https://${portalHSDomain}/contacts/${portalId}/${objectRef.objectType}/${objectRef.objectId}`;
}

export function outsideHubSpotURL(currentURL: string): string {
  const url = new URL(currentURL);
  url.searchParams.set("outside-hubspot", "true");
  return url.toString();
}

export function isOutsideHubSpotURL(currentURL: string): boolean {
  const url = new URL(currentURL);
  return url.searchParams.get("outside-hubspot") === "true";
}

export function removeDuplicatePrimaryAssociations(params: {
  hgAssociationLabels: HGAssociationLabel[];
  hgAssociations: HGAssociation[];
}): HGAssociation[] {
  const { hgAssociationLabels, hgAssociations } = params;

  const associationLabelsByCanonicalId = indexBy(
    hgAssociationLabels,
    (labelDef) => labelDef.canonicalId,
  );

  // filter out associations which are doubled-up because there is also a primary association
  const objectRefPairsWithLabelledAssociation = _.reduce(
    hgAssociations,
    (acc, hgAssociation) => {
      const associationLabel =
        associationLabelsByCanonicalId[
          hgAssociation.associationLabelCanonicalId
        ];

      if (!isUnlabelledAssociationLabel(associationLabel)) {
        const objectRefPair: HGObjectRefPair = [
          hgAssociation.fromObjectRef,
          hgAssociation.toObjectRef,
        ];
        const _objectRefPairId = objectRefPairId(objectRefPair);
        acc[_objectRefPairId] = objectRefPair;
      }

      return acc;
    },
    {} as Record<string, HGObjectRefPair>,
  );

  let resultsWithoutDupes = _.filter(hgAssociations, (hgAssociation) => {
    const associationLabel =
      associationLabelsByCanonicalId[hgAssociation.associationLabelCanonicalId];
    const _objectRefPairId = objectRefPairId([
      hgAssociation.fromObjectRef,
      hgAssociation.toObjectRef,
    ]);

    const hasLabelledAssociationBetweenPair =
      !!objectRefPairsWithLabelledAssociation[_objectRefPairId];
    if (
      hasLabelledAssociationBetweenPair &&
      isUnlabelledAssociationLabel(associationLabel)
    ) {
      return false;
    }

    return true;
  });

  return resultsWithoutDupes;
}

export function isCardShape(shape: TDShape): shape is CardShape {
  return shape.type === "card";
}

export const DateTimeFromFirestoreTimestamp = new ts.Type<
  string,
  firebase.firestore.Timestamp,
  unknown
>(
  "DateTimeFromFirestoreTimestamp",
  // todo format validation?
  (input: unknown): input is string => typeof input === "string",
  (input, context) => {
    // can't `instanceof` against fb.firestore.Timestamp because it will be
    // considered false between different sources of data: `firebase-admin` vs
    // `firestore` modules
    const toDateFn = _.get(input, "toDate") as unknown;
    if (typeof toDateFn === "function") {
      const jsDate = toDateFn.call(input);
      return ts.success(DateTime.fromJSDate(jsDate).toISO());
    } else {
      return ts.failure(input, context);
    }
  },
  (isoDateTime: string): firebase.firestore.Timestamp => {
    const jsDate = DateTime.fromISO(isoDateTime).toJSDate();
    return firebase.firestore.Timestamp.fromDate(jsDate);
  },
);

export const RelationshipMap = ts.type({
  id: ts.string,
  portalId: ts.string,
  name: ts.string,
  archived: ts.union([ts.boolean, ts.undefined]),
  description: ts.union([ts.string, ts.undefined]),
  isTemplate: ts.union([ts.boolean, ts.undefined]),
  createdAt: DateTimeFromFirestoreTimestamp,
  updatedAt: DateTimeFromFirestoreTimestamp,
});
export type RelationshipMap = ts.TypeOf<typeof RelationshipMap>;

export const RelationshipMapRecord = ts.type({
  id: ts.string,
  portalId: ts.string,
  document: ts.unknown,
  metadata: ts.type({
    mapVersion: ts.number,
  }),
});
export type RelationshipMapRecord = ts.TypeOf<typeof RelationshipMapRecord>;

export function userDisplayName(user: HSUser): string | undefined {
  if (user.firstName || user.lastName) {
    const parts = [user.firstName, user.lastName]
      .filter((part) => !_.isEmpty(part))
      .join(" ");
    return parts;
  } else {
    return undefined;
  }
}

export const HGDisplayProperty = ts.type({
  name: ts.string,
  objectType: ts.string,
  showOnCard: ts.boolean,
});
export type HGDisplayProperty = ts.TypeOf<typeof HGDisplayProperty>;

export const HGPortal = ts.intersection([
  ts.type({
    "hub-domain": ts.string,
  }),
  ts.partial({
    "hg-display-properties": ts.array(HGDisplayProperty),
  }),
]);
export type HGPortal = ts.TypeOf<typeof HGPortal>;

export const HGObjectSchema = ts.type({
  canonicalId: ts.string,
  id: ts.string,
  objectTypeId: ts.string,
  properties: ts.array(HSProperty),
  associations: ts.array(
    ts.type({
      id: ts.string,
      fromObjectTypeId: ts.string,
      toObjectTypeId: ts.string,
      name: ts.string,
    }),
  ),
  labels: ts.type({
    singular: ts.string,
    plural: ts.string,
  }),
  primaryDisplayProperty: ts.string,
  secondaryDisplayProperties: ts.array(ts.string),
  searchableProperties: ts.array(ts.string),
  requiredProperties: ts.array(ts.string),
  name: ts.string,
});
export type HGObjectSchema = ts.TypeOf<typeof HGObjectSchema>;

export function calculateSupportedObjectTypes(
  hgObjectSchemas: HGObjectSchema[],
): string[] {
  const alwaysSupported = ["company", "deal", "contact"];
  return [
    ...alwaysSupported,
    ...hgObjectSchemas.map((hgObjectSchema) => hgObjectSchema.objectTypeId),
  ];
}

function calculatePropertiesToFetchForObjectType(params: {
  objectType: string;
  displayProperties: HGDisplayProperty[];
  hgObjectSchemas: HGObjectSchema[];
}): string[] {
  const { objectType, displayProperties, hgObjectSchemas } = params;

  const displayPropertyNamesToFetch = displayProperties
    .filter((displayProperty) => {
      return displayProperty.objectType === objectType;
    })
    .map((displayProperty) => {
      return displayProperty.name;
    });

  // also gather up anything we expect we need for custom objects
  const customObjectPropertyNamesToFetch = hgObjectSchemas
    .filter((hgObjectSchema) => {
      return hgObjectSchema.objectTypeId === objectType;
    })
    .flatMap((hgObjectSchema) => {
      return [
        hgObjectSchema.primaryDisplayProperty,
        ...hgObjectSchema.secondaryDisplayProperties,
        ...hgObjectSchema.requiredProperties,
        ...hgObjectSchema.searchableProperties,
      ];
    });

  // include the default-returned properties for default HubSpot objects
  let hubspotDefaultProperties: string[] = [];

  switch (objectType) {
    case "contact": {
      hubspotDefaultProperties = ["firstname", "lastname", "email"];
      break;
    }
    case "company": {
      hubspotDefaultProperties = ["name", "domain"];
      break;
    }
    case "deal": {
      hubspotDefaultProperties = [
        "dealname",
        "amount",
        "closedate",
        "pipeline",
        "dealstage",
      ];
      break;
    }
  }

  return _.uniq([
    ...displayPropertyNamesToFetch,
    ...customObjectPropertyNamesToFetch,
    ...hubspotDefaultProperties,
  ]);
}

function calculatePropertiesToFetch(params: {
  displayProperties: HGDisplayProperty[];
  hgObjectSchemas: HGObjectSchema[];
  objectTypes: string[];
}): Record<string, string[]> {
  const { displayProperties, hgObjectSchemas, objectTypes } = params;

  const propertiesByObjectType: Record<string, string[]> = {};

  for (const objectType of objectTypes) {
    propertiesByObjectType[objectType] =
      calculatePropertiesToFetchForObjectType({
        hgObjectSchemas,
        displayProperties,
        objectType,
      });
  }

  return propertiesByObjectType;
}
export function calculatePropertiesToFetchForObjectRefs(params: {
  displayProperties: HGDisplayProperty[];
  hgObjectSchemas: HGObjectSchema[];
  objectRefs: HGObjectRef[];
}): Record<string, string[]> {
  const { objectRefs, displayProperties, hgObjectSchemas } = params;
  const objectTypes = _.uniq(
    _.map(objectRefs, (objectRef) => objectRef.objectType),
  );
  return calculatePropertiesToFetch({
    displayProperties,
    hgObjectSchemas,
    objectTypes,
  });
}

export function calculatePropertiesToFetchForAllObjectTypes(params: {
  displayProperties: HGDisplayProperty[];
  hgObjectSchemas: HGObjectSchema[];
}): Record<string, string[]> {
  const { displayProperties, hgObjectSchemas } = params;

  const customObjectTypes = hgObjectSchemas.map(
    (hgObjectSchema) => hgObjectSchema.objectTypeId,
  );

  return calculatePropertiesToFetch({
    displayProperties,
    hgObjectSchemas,
    objectTypes: ["contact", "company", "deal", ...customObjectTypes],
  });
}

export function customObjectsSupported(currentScopes: string[]): boolean {
  return SCOPES_REQUIRED_FOR_CUSTOM_OBJECT_SUPPORT.every((requiredScope) => {
    return currentScopes.includes(requiredScope);
  });
}

export function toShortDate(isoDateTime: string): string {
  const dt = DateTime.fromISO(isoDateTime);
  const formattedValue = dt.toLocaleString(DateTime.DATE_SHORT);
  return formattedValue;
}

export function toShortDateTime(isoDateTime: string): string {
  const dt = DateTime.fromISO(isoDateTime);
  const formattedValue = dt.toLocaleString(DateTime.DATETIME_SHORT);
  return formattedValue;
}

export function hsAPIAssociationsToHGAssociation(params: {
  fromObjectType: string;
  toObjectType: string;
  apiResults: HubSpotAssociationsBatchReadAPIResponse["results"];
}): HGAssociation[] {
  const { apiResults, fromObjectType, toObjectType } = params;

  const results: HGAssociation[] = [];

  for (const apiResult of apiResults) {
    const fromObjectId = apiResult.from.id;
    for (const to of apiResult.to) {
      for (const associationType of to.associationTypes) {
        console.log("processing associationType result", [
          fromObjectType,
          toObjectType,
          associationType,
        ]);

        let hsLabel = associationType.label;
        let objectRefA: HGObjectRef;
        let objectRefB: HGObjectRef;
        if (
          fromObjectType === "company" &&
          toObjectType === "company" &&
          (associationType.typeId === 13 || associationType.typeId === 14)
        ) {
          hsLabel = "Parent to Child";
          // make sure parent is source object (type id 13 = parent -> child)
          if (associationType.typeId === 13) {
            objectRefA = {
              objectType: fromObjectType,
              objectId: fromObjectId,
            };
            objectRefB = {
              objectType: toObjectType,
              objectId: `${to.toObjectId}`,
            };
          } else {
            objectRefA = {
              objectType: toObjectType,
              objectId: `${to.toObjectId}`,
            };
            objectRefB = {
              objectType: fromObjectType,
              objectId: fromObjectId,
            };
          }
        } else {
          objectRefA = {
            objectType: fromObjectType,
            objectId: fromObjectId,
          };
          objectRefB = {
            objectType: toObjectType,
            objectId: `${to.toObjectId}`,
          };
        }

        // rewrite "Primary Company" labels etc to match normalisation we have done when fetching the label definitions
        if (
          associationType.category === "HUBSPOT_DEFINED" &&
          associationType.label?.startsWith("Primary")
        ) {
          hsLabel = "Primary";
        }

        const associationLabelCanonicalId = canonicalIdForAssociationLabel({
          category: associationType.category,
          label: hsLabel,
          objectTypeA: objectRefA.objectType,
          objectTypeB: objectRefB.objectType,
        });
        const canonicalId = canonicalIdForAssociation({
          associationLabelCanonicalId,
          objectRefA,
          objectRefB,
        });

        const association: HGAssociation = {
          canonicalId: canonicalId,
          associationLabelCanonicalId,
          fromObjectRef: objectRefA,
          toObjectRef: objectRefB,
        };

        results.push(association);
      }
    }
  }

  // remove any duplicates
  const withoutDuplicates = _.uniqBy(results, (result) => result.canonicalId);

  return withoutDuplicates;
}

/**
 * Figures out which associations we should remove from our local cache before
 * we re-assert new information from HubSpot.
 *
 * This should only be used when we are reasserting all the associations for
 * the affected objects. Usually we would do upserts (adding associations to
 * out local in-memory state), but sometimes we want to "reset" with the new
 * information from HubSpot.
 *
 * A good example of this is that if we create a new primary association then
 * HubSpot might remove an existing primary association at the same time. By
 * performing the create, then removing all the possible associations that
 * might have been altered, and then storing all new information from HubSpot
 * we will end up in the correct state.
 *
 * This is a terrible explanation and name and I hope I can figure out a
 * better way of modelling this later.
 */
export function associationIdsToRemoveBeforeReassert(params: {
  affectedObjectRefs: [objectRefA: HGObjectRef, objectRefB: HGObjectRef];
  existingHGAssociations: HGAssociation[];
}): string[] {
  const { existingHGAssociations, affectedObjectRefs } = params;

  const toDeleteCanonicalIds: string[] = [];

  const [objectRefA, objectRefB] = affectedObjectRefs;

  const possibleMatches: [
    objectRef: HGObjectRef,
    objectTypeForMatch: string,
  ][] = [
    [objectRefA, objectRefB.objectType],
    [objectRefB, objectRefA.objectType],
  ];

  for (const hgAssociation of existingHGAssociations) {
    console.log("checking to see whether we should delete hgAssociation...", {
      hgAssociaton: _.cloneDeep(hgAssociation),
      possibleMatches,
    });

    for (const [objectRef, objectTypeForMatch] of possibleMatches) {
      let involvedAs: "to" | "from" | null = null;
      if (objectRefsEqual(hgAssociation.fromObjectRef, objectRef)) {
        involvedAs = "from";
      } else if (objectRefsEqual(hgAssociation.toObjectRef, objectRef)) {
        involvedAs = "to";
      }

      if (!involvedAs) {
        continue;
      }

      const destinationObjectType =
        involvedAs === "from"
          ? hgAssociation.toObjectRef.objectType
          : hgAssociation.fromObjectRef.objectType;

      if (destinationObjectType !== objectTypeForMatch) {
        continue;
      }

      console.log(
        "deleting hgASsociation",
        _.cloneDeep({
          objectRef,
          objectTypeForMatch,
          hgAssociation,
        }),
      );

      toDeleteCanonicalIds.push(hgAssociation.canonicalId);
    }
  }

  return toDeleteCanonicalIds;
}

type SettableURLParams = "mapId";
export function updateURLParam(k: SettableURLParams, v: string | null): void {
  const url = new URL(window.location.href);
  if (v === null) {
    url.searchParams.delete(k);
  } else {
    url.searchParams.set(k, v);
  }
  window.history.replaceState(null, "", url);
}
