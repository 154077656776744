import {
  Checkbox,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  TooltipProps,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { HGDisplayProperty, HSProperty, HSPropertyGroup } from "../domain";
import * as computeds from "../computeds";
import { observer } from "mobx-react-lite";

const DisplayPropertyListTermInput: React.FC<{
  objectType: string;
  onChange: (term: string) => void;
}> = observer((props) => {
  const { objectType, onChange } = props;

  const [term, setTerm] = useState<string>("");

  const debouncedChange = useCallback(debounce(onChange, 200), []);

  const displayLabel = computeds.displayLabelForObjectTypeSingular(objectType);

  return (
    <TextField
      placeholder={`Search ${displayLabel} Properties`}
      autoFocus
      fullWidth
      value={term}
      onChange={(e) => {
        const nextTerm = e.target.value;
        setTerm(nextTerm);
        debouncedChange(nextTerm);
      }}
    />
  );
});

function displayPropertyListResults(params: {
  term: string;
  properties: HSProperty[];
  groups: HSPropertyGroup[];
}): {
  group: HSPropertyGroup;
  property?: HSProperty;
}[] {
  const { term, properties, groups } = params;

  const propertiesByGroup = _.groupBy(
    properties,
    (property) => property.groupName,
  );

  const matchesTerm = (property: HSProperty) => {
    const label = property.label;
    return label.toLowerCase().indexOf(term.toLowerCase()) > -1;
  };

  const isValidProperty = (property: HSProperty) => {
    return true;
  };

  const ordered = _.chain(groups)
    .sortBy((group) => group.displayOrder)
    .flatMap((group) => {
      const name = group.name;
      const properties = propertiesByGroup[name] || [];
      const items: { group: HSPropertyGroup; property?: HSProperty }[] = [
        { group },
        ..._.chain(properties)
          .filter(isValidProperty)
          .filter((property) => {
            if (_.isEmpty(term)) {
              return true;
            } else {
              return matchesTerm(property);
            }
          })
          .sortBy((property) => property.label)
          .map((property) => {
            return {
              group,
              property,
            };
          })
          .value(),
      ];

      return items;
    })
    .value();

  return ordered;
}

const TooltipSolid: React.FC<TooltipProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Tooltip
      {...otherProps}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "rgba(27,38,49,1)",
            fontSize: "0.875rem",
            fontWeight: "400",
            "& .MuiTooltip-arrow": {
              color: "rgba(27,38,49,1)",
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

const DisplayPropertyListItem: React.FC<{
  property?: HSProperty;
  propertyGroup: HSPropertyGroup;
  displayProperty: HGDisplayProperty | undefined;

  onRemoveDisplayProperty: (params: {
    name: string;
    objectType: string;
  }) => void;
  onAddDisplayProperty: (params: {
    name: string;
    objectType: string;
    showOnCard: boolean;
  }) => void;
  onUpdateDisplayProperty: (params: {
    name: string;
    objectType: string;
    showOnCard: boolean;
  }) => void;
}> = (props) => {
  const {
    displayProperty,
    property,
    propertyGroup,
    onAddDisplayProperty,
    onRemoveDisplayProperty,
    onUpdateDisplayProperty,
  } = props;

  if (!property) {
    return (
      <div className="px-4 py-2 bg-gray-100 border-b border-black border-opacity-10 truncate">
        <span className="font-semibold text-sm">{propertyGroup.label}</span>
      </div>
    );
  }

  const selected = !!displayProperty;
  const description = property.description;

  return (
    <div
      className={`flex flex-col justify-center pl-2 pr-4 overflow-hidden hover:bg-gray-100 cursor-pointer ${
        selected ? "pb-2" : ""
      }`}
      onClick={(e) => {
        if (selected) {
          onRemoveDisplayProperty({
            name: property.name,
            objectType: property.objectType,
          });
        } else {
          onAddDisplayProperty({
            name: property.name,
            objectType: property.objectType,
            showOnCard: true,
          });
        }
      }}
    >
      <TooltipSolid title={description} placement={"right"} arrow={true}>
        <div className="flex flex-row items-center py-2">
          <Checkbox
            checked={selected}
            style={{
              padding: 0,
              top: 1,
              backgroundColor: "transparent",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              console.log("onChange", { property, selected });
              if (selected) {
                onRemoveDisplayProperty({
                  name: property.name,
                  objectType: property.objectType,
                });
              } else {
                onAddDisplayProperty({
                  name: property.name,
                  objectType: property.objectType,
                  showOnCard: false,
                });
              }
            }}
            disableRipple
            color="primary"
          />

          <div className="ml-2 flex-auto flex flex-row justify-between items-center overflow-hidden">
            <span className="font-normal text-sm truncate">
              {property.label}
            </span>
          </div>
        </div>
      </TooltipSolid>

      {selected && displayProperty && (
        <FormControl variant="outlined" fullWidth>
          <Select
            size="small"
            value={displayProperty.showOnCard ? "true" : "false"}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              onUpdateDisplayProperty({
                name: property.name,
                objectType: property.objectType,
                showOnCard: !displayProperty.showOnCard,
              });
            }}
          >
            <MenuItem value={"false"}>Show in sidebar</MenuItem>
            <MenuItem value={"true"}>Show on card &amp; sidebar</MenuItem>
          </Select>
        </FormControl>
      )}
    </div>
  );
};

interface DisplayPropertyListProps {
  objectType: string;
  properties: HSProperty[];
  propertyGroups: HSPropertyGroup[];
  displayProperties: HGDisplayProperty[];
  onDone: () => void;
  onAddDisplayProperty: (params: {
    name: string;
    objectType: string;
    showOnCard: boolean;
  }) => void;
  onRemoveDisplayProperty: (params: {
    name: string;
    objectType: string;
  }) => void;
  onUpdateDisplayProperty: (params: {
    name: string;
    objectType: string;
    showOnCard: boolean;
  }) => void;
}

const DisplayPropertyList: React.FC<DisplayPropertyListProps> = (props) => {
  const {
    objectType,
    propertyGroups,
    properties,
    displayProperties,
    onDone,
    onAddDisplayProperty,
    onRemoveDisplayProperty,
    onUpdateDisplayProperty,
  } = props;

  const [term, setTerm] = useState<string>("");

  const [results, setResults] = useState<
    {
      group: HSPropertyGroup;
      property?: HSProperty;
    }[]
  >([]);

  useEffect(() => {
    setResults(
      displayPropertyListResults({
        groups: propertyGroups,
        properties,
        term,
      }),
    );
  }, [term, propertyGroups, properties]);

  return (
    <div className="h-full flex flex-col">
      <div className="bg-gray-100 border-b-2 border-black border-opacity-10 p-2">
        <DisplayPropertyListTermInput
          objectType={objectType}
          onChange={(term) => {
            setTerm(term);
          }}
        />
      </div>

      <div className="flex-auto overflow-y-scroll">
        {results.map((result) => {
          const { property, group } = result;

          const displayProperty = property?.name
            ? displayProperties.find(
                (displayProperty) => displayProperty.name === property.name,
              )
            : undefined;

          return (
            <DisplayPropertyListItem
              key={group.name + property?.name || "group"}
              property={property}
              propertyGroup={group}
              displayProperty={displayProperty}
              onAddDisplayProperty={onAddDisplayProperty}
              onRemoveDisplayProperty={onRemoveDisplayProperty}
              onUpdateDisplayProperty={onUpdateDisplayProperty}
            />
          );
        })}
      </div>

      <div className="p-2 bg-gray-100 flex items-end justify-end border-t-2 border-black border-opacity-5">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          style={{ textTransform: "none" }}
          onClick={onDone}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

type DisplayPropertiesDrawerProps = DisplayPropertyListProps;

export const DisplayPropertiesDrawer: React.FC<DisplayPropertiesDrawerProps> = (
  props,
) => {
  return (
    <div className="w-[24rem] h-full">
      <DisplayPropertyList {...props} />
    </div>
  );
};
