import React, { useState } from "react";
import * as domain from "../domain";
import * as CanvasProperties from "./CanvasProperties";
import * as icons from "@mui/icons-material";
import _ from "lodash";
import { Button, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useHGApp, useHGApp as useHGAppContext } from "../store";
import * as actions from "../actions";
import { canonicalObjectIdsOnMap } from "../computeds";
import * as computeds from "../computeds";
import * as theme from "../theme";

const bgColorClassesForObjectType: {
  [objectType: string]:
    | {
        dark: string;
        darker: string;
        darkest: string;
        light: string;
        darkBorder: string;
      }
    | undefined;
} = {
  contact: {
    darkBorder: "border-purple-400",
    darker: "bg-purple-300",
    darkest: "bg-purple-500",
    dark: "bg-purple-200",
    light: "bg-purple-50",
  },
  company: {
    darkBorder: "border-blue-400",
    darker: "bg-blue-300",
    darkest: "bg-blue-500",
    dark: "bg-blue-200",
    light: "bg-blue-50",
  },
  deal: {
    darkBorder: "border-emerald-400",
    darker: "bg-emerald-300",
    darkest: "bg-emerald-500",
    dark: "bg-emerald-200",
    light: "bg-emerald-50",
  },
};

const fgColorClassForObjectType: {
  [objectType: string]: string | undefined;
} = {
  contact: "text-purple-900",
  company: "text-blue-900",
  deal: "text-emerald-900",
};

function fgColorClass(objectType: string): string {
  return fgColorClassForObjectType[objectType] || "text-gray-900";
}

function bgColorClasses(objectType: string): {
  darkBorder: string;
  darker: string;
  darkest: string;
  dark: string;
  light: string;
} {
  return (
    bgColorClassesForObjectType[objectType] || {
      darkBorder: "border-gray-400",
      dark: "bg-gray-200",
      darker: "bg-gray-300",
      darkest: "bg-gray-400",
      light: "bg-gray-50",
    }
  );
}

const HGObjectCanvasNodeHeader: React.FC<{
  node: domain.HGCanvasNode;
  hgObject: domain.HGObject;
}> = observer((props) => {
  const { node, hgObject } = props;

  let iconNode: React.ReactNode | undefined;
  switch (hgObject.objectType) {
    case "contact": {
      iconNode = <icons.Person color="inherit" fontSize="medium" />;
      break;
    }
    case "company": {
      iconNode = <icons.Business color="inherit" fontSize="medium" />;
      break;
    }
    case "deal": {
      iconNode = <icons.Handshake color="inherit" fontSize="medium" />;
      break;
    }
  }

  const Tag = (props: { label: string }) => {
    return (
      <div
        className={`px-2 py-0.5 rounded-md bg-white bg-opacity-70 ${fgColorClass(
          hgObject.objectType,
        )} text-base font-bold`}
      >
        {props.label}
      </div>
    );
  };

  // const dispatch = useAppDispatch();

  const displayLabel = computeds.displayLabelForObjectTypeSingular(
    hgObject.objectType,
  );

  return (
    <div
      className={`${bgColorClasses(hgObject.objectType).dark} border-b-2 ${
        bgColorClasses(hgObject.objectType).darkBorder
      } flex flex-row`}
    >
      <div
        onPointerDown={(e) => {
          console.log("stop propagation of pointerdown");
          e.stopPropagation();
        }}
        onPointerUp={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();

          console.log("click header pointer thing");

          if (hgObject) {
            // dispatch(
            //   session.actions.clickNodeConnector({
            //     nodeId: node.id,
            //     objectId: hgObject.objectId,
            //     objectType: hgObject.objectType,
            //   }),
            // );

            actions.clickNodeConnector({
              nodeId: node.id,
              hgObjectRef: {
                objectId: hgObject.objectId,
                objectType: hgObject.objectType,
              },
            });
          }
        }}
        className={`${
          bgColorClasses(hgObject.objectType).darker
        } w-10 flex items-center justify-center hover:bg-opacity-70 hover:cursor-pointer`}
      >
        <div
          className={`rounded-full ${
            bgColorClasses(hgObject.objectType).darkest
          } h-3 w-3`}
        />
      </div>
      <div className="flex flex-row px-2 py-2.5 space-x-2 flex flex-row items-center">
        <span className={`${fgColorClass(hgObject.objectType)} ml-1`}>
          {iconNode}
        </span>
        <Tag label={displayLabel} />
      </div>
    </div>
  );
});

export const HGObjectCanvasNode: React.FC<{
  node: domain.HGCanvasObjectNode;
}> = observer((props) => {
  const { node } = props;

  const canonicalId = domain.canonicalIdForHGObjectRef({
    objectId: node.id,
    objectType: node.objectType,
  });

  const [showExtra, setShowExtra] = useState<boolean>(false);

  const app = useHGAppContext();

  let hgObject = app.store.hgObjects[canonicalId] as
    | domain.HGObject
    | undefined;

  const objectRef: domain.HGObjectRef = {
    objectId: node.id,
    objectType: node.objectType,
  };
  const hgAssociations = _.reduce(
    app.store.hgAssociations,
    (acc, hgAssociation) => {
      if (
        domain.objectRefsEqual(hgAssociation.fromObjectRef, objectRef) ||
        domain.objectRefsEqual(hgAssociation.toObjectRef, objectRef)
      ) {
        acc.push(hgAssociation);
      }
      return acc;
    },
    [] as domain.HGAssociation[],
  );

  const hgAssociationsToObjectsNotOnMap = _.reduce(
    hgAssociations,
    (acc, hgAssociation) => {
      if (typeof hgObject === "undefined") {
        return acc;
      }

      const otherObjectRef = domain.objectRefsEqual(
        objectRef,
        hgAssociation.fromObjectRef,
      )
        ? hgAssociation.toObjectRef
        : hgAssociation.fromObjectRef;

      const otherCanonicalId = domain.canonicalIdForHGObjectRef(otherObjectRef);

      if (!canonicalObjectIdsOnMap.get()[otherCanonicalId]) {
        acc.push(hgAssociation);
      }

      return acc;
    },
    [] as domain.HGAssociation[],
  );

  // TODO: test code just so we can render a fake hubspot object
  if (!hgObject) {
    hgObject = {
      canonicalId: node.id,
      objectId: node.id,
      objectType: "company",
      properties: {},
      isFetched: true,
    };
  }

  if (!hgObject) {
    return <div>Empty placeholder for ${canonicalId}</div>;
  }

  const { objectId, objectType } = hgObject;

  // useEffect(() => {
  //   setInterval(() => {
  //     setShowExtra((_show) => !_show);
  //   }, 5000);
  // }, []);

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     setCounter((count) => count + 1);
  //   }, 1000);
  //   return () => {
  //     clearInterval(id);
  //   };
  // });

  let extraAssociationsMessage: string | undefined;
  if (hgAssociationsToObjectsNotOnMap.length > 0) {
    if (hgAssociationsToObjectsNotOnMap.length > 1) {
      extraAssociationsMessage = `Has ${hgAssociationsToObjectsNotOnMap.length} additional associations not shown on this map.`;
    } else {
      extraAssociationsMessage =
        "Has 1 additional association not shown on this map.";
    }
  }

  const isFetchingAssociations =
    app.store.hgObjectsFetchingAssociations[hgObject.canonicalId];

  const isCreatingAssociation = !!app.store.draftConnection?.from;

  return (
    <div
      onPointerDownCapture={(e) => {
        if (isCreatingAssociation && hgObject) {
          e.stopPropagation();
          actions.clickNodeConnector({
            hgObjectRef: {
              objectId: hgObject.objectId,
              objectType: hgObject.objectType,
            },
            nodeId: node.id,
          });
        }
      }}
      onClickCapture={(e) => {
        if (isCreatingAssociation) {
          e.stopPropagation();
        }
      }}
      className={`flex flex-col min-w-[340px] max-w-[500px] border-4 ${
        bgColorClasses(hgObject.objectType).darkBorder
      } rounded-xl bg-white overflow-hidden user-select-none`}
    >
      <HGObjectCanvasNodeHeader node={node} hgObject={hgObject} />

      <div className={`p-4 ${bgColorClasses(hgObject.objectType).light}`}>
        <h5 className="text-gray-800 text-xl font-semibold tracking-tight hg-privacy blur">
          {domain.objectDisplayName(hgObject)}
        </h5>
      </div>

      {/* <div className="px-4 py-2">
        <div>
          <span className="font-bold">Object Id:</span> {hgObject.objectId}
        </div>
        <div>
          <span className="font-bold">Object Type:</span> {hgObject.objectType}
        </div>
      </div> */}

      {isFetchingAssociations && (
        <div className="bg-red-50 p-4 flex flex-col space-y-2 border-y border-red-300">
          <CircularProgress color={"secondary"} size={32} />
        </div>
      )}

      {!isFetchingAssociations &&
        !_.isEmpty(hgAssociationsToObjectsNotOnMap) &&
        extraAssociationsMessage && (
          <div>
            <div className="bg-red-50 px-4 pt-3 pb-2 flex flex-col space-y-2 border-y border-red-300">
              <div className="pl-2">
                <span className="text-red-900 font-semibold text-base leading-tight">
                  {extraAssociationsMessage}
                </span>
              </div>
              <div className="flex">
                <Button
                  style={{ color: theme.colors.red[800] }}
                  variant="text"
                  onPointerDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    if (!hgObject) {
                      return;
                    }
                    actions.startAddingAssociatedObject({
                      objectType: hgObject.objectType,
                      objectId: hgObject.objectId,
                    });
                  }}
                >
                  View{" "}
                  {hgAssociationsToObjectsNotOnMap.length > 1
                    ? "associations"
                    : "association"}
                </Button>
              </div>
            </div>
          </div>
        )}

      {showExtra && <div className="h-[600px] bg-red-500"></div>}

      {/* <div className="p-4">
        <Button
          variant="contained"
          color="primary"
          onPointerDown={(e) => {
            console.log("stop propagation of pointerdown");
            e.stopPropagation();
          }}
          onClick={(e) => {
            console.log("click!");
            e.stopPropagation();
            e.preventDefault();
            actions.fetchObjectAssociations({
              objectRef: {
                objectId,
                objectType,
              },
            });
            return;
          }}
        >
          Fetch associations
        </Button>
      </div> */}

      {/* <div className="max-w-[340px] p-4 bg-gray-200 overflow-hidden">
        <h4>Associations</h4>
        {Object.values(hgAssociations).map((hgAssociation) => {
          return (
            <div key={hgAssociation.canonicalId}>
              {hgAssociation.canonicalId}
            </div>
          );
        })}
      </div> */}

      <div>
        <CanvasProperties.PropertyRows hgObject={hgObject} />
      </div>

      <div className="p-4">
        <Button
          style={{
            textTransform: "none",
          }}
          variant="contained"
          onPointerDown={(e) => e.stopPropagation()}
          onClick={() => {
            console.log("focus object");
            actions.focusObject({ objectType, objectId });
          }}
        >
          Show details
        </Button>
      </div>

      {/* <div className="p-4">
      <span className="font-bold">Object Properties:</span>{" "}
      {JSON.stringify(hgObject.properties, null, 2)}
      </div> */}
    </div>
  );
});

const HGObjectCollectionCanvasNodeHeader: React.FC<{
  objectType: string;
}> = observer((props) => {
  const { objectType } = props;

  const displayLabelPlural =
    computeds.displayLabelForObjectTypePlural(objectType);

  let iconNode: React.ReactNode | undefined;
  switch (objectType) {
    case "contact": {
      iconNode = <icons.Person color="inherit" fontSize="medium" />;
      break;
    }
    case "company": {
      iconNode = <icons.Business color="inherit" fontSize="medium" />;
      break;
    }
    case "deal": {
      iconNode = <icons.Handshake color="inherit" fontSize="medium" />;
      break;
    }
  }

  const Tag = (props: { label: string }) => {
    return (
      <div
        className={`px-2 py-0.5 rounded-md bg-white bg-opacity-70 ${fgColorClass(
          objectType,
        )} text-base font-bold`}
      >
        {props.label}
      </div>
    );
  };

  return (
    <div
      className={`p-2 py-2.5 ${bgColorClasses(objectType).dark} border-b-2 ${
        bgColorClasses(objectType).darkBorder
      } flex flex-row items-center space-x-2`}
    >
      <span className={`${fgColorClass(objectType)} ml-1`}>
        {iconNode}
        {iconNode}
      </span>
      <Tag label={displayLabelPlural} />
    </div>
  );
});

const HGObjectCollectionCanvasNodePreviewCell: React.FC<{
  hgObject: domain.HGObject;
}> = (props) => {
  const { hgObject } = props;
  const displayName = domain.objectDisplayName(hgObject);

  return (
    <div className="p-4 hover:bg-gray-200 hover:cursor-pointer">
      <h5 className="tracked-tight text-gray-900 text-base font-semibold">
        {displayName}
      </h5>
    </div>
  );
};

export const HGObjectCollectionCanvasNode: React.FC<{
  node: domain.HGCanvasObjectCollectionNode;
}> = (props) => {
  const { node } = props;

  const canonicalIds = node.objectIds;
  const objectType = node.objectType;

  // const hgObjects = useAppSelector((state) => {
  //   return _.compact(
  //     _.map(canonicalIds, (id) => {
  //       return session.selectors.hgObjects.selectById(state, id);
  //     }),
  //   );
  // });

  const hgObjects: domain.HGObject[] = [];

  return (
    <div className="flex flex-col w-[300px] max-h-[480px] shadow-md rounded-xl bg-white overflow-hidden pan-zoom-excluded">
      <HGObjectCollectionCanvasNodeHeader objectType={objectType} />

      <div className="overflow-y-scroll divide-y divide-gray-300">
        {_.map(hgObjects, (hgObject) => {
          return (
            <HGObjectCollectionCanvasNodePreviewCell
              key={hgObject.objectId}
              hgObject={hgObject}
            />
          );
        })}
      </div>
    </div>
  );
};

export const HGObjectLoaderNode: React.FC<{
  node: domain.HGCanvasObjectLoaderNode;
}> = (props) => {
  const { node } = props;

  // const dispatch = useAppDispatch();

  return (
    <div
      className="min-w-[120px] w-full"
      onClick={() => {
        // dispatch(
        //   expandNode({
        //     objectRef: {
        //       objectId: node.objectId,
        //       objectType: node.objectType,
        //     },
        //     noCommit: false,
        //   }),
        // );
      }}
    >
      <Button variant="contained" color="secondary" fullWidth={true}>
        Load more
      </Button>
    </div>
  );
};
