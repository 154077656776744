import React from "react";
import { useHGApp } from "../store";
import * as actions from "../actions";
import { observer } from "mobx-react-lite";
import * as domain from "../domain";
import _ from "lodash";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { canonicalObjectIdsOnMap } from "../computeds";
import { AddToMapSuggestionRow } from "./AddToMapSuggestion";

export const AddAssociatedToChartModal: React.FC<{}> = observer((props) => {
  const hgApp = useHGApp();

  const objectRef = hgApp.store.draftAddingAssociatedToChart;

  if (!objectRef) {
    return null;
  }

  const hgAssociations = Object.values(hgApp.store.hgAssociations).filter(
    (hgAssociation) => {
      return (
        domain.objectRefsEqual(objectRef, hgAssociation.fromObjectRef) ||
        domain.objectRefsEqual(objectRef, hgAssociation.toObjectRef)
      );
    },
  );

  const objectIdsAlreadyOnMap = canonicalObjectIdsOnMap.get();

  interface HGObjectToShow {
    hgObject: domain.HGObject;
    hgAssociations: domain.HGAssociation[];
    relatedObjectRef: domain.HGObjectRef;
  }
  const hgObjectsToShow: HGObjectToShow[] = _.chain(hgAssociations)
    .flatMap((hgAssociation) => {
      return [
        { relatedObjectRef: hgAssociation.fromObjectRef, hgAssociation },
        { relatedObjectRef: hgAssociation.toObjectRef, hgAssociation },
      ];
    })
    // // filter out ourselves
    .filter(({ relatedObjectRef }) => {
      return !domain.objectRefsEqual(objectRef, relatedObjectRef);
    })
    // filter out any objects already on the map
    .filter(({ relatedObjectRef }) => {
      return !objectIdsAlreadyOnMap[
        domain.canonicalIdForHGObjectRef(relatedObjectRef)
      ];
    })
    // collapse all the associations into one record per objectRef
    .groupBy(({ relatedObjectRef }) => {
      return domain.canonicalIdForHGObjectRef(relatedObjectRef);
    })
    .map((hgObjectsToShow, canonicalId) => {
      return {
        relatedObjectRef: hgObjectsToShow[0].relatedObjectRef,
        hgAssociations: hgObjectsToShow.map(
          (hgObjectToShow) => hgObjectToShow.hgAssociation,
        ),
      };
    })
    // add in hgObject records from our in-memory database
    .map((relatedObjectToShow) => {
      return {
        ...relatedObjectToShow,
        hgObject:
          hgApp.store.hgObjects[
            domain.canonicalIdForHGObjectRef(
              relatedObjectToShow.relatedObjectRef,
            )
          ],
      };
    })
    // remove any objects that we don't have in the database
    .filter(({ hgObject }) => !!hgObject)
    .value();

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        actions.cancelAddingAssociatedObject();
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle>Add associated objects to map</DialogTitle>
      <div className="divide-y-2 border-y border-gray-300">
        {_.map(hgObjectsToShow, ({ hgObject, hgAssociations }) => {
          return (
            <AddToMapSuggestionRow
              hgObject={hgObject}
              hgAssociations={hgAssociations}
              onAddToMap={() => {
                actions.addAssociatedObjectToChart({ hgObject });
              }}
              key={domain.canonicalIdForHGObjectRef(hgObject)}
            />
          );
        })}
      </div>
      <DialogActions>
        <Button
          onClick={() => {
            actions.cancelAddingAssociatedObject();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});
