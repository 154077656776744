import firebase from "firebase/app";
// side-effect importing all the firebase modules we need, not great
import "firebase/auth";
import "firebase/firestore";

import { TDAsset, TDBinding, TDShape } from "@orgcharthub/tldraw-tldraw";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import * as config from "../config";
import {
  DateTimeFromFirestoreTimestamp,
  HGPortal,
  RelationshipMap,
  RelationshipMapRecord,
  DEFAULT_DISPLAY_PROPERTIES,
  HGDisplayProperty,
} from "../domain";
import { parseOrThrow } from "../utils";
import * as ts from "io-ts";
import _ from "lodash";

export function startFirebase(): void {
  firebase.initializeApp({
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    databaseURL: config.FIREBASE_DATABASE_URL,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messageSenderId: config.FIREBASE_MESSAGE_SENDER_ID,
  });
}

interface RelationshipMapDocument {
  version: number;
  shapes: Record<string, TDShape>;
  bindings: Record<string, TDBinding>;
  assets: Record<string, TDAsset>;
}

type SerialisedRelationshipMapDocument = Omit<
  RelationshipMapDocument,
  "shapes"
> & {
  shapes: Record<string, string>;
};

function serialiseDocument(
  document: RelationshipMapDocument,
): SerialisedRelationshipMapDocument {
  const previousShapes = document.shapes;

  const nextShapes = _.reduce(
    previousShapes,
    (acc, shape, k) => {
      acc[k] = JSON.stringify(shape);
      return acc;
    },
    {} as Record<string, string>,
  );

  return {
    ...document,
    shapes: nextShapes,
  };
}

export async function syncRelationshipMapDocument(params: {
  portalId: string;
  mapId: string;
  document: RelationshipMapDocument;
  metadata: {
    mapVersion: number;
  };
}): Promise<void> {
  const { portalId, mapId, document, metadata } = params;

  const serialised = serialiseDocument(document);
  console.log("document", document);
  console.log("serialised", serialised);

  const mapDocRef = firebase
    .firestore()
    .collection("portals")
    .doc(portalId)
    .collection("relationship-maps")
    .doc(mapId);

  const docRef = mapDocRef.collection("documents").doc("current");

  await firebase.firestore().runTransaction(async (transaction) => {
    const mapDoc = await mapDocRef.get();
    const doc = await docRef.get();

    if (mapDoc.exists) {
      const Patch = ts.type({
        updatedAt: DateTimeFromFirestoreTimestamp,
      });
      const patch: ts.TypeOf<typeof Patch> = {
        updatedAt: DateTime.utc().toISO(),
      };
      mapDocRef.update(Patch.encode(patch));
    } else {
      const RelationshipMapWithoutName = ts.type({
        id: RelationshipMap.props["id"],
        portalId: RelationshipMap.props["portalId"],
        createdAt: RelationshipMap.props["createdAt"],
        updatedAt: RelationshipMap.props["updatedAt"],
      });
      const mapDoc: ts.TypeOf<typeof RelationshipMapWithoutName> = {
        createdAt: DateTime.utc().toISO(),
        updatedAt: DateTime.utc().toISO(),
        id: mapId,
        portalId: portalId,
      };
      mapDocRef.set(RelationshipMapWithoutName.encode(mapDoc));
    }

    if (!doc.exists) {
      const patch: RelationshipMapRecord = {
        id: mapId,
        portalId: portalId,
        document: serialised,
        metadata: metadata,
      };
      console.log("firestore create doc", patch);
      transaction.set(docRef, patch);
    } else {
      const parsed = parseOrThrow(RelationshipMapRecord, doc.data());

      // if the version number is the same then nothing to do - another client
      // must have already updated this document for us
      if (parsed.metadata.mapVersion === metadata.mapVersion) {
        return;
      } else {
        const patch: Pick<RelationshipMapRecord, "document" | "metadata"> = {
          document: serialised,
          metadata,
        };
        console.log("firestore update doc", patch);
        // if the version number is not the same then apply the update to keep
        // our firestore document in-sync with the liveblocks document
        transaction.update(docRef, patch);
        return;
      }
    }
  });

  return;
}

export async function createNewRelationshipMap(params: {
  portalId: string;
  id: string;
}): Promise<void> {
  const { portalId, id } = params;
  const ref = mapRef(portalId, id);

  const now = DateTime.now();

  const shortDateTime = now.toLocaleString(DateTime.DATETIME_SHORT);

  const newDoc: RelationshipMap = {
    createdAt: now.toISO(),
    updatedAt: now.toISO(),
    id: id,
    name: `Untitled - ${shortDateTime}`,
    description: "",
    isTemplate: false,
    archived: false,
    portalId,
  };

  await ref.set(RelationshipMap.encode(newDoc), { merge: true });
}

export async function updateRelationshipMap(params: {
  portalId: string;
  id: string;
  name: string;
  description: string;
  isTemplate: boolean;
}): Promise<void> {
  const { portalId, id, name, description, isTemplate } = params;

  const Patch = ts.type({
    name: ts.string,
    description: ts.string,
    isTemplate: ts.boolean,
    updatedAt: DateTimeFromFirestoreTimestamp,
  });

  const patch = Patch.encode({
    name: name,
    description,
    isTemplate,
    updatedAt: DateTime.now().toISO(),
  });

  const ref = mapRef(portalId, id);

  await ref.update(patch);
}

export async function archiveRelationshipMap(params: {
  portalId: string;
  id: string;
}): Promise<void> {
  const { portalId, id } = params;

  const Patch = ts.type({
    archived: ts.boolean,
    updatedAt: DateTimeFromFirestoreTimestamp,
  });

  const patch = Patch.encode({
    archived: true,
    updatedAt: DateTime.now().toISO(),
  });

  const ref = mapRef(portalId, id);

  await ref.update(patch);
}

export async function unarchiveRelationshipMap(params: {
  portalId: string;
  id: string;
}): Promise<void> {
  const { portalId, id } = params;

  const Patch = ts.type({
    archived: ts.literal(false),
    updatedAt: DateTimeFromFirestoreTimestamp,
  });

  const patch = Patch.encode({
    archived: false,
    updatedAt: DateTime.now().toISO(),
  });

  const ref = mapRef(portalId, id);

  await ref.update(patch);
}

export const debouncedSyncRelationshipMapDocument = debounce(
  syncRelationshipMapDocument,
  500,
);

export function portalDocRef(
  portalId: string,
): firebase.firestore.DocumentReference {
  return firebase.firestore().collection("portals").doc(portalId);
}

export function mapsCollectionRef(
  portalId: string,
): firebase.firestore.CollectionReference {
  return portalDocRef(portalId).collection("relationship-maps");
}

export function makeNewRelationshipMapId(portalId: string): string {
  return portalDocRef(portalId).collection("relationship-maps").doc().id;
}

export function mapRef(
  portalId: string,
  mapId: string,
): firebase.firestore.DocumentReference {
  return mapsCollectionRef(portalId).doc(mapId);
}

export async function addDefaultDisplayPropertiesIfRequired(params: {
  portalId: string;
}): Promise<void> {
  const { portalId } = params;
  const portalDoc = await portalDocRef(portalId).get();
  const portal = parseOrThrow(HGPortal, portalDoc.data());

  if (typeof portal["hg-display-properties"] === "undefined") {
    console.log("creating default display properties for portal");
    const patch: {
      "hg-display-properties": Readonly<HGDisplayProperty[]>;
    } = {
      "hg-display-properties": DEFAULT_DISPLAY_PROPERTIES,
    };
    await portalDoc.ref.update(patch);
  } else {
    console.log("no need to set default display properties, already got some", {
      existingDisplayProperties: portal["hg-display-properties"],
    });
  }

  return;
}
