import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import { INITIAL_URL_STATE } from "./config";
import * as domain from "./domain";
import {
  HSProperty,
  HGAssociation,
  HGAssociationLabel,
  HGObject,
  HSPropertyGroup,
} from "./domain";
import { makeNewInitialDocument } from "./domain/document";
import _ from "lodash";
import shortid from "shortid";
import { TDShape } from "@orgcharthub/tldraw-tldraw";
import { TLUser } from "@orgcharthub/tldraw-core";

type TestItem = { count: number; id: number };

type CardSize = [width: number, height: number];

interface HGStore {
  id: string;
  initialized: boolean;
  initializedEnoughForMap: boolean;
  loadingMultiplayer: boolean;
  counter: number;
  msg: string;
  items: TestItem[];

  cardSizeCache: Record<string, CardSize | undefined>;

  devMenuVisible: boolean;

  privacyMode: boolean;

  user:
    | Omit<TLUser<{ email?: string; displayName?: string }>, "id">
    | undefined;

  currentRelationshipMap:
    | {
        id: string;
        canUndo: boolean;
        canRedo: boolean;
      }
    | undefined;
  switchingRelationshipMap: boolean;

  relationshipMapDetailsEditorVisible: boolean;

  currentPoint: number[];

  auth: {
    queryToken: string | null;
    authCode: string;
    accessToken?: string;
    refreshToken?: string;
    userId: string;
    impersonation: boolean;
  };

  hgProperties: {
    [id: string]: HSProperty;
  };
  hgPropertyGroups: {
    [id: string]: HSPropertyGroup;
  };
  hgAssociationLabels: { [id: string]: HGAssociationLabel };

  hgObjects: { [id: string]: HGObject };
  hgAssociations: { [id: string]: HGAssociation };
  hgObjectsFetchingAssociations: { [id: string]: boolean };

  customObjectsSupported: boolean;
  hgObjectSchemas: { [id: string]: domain.HGObjectSchema };

  portalId: string;

  portal: domain.HGPortal | undefined;

  initialObject: {
    objectType: string;
    objectId: string;
  };

  focusedObject:
    | {
        objectType: string;
        objectId: string;
      }
    | undefined;

  documentForceUpdate: number;
  documentNotifyUpdate: number;
  // document: TDDraw.TDDocument;

  displayPropertiesConfigSession:
    | {
        objectType: string;
        previousDisplayPropertyConfig: domain.HGDisplayProperty[];
      }
    | undefined;

  draftConnection:
    | {
        from: domain.HGObjectRef & { nodeId: string };
        to?: domain.HGObjectRef & { nodeId: string };
        associationLabelCanonicalId?: string;
      }
    | undefined;
  draftAssociationLabel:
    | {
        fromObjectType: string;
        toObjectType: string;
      }
    | undefined;
  draftAddingObjectToChart:
    | {
        objectType: string;
      }
    | undefined;

  draftAddingAssociatedToChart: domain.HGObjectRef | undefined;
}

const store: HGStore = {
  id: shortid(),
  initialized: false,
  initializedEnoughForMap: false,
  loadingMultiplayer: false,
  counter: 0,
  msg: "",
  items: [],

  devMenuVisible: false,

  privacyMode: false,

  user: undefined,

  cardSizeCache: {},

  switchingRelationshipMap: false,
  // currentRelationshipMap: { id: "no-persistence" },
  currentRelationshipMap: INITIAL_URL_STATE.mapId
    ? { id: INITIAL_URL_STATE.mapId, canUndo: false, canRedo: false }
    : undefined,

  // currentRelationshipMap: {
  //   id: "test-id",
  // },

  relationshipMapDetailsEditorVisible: false,

  currentPoint: [0, 0, 0],

  auth: {
    queryToken: INITIAL_URL_STATE.queryToken,
    authCode: INITIAL_URL_STATE.authCode,
    userId: INITIAL_URL_STATE.userId,
    impersonation: INITIAL_URL_STATE.impersonation,
  },

  hgProperties: {},
  hgPropertyGroups: {},
  hgAssociationLabels: {},

  hgObjects: {},
  hgAssociations: {},

  customObjectsSupported: false,
  hgObjectSchemas: {},

  hgObjectsFetchingAssociations: {},

  portalId: INITIAL_URL_STATE.portalId,

  portal: undefined,

  initialObject: {
    objectType: INITIAL_URL_STATE.objectType,
    objectId: INITIAL_URL_STATE.objectId,
  },

  focusedObject: undefined,

  displayPropertiesConfigSession: undefined,

  documentForceUpdate: 0,
  documentNotifyUpdate: 0,
  // document: initialDocument,

  draftConnection: undefined,
  draftAssociationLabel: undefined,
  draftAddingObjectToChart: undefined,
  draftAddingAssociatedToChart: undefined,
};

console.log("LOAD STORE FILE");

export interface HGApp {
  id: string;
  store: HGStore;
  tlApp: TDDraw.TldrawApp;
}

export const app: HGApp = {
  id: shortid(),
  store: store,
  tlApp: new TDDraw.TldrawApp(),
};

app.tlApp.onPointerMoveEvent = (e) => {
  // console.log("pointer move", e);
  runInAction(() => {
    store.currentPoint = e.currentPoint;
  });
};

app.tlApp.setSetting("showBindingHandles", false);
app.tlApp.setSetting("showCloneHandles", false);

app.tlApp.loadDocument(makeNewInitialDocument("initial_document"));
makeAutoObservable(app.store);

// for debugging
// @ts-ignore
window.hgApp = app;

export const HGAppContext = React.createContext<HGApp>({} as HGApp);

export function useHGApp() {
  const context = React.useContext(HGAppContext);
  return context;
}

if (import.meta.hot) {
  // import.meta.hot.on("vite:beforeUpdate", (e) =>
  //   console.log("before update mobx-store 1", e),
  // );
}

console.log(
  "mobx-store 11",
  _.cloneDeep(app.tlApp.document.pages.page_1.shapes),
);
