import React, { useState } from "react";
import { useHGApp } from "../store";
import * as actions from "../actions";
import { useDebounce } from "usehooks-ts";
import { observer } from "mobx-react-lite";
import { MenuItem, Select, TextField } from "@mui/material";
import * as domain from "../domain";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import * as hubspotApi from "../api/hubspot-api";
import { AddToMapSuggestionRow } from "./AddToMapSuggestion";
import * as computeds from "../computeds";

export const AddObjectToChartPopoverContent: React.FC<{}> = observer(
  (props) => {
    const hgApp = useHGApp();

    const draftState = hgApp.store.draftAddingObjectToChart || {
      objectType: "company",
    };

    const [query, setQuery] = useState<string>("");
    const actualQuery = useDebounce(query, 500);

    const accessToken = hgApp.store.auth.accessToken;

    const objectTypeDisplayLabelPlural =
      computeds.displayLabelForObjectTypePlural(draftState.objectType);

    const propertiesToFetch = computeds.propertiesToFetchForObjectType(
      draftState.objectType,
    );

    const { data, isError, isLoading } = useQuery(
      ["search", actualQuery, draftState.objectType, accessToken],
      async () => {
        if (!accessToken) {
          return;
        }
        return await hubspotApi.searchObjects({
          authState: { accessToken },
          objectType: draftState.objectType,
          query: actualQuery,
          propertiesToFetch,
        });
      },
    );

    const supportedObjectTypes = computeds.supportedObjectTypes.get();

    return (
      <div className="h-screen max-h-[600px] flex flex-col overflow-hidden">
        <div className="px-4 pt-4 pb-3">
          <div className="mb-3">
            <TextField
              autoFocus={true}
              autoComplete="off"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              label={`Search ${objectTypeDisplayLabelPlural}`}
              variant="filled"
              fullWidth={true}
            />
          </div>

          <Select
            fullWidth={true}
            label="Type"
            value={draftState.objectType}
            onChange={(e) => {
              const v = e.target.value;
              if (typeof v !== "string") {
                return;
              }
              actions.updateAddingObjectToChartObjectType({ objectType: v });
            }}
          >
            {supportedObjectTypes.map((objectType) => {
              const displayLabel =
                computeds.displayLabelForObjectTypeSingular(objectType);
              return (
                <MenuItem key={objectType} value={objectType}>
                  {displayLabel}
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <div className="overflow-y-scroll flex flex-col">
          {isError && <div>Error!</div>}

          {isLoading && !isError && <div className="p-4">Loading...</div>}

          {!_.isEmpty(data) && !isLoading && !isError && (
            <div className="divide-y-2 border-y border-gray-300">
              {_.map(data, (hgObject) => {
                return (
                  <AddToMapSuggestionRow
                    key={domain.canonicalIdForHGObjectRef(hgObject)}
                    hgObject={hgObject}
                    onAddToMap={() => {
                      actions.addObjectToChart({ hgObject });
                    }}
                  />
                );
              })}
            </div>
          )}

          {_.isEmpty(data) && !isLoading && !isError && (
            <div className="px-4 pb-4">
              <div className="p-4 rounded-md bg-gray-100 flex items-center justify-center text-gray-700 font-medium text-sm">
                No results found
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);
